/*COMMON CLASSES*/
.floatLeft {
  float: left !important; }

.floatRight {
  float: right !important; }

.floatNone {
  float: none !important; }

.txtCenter {
  text-align: center !important; }

.txtLeft {
  text-align: left; }

.txtRight {
  text-align: right; }

.textJustify {
  text-align: justify; }

.textNowrap {
  white-space: nowrap !important; }

.lowercase {
  text-transform: lowercase !important; }

.uppercase {
  text-transform: uppercase !important; }

.capitalize {
  text-transform: capitalize !important; }

.transformNone {
  text-transform: none !important; }

.txtBlack {
  color: #000 !important; }

.relative {
  position: relative; }

.staticPos {
  position: static !important; }

.height-Auto {
  height: auto !important;
  min-height: 100%; }

.minH-auto {
  min-height: auto !important; }

.ellip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.breakAll {
  word-break: break-all; }

.noBreak {
  word-break: normal !important; }

.noScroll {
  overflow: hidden !important; }

.no_bg {
  background: none !important; }

.noBorder {
  border: none !important; }

.center-block {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.hide {
  display: none !important; }

.show {
  display: block !important; }

.invisible {
  visibility: hidden; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.paddingLR0 {
  padding-left: 0px !important;
  padding-right: 0px !important; }

.pt0 {
  padding-top: 0 !important; }

.pt5 {
  padding-top: 5px !important; }

.pt10 {
  padding-top: 10px !important; }

.pb0 {
  padding-bottom: 0px !important; }

.pb10 {
  padding-bottom: 10px !important; }

.pt20 {
  padding-top: 20px !important; }

.pb4 {
  padding-bottom: 4px !important; }

.pb20 {
  padding-bottom: 20px !important; }

.pl10 {
  padding-left: 10px !important; }

.pl20 {
  padding-left: 20px !important; }

.pl24 {
  padding-left: 24px !important; }

.pr10 {
  padding-right: 10px !important; }

.pr20 {
  padding-right: 20px !important; }

.pr0 {
  padding-right: 0px !important; }

.margin0 {
  margin: 0 !important; }

.mt0 {
  margin-top: 0; }

.mt5 {
  margin-top: 5px !important; }

.mt10 {
  margin-top: 10px !important; }

.mt15 {
  margin-top: 15px !important; }

.mt20 {
  margin-top: 20px !important; }

.mt30 {
  margin-top: 30px !important; }

.mt-20 {
  margin-top: -20px !important; }

.mb0 {
  margin-bottom: 0 !important; }

.mb5 {
  margin-bottom: 5px !important; }

.mb10 {
  margin-bottom: 10px !important; }

.mt-10 {
  margin-top: -10px !important; }

.mb20 {
  margin-bottom: 20px !important; }

.mb30 {
  margin-bottom: 30px !important; }

.mr5 {
  margin-right: 5px !important; }

.mr10 {
  margin-right: 10px !important; }

.mr20 {
  margin-right: 20px !important; }

.mr30 {
  margin-right: 30px !important; }

.ml5 {
  margin-left: 5px !important; }

.ml10 {
  margin-left: 10px !important; }

.ml15 {
  margin-left: 15px !important; }

.ml0 {
  margin-left: 0 !important; }

.width20p {
  width: 20% !important; }

.ml20 {
  margin-left: 20px !important; }

.ml30 {
  margin-left: 30px !important; }

.orange {
  color: #ff6c2f; }

.gray4a {
  color: #4a4a4a; }

.middleIcon {
  display: inline-block;
  width: 24px;
  height: 22px;
  margin: 0 10px;
  vertical-align: middle; }

.leftIcon {
  display: inline-block;
  width: 24px;
  height: 22px;
  margin: 0 10px 0 0;
  vertical-align: middle; }

.valign svg {
  vertical-align: middle !important; }

.commonList {
  list-style: disc;
  margin-left: 30px; }

.commonBg {
  position: fixed;
  left: 0;
  top: 0;
  display: none;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 999; }

.inlineB {
  display: inline-block !important; }

.inline {
  display: inline !important; }

.lineH30 {
  line-height: 30px !important; }

.pointer {
  cursor: pointer !important; }

.iconbgcolor {
  color: #bebebe; }

.tooltipWrap {
  position: relative; }
  .tooltipWrap .tooltiptext {
    position: absolute;
    background-color: #fff;
    color: #4a4a4a;
    text-align: left;
    z-index: 1;
    top: 0px;
    left: 24px;
    margin-left: 0px;
    font-family: 'Lato', sans-serif;
    border-radius: 2px;
    transition: opacity 0.6s ease 0s;
    box-shadow: -1px 1px 6px 1px #e5e2e2;
    width: 230px;
    padding: 10px;
    font-size: 14px;
    line-height: 20px;
    display: none;
    opacity: 0; }
    .tooltipWrap .tooltiptext:after {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent #000 transparent  transparent; }
    .tooltipWrap .tooltiptext.tooltip-top {
      bottom: 100%;
      left: 50%; }
      .tooltipWrap .tooltiptext.tooltip-top:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #000 transparent transparent transparent; }
    .tooltipWrap .tooltiptext.fullScreenTp {
      left: 280px;
      width: calc(100% - 280px);
      margin-top: -18px;
      min-height: 80px;
      line-height: 22px;
      padding: 20px; }
      .tooltipWrap .tooltiptext.fullScreenTp:after {
        content: "";
        position: absolute;
        bottom: 38%;
        left: 0%;
        margin-left: -20px;
        border-width: 10px;
        border-style: solid;
        border-color: transparent #fff transparent  transparent; }
    .tooltipWrap .tooltiptext.smallTP {
      background: #000;
      color: #fff;
      width: 100px;
      top: 38px;
      bottom: 0px;
      left: -35px;
      min-height: 30px;
      padding: 5px 10px;
      text-align: center; }
      .tooltipWrap .tooltiptext.smallTP:after {
        border-color: transparent transparent #000   transparent; }
  .tooltipWrap:hover .tooltiptext {
    display: block;
    opacity: 1; }

/* error toast */
.Toastify {
  color: #fff; }
  .Toastify .Toastify__toast--warning {
    color: #fff;
    padding: 6px 8px;
    min-height: 60px;
    background: #ffbf00 !important;
    border-radius: 10px; }
    .Toastify .Toastify__toast--warning > div {
      background: transparent !important;
      padding-left: 6px; }
      .Toastify .Toastify__toast--warning > div h1 {
        color: #fff;
        font-family: 'Lato', sans-serif;
        font-weight: 700 !important;
        font-size: 18px;
        margin: 0 0 1px 0; }
        .Toastify .Toastify__toast--warning > div h1 i:before {
          font-size: 14px;
          margin-right: 5px; }
        .Toastify .Toastify__toast--warning > div h1 span {
          font-size: 12px;
          font-family: 'Lato', sans-serif;
          color: #ffdccd;
          padding-left: 10px; }
      .Toastify .Toastify__toast--warning > div p {
        color: #fff;
        font-family: 'Lato', sans-serif;
        font-size: 14px;
        font-weight: 400; }
  .Toastify .Toastify__toast--error {
    color: #fff;
    padding: 6px 8px;
    min-height: 60px;
    background: #ff6c2f !important;
    border-radius: 10px; }
    .Toastify .Toastify__toast--error > div {
      background: transparent !important;
      padding-left: 6px; }
      .Toastify .Toastify__toast--error > div h1 {
        color: #fff;
        font-family: 'Lato', sans-serif;
        font-weight: 700 !important;
        font-size: 18px;
        margin: 0 0 1px 0; }
        .Toastify .Toastify__toast--error > div h1 i:before {
          font-size: 14px;
          margin-right: 5px; }
        .Toastify .Toastify__toast--error > div h1 span {
          font-size: 12px;
          font-family: 'Lato', sans-serif;
          color: #ffdccd;
          padding-left: 10px; }
      .Toastify .Toastify__toast--error > div p {
        color: #fff;
        font-family: 'Lato', sans-serif;
        font-size: 14px;
        font-weight: 400; }
  .Toastify .Toastify__toast--success,
  .Toastify .Toastify__toast--info {
    color: #fff;
    padding: 6px 8px;
    min-height: 60px;
    background: #00B67A !important;
    border-radius: 10px; }
    .Toastify .Toastify__toast--success > div,
    .Toastify .Toastify__toast--info > div {
      background: transparent !important;
      padding-left: 6px; }
      .Toastify .Toastify__toast--success > div h1,
      .Toastify .Toastify__toast--info > div h1 {
        color: #fff;
        font-family: 'Lato', sans-serif;
        font-size: 18px;
        font-weight: 700 !important;
        margin: 0 0 1px 0px; }
        .Toastify .Toastify__toast--success > div h1 i:before,
        .Toastify .Toastify__toast--info > div h1 i:before {
          font-size: 14px;
          margin-right: 5px; }
        .Toastify .Toastify__toast--success > div h1 span,
        .Toastify .Toastify__toast--info > div h1 span {
          font-size: 12px;
          font-family: 'Lato', sans-serif;
          color: #fff;
          padding-left: 10px; }
      .Toastify .Toastify__toast--success > div p,
      .Toastify .Toastify__toast--info > div p {
        color: #fff;
        font-family: 'Lato', sans-serif;
        font-size: 14px;
        font-weight: normal; }

.Toastify__close-button {
  font-weight: normal !important;
  font-size: 18px !important;
  color: black !important; }

.Toastify__toast-container {
  z-index: 100000 !important; }

.cursorPointer {
  cursor: pointer; }

.cursorDefault {
  cursor: default !important; }

input[type=date]::-webkit-inner-spin-button,
input[type=date]::-webkit-outer-spin-button {
  -webkit-appearance: none; }

input[type=time]::-webkit-inner-spin-button,
input[type=time]::-webkit-outer-spin-button {
  -webkit-appearance: none; }

.pointerD {
  cursor: default; }

/*COMMON STYLES*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.nopadding {
  padding: 0 !important; }

.clear {
  clear: both;
  line-height: 0;
  font-size: 0;
  overflow: hidden; }

.clearfix {
  *zoom: 1; }

.clearfix:after,
.container:after {
  clear: both;
  content: ".";
  display: block;
  font-size: 0;
  height: 0;
  line-height: 0;
  visibility: hidden; }

:focus,
a {
  outline: none; }

a {
  color: #0093d0;
  font-family: 'Lato', sans-serif;
  cursor: pointer; }
  a:hover {
    text-decoration: underline; }

*, :after, :before, body, button, form, h1, h2, h3, h4, h5, h6, input, label, select, textarea {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

body {
  font-family: 'Lato', sans-serif;
  background: #f8f8f8; }

.formSelectBoxImp,
.formSelectBoxRdonly,
.form-inputField {
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
  display: flex !important; }
  .formSelectBoxImp > label,
  .formSelectBoxRdonly > label,
  .form-inputField > label {
    font-size: 16px;
    line-height: 16px;
    font-family: 'Lato', sans-serif;
    color: #4a4a4a !important;
    transform: translate(0, 17px) scale(1); }
    .formSelectBoxImp > label + div,
    .formSelectBoxRdonly > label + div,
    .form-inputField > label + div {
      margin-top: 16px; }
      .formSelectBoxImp > label + div:before,
      .formSelectBoxRdonly > label + div:before,
      .form-inputField > label + div:before {
        border-bottom: 1px solid #9b9b9b;
        height: 30px; }
      .formSelectBoxImp > label + div:after,
      .formSelectBoxRdonly > label + div:after,
      .form-inputField > label + div:after {
        border-bottom: 1px solid #0093d0; }
      .formSelectBoxImp > label + div input,
      .formSelectBoxRdonly > label + div input,
      .form-inputField > label + div input {
        line-height: 22px;
        height: auto;
        padding: 2px 0 6px 0;
        font-size: 16px;
        color: #4a4a4a !important;
        font-family: 'Lato', sans-serif; }
      .formSelectBoxImp > label + div:hover:before,
      .formSelectBoxRdonly > label + div:hover:before,
      .form-inputField > label + div:hover:before {
        border-bottom: 1px solid #0093d0 !important; }
    .formSelectBoxImp > label[data-shrink="true"],
    .formSelectBoxRdonly > label[data-shrink="true"],
    .form-inputField > label[data-shrink="true"] {
      transform: translate(0, 1.5px) scale(0.75); }
  .formSelectBoxImp.formSelectBoxImp > label, .formSelectBoxImp.form-inputFieldImp > label,
  .formSelectBoxRdonly.formSelectBoxImp > label,
  .formSelectBoxRdonly.form-inputFieldImp > label,
  .form-inputField.formSelectBoxImp > label,
  .form-inputField.form-inputFieldImp > label {
    padding-left: 12px; }
    .formSelectBoxImp.formSelectBoxImp > label + div:before, .formSelectBoxImp.form-inputFieldImp > label + div:before,
    .formSelectBoxRdonly.formSelectBoxImp > label + div:before,
    .formSelectBoxRdonly.form-inputFieldImp > label + div:before,
    .form-inputField.formSelectBoxImp > label + div:before,
    .form-inputField.form-inputFieldImp > label + div:before {
      border-left: 2px solid #ff6868; }
    .formSelectBoxImp.formSelectBoxImp > label + div input, .formSelectBoxImp.form-inputFieldImp > label + div input,
    .formSelectBoxRdonly.formSelectBoxImp > label + div input,
    .formSelectBoxRdonly.form-inputFieldImp > label + div input,
    .form-inputField.formSelectBoxImp > label + div input,
    .form-inputField.form-inputFieldImp > label + div input {
      padding-left: 12px !important;
      padding-right: 12px !important; }
    .formSelectBoxImp.formSelectBoxImp > label[data-shrink="true"], .formSelectBoxImp.form-inputFieldImp > label[data-shrink="true"],
    .formSelectBoxRdonly.formSelectBoxImp > label[data-shrink="true"],
    .formSelectBoxRdonly.form-inputFieldImp > label[data-shrink="true"],
    .form-inputField.formSelectBoxImp > label[data-shrink="true"],
    .form-inputField.form-inputFieldImp > label[data-shrink="true"] {
      padding-left: 0px;
      color: #4a4a4a; }

.selectSingle .formSelectBoxImp div > div > div[aria-pressed] > div > div[role=button] {
  background: transparent !important; }

.selectSingle .formSelectBoxImp div > div > div[aria-pressed] > div > div[role=button] span {
  padding-left: 12px; }

.selectSingle .formSelectBoxImp > label + div {
  min-height: 30px; }
  .selectSingle .formSelectBoxImp > label + div > div > div {
    padding: 4px 0 4px; }
    .selectSingle .formSelectBoxImp > label + div > div > div:focus {
      background: transparent !important; }
    .selectSingle .formSelectBoxImp > label + div > div > div > div > div[role="button"] {
      height: 22px; }

.checkBoxWrap span:first-child {
  width: 40px;
  height: 40px; }

.checkBoxWrap span:last-child {
  font-family: 'Lato', sans-serif;
  font-size: 14px; }

.boldlabel .checkBoxWrap span:last-child {
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: #171718; }

.radioGroupWrap > h5 {
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  color: #171718; }

.radioGroupWrap.boldlabel > h5 {
  font-weight: 700; }

.radioGroupWrap .radioGroup .radio-col span:first-child {
  width: 40px;
  height: 40px; }

.invalidTxt {
  display: block;
  width: 100%;
  text-align: left;
  font-size: 12px;
  line-height: 14px;
  color: #ff6868;
  letter-spacing: .3px;
  margin-top: 3px; }

.btnPrimary {
  background: #ff6c2f !important;
  color: #fff !important;
  border-radius: 3px !important;
  padding: 8px 22px !important; }
  .btnPrimary span {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 15px; }

.btnPrimaryNew {
  background: #ff6c2f !important;
  color: #fff !important;
  border-radius: 30px !important;
  padding: 8px 22px !important; }
  .btnPrimaryNew span {
    text-decoration: none !important;
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 17px; }

.btnPrimaryShippingForm {
  background: #ff6c2f !important;
  color: #fff !important;
  border-radius: 3px !important;
  margin-left: 110px !important; }
  .btnPrimaryShippingForm span {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 15px; }

.btnInactiveShippingForm {
  background: #e2e2e2 !important;
  color: #7e7e7e !important;
  border-radius: 3px !important;
  box-shadow: none !important;
  margin-left: 110px !important; }
  .btnInactiveShippingForm span {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 15px; }
  .btnInactiveShippingForm.small-btn {
    background: #e2e2e2 !important;
    color: #7e7e7e !important; }

.btnInactive {
  background: #e2e2e2 !important;
  color: #7e7e7e !important;
  border-radius: 3px !important;
  padding: 8px 22px !important;
  box-shadow: none !important; }
  .btnInactive span {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 15px; }
  .btnInactive.small-btn {
    background: #e2e2e2 !important;
    color: #7e7e7e !important; }

.btnSecondary {
  background: #0093d0 !important;
  color: #fff !important;
  border-radius: 3px !important;
  padding: 8px 22px !important;
  box-shadow: none !important; }
  .btnSecondary span {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 15px; }
  .btnSecondary:hover {
    background: #06aae5 !important; }

.btnFacebook {
  background: #1977f3 !important;
  color: #fff !important;
  border-radius: 3px !important;
  border-width: 0 !important;
  padding: 8px 22px !important;
  min-width: 110px !important;
  box-shadow: none !important;
  width: 100% !important;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 14px; }
  .btnFacebook span {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 15px; }
  .btnFacebook:hover {
    background: #06aae5 !important; }

.switcherText {
  font-weight: bold;
  color: #000;
  text-align: center;
  padding-bottom: 15px; }

.switcher {
  text-align: center;
  text-decoration: none !important; }
  .switcher a {
    text-decoration: none;
    font-weight: bold;
    color: #ff6c2f !important; }

.separator {
  display: flex;
  align-items: center;
  text-align: center; }

.separator::before, .separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #000; }

.separator::before {
  margin-right: .25em; }

.separator::after {
  margin-left: .25em; }

.btnCancel {
  background: #ffffff !important;
  color: #000 !important;
  border-radius: 3px !important;
  padding: 8px 22px !important;
  box-shadow: none !important;
  margin: 0 10px !important; }
  .btnCancel span {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 15px;
    color: #7e7e7e; }
  .btnCancel:hover {
    background: #eae4e4 !important; }
  .btnCancel.whitebg {
    background: #fff !important; }
    .btnCancel.whitebg span {
      color: #000; }

.small-btn {
  background: #0093d0 !important;
  color: #fff !important;
  border-radius: 3px !important;
  padding: 6px 15px !important;
  min-width: 90px !important;
  box-shadow: none !important;
  min-height: 30px !important; }
  .small-btn span {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 15px;
    text-transform: none; }

h1 {
  font-size: 30px;
  font-family: 'Lato', sans-serif;
  font-weight: 300; }

h2 {
  font-size: 18px;
  font-family: 'Lato', sans-serif;
  font-weight: 700; }

h3 {
  font-size: 16px;
  font-family: 'Lato', sans-serif;
  font-weight: 700; }

h4 {
  font-size: 14px;
  font-family: 'Lato', sans-serif;
  font-weight: 700; }

h5 {
  font-family: 'Lato', sans-serif;
  font-size: 14px; }

.iconButton button span {
  font-size: 14px;
  color: #4a4a4a;
  font-weight: 400;
  text-transform: none; }
  .iconButton button span i {
    font-size: 23px;
    margin-left: 5px; }
    .iconButton button span i.zmdi-cloud-download {
      font-size: 22px;
      margin-right: 10px;
      margin-left: 0px; }

.iconButton button:hover {
  border-radius: 20px !important; }

.iconButton .popupMenu {
  top: 58px;
  left: -20px;
  right: inherit;
  width: 224px; }
  .iconButton .popupMenu ul li {
    cursor: pointer; }

.radiusBtn span {
  font-size: 14px;
  color: #4a4a4a;
  font-weight: 400;
  text-transform: none;
  color: #0093d0; }

.radiusBtn:hover {
  border-radius: 20px !important; }

.col.textarea .form-inputFieldImp div textarea {
  padding-left: 10px !important;
  padding-right: 10px !important;
  height: 75px; }

.col.textarea .form-inputFieldImp div:before {
  height: 90px; }

.datePicker {
  position: relative; }
  .datePicker .form-inputField input[type="time"] {
    padding-right: 22px !important; }
  .datePicker .calendar-icon {
    position: absolute;
    top: 19px;
    right: 32px;
    pointer-events: none; }
    .datePicker .calendar-icon button {
      border: 0;
      background: #fff; }
      .datePicker .calendar-icon button i {
        font-size: 24px; }
        .datePicker .calendar-icon button i:before {
          color: #4a4a4a; }
    .datePicker .calendar-icon.time {
      right: 26px;
      top: 22px; }
      .datePicker .calendar-icon.time button {
        padding: 0; }
        .datePicker .calendar-icon.time button i {
          font-size: 21px; }
      .datePicker .calendar-icon.time.last {
        right: 0px; }

.datePicker-shipment {
  position: relative; }
  .datePicker-shipment .form-inputField input[type="time"] {
    padding-right: 1px !important; }
  .datePicker-shipment .calendar-icon {
    position: absolute;
    top: 19px;
    right: 32px;
    pointer-events: none; }
    .datePicker-shipment .calendar-icon button {
      border: 0;
      background: #fff; }
      .datePicker-shipment .calendar-icon button i {
        font-size: 24px; }
        .datePicker-shipment .calendar-icon button i:before {
          color: #4a4a4a; }
    .datePicker-shipment .calendar-icon.time {
      right: 27px;
      top: 22px; }
      .datePicker-shipment .calendar-icon.time button {
        padding: 0; }
        .datePicker-shipment .calendar-icon.time button i {
          padding-right: 4px;
          font-size: 21px; }
      .datePicker-shipment .calendar-icon.time.last {
        right: 0; }

.inputField {
  width: 100%;
  clear: both;
  border-bottom: 1px solidd transparent; }
  .inputField .label {
    font-size: 11px;
    color: #4a4a4a;
    line-height: 18px;
    min-height: 18px; }
  .inputField .labelData {
    line-height: 22px;
    height: auto;
    padding: 2px 0 3px 0;
    font-size: 14px;
    color: #4a4a4a;
    font-family: 'Lato', sans-serif;
    min-height: 26px;
    font-weight: 700; }

.formSelectBoxRdonly label + div > div > div {
  padding: 2px 0 3px; }
  .formSelectBoxRdonly label + div > div > div:focus {
    background: transparent; }
  .formSelectBoxRdonly label + div > div > div > div > div[role="button"] {
    height: 25px;
    border-radius: 12px; }

.button-section {
  width: 100%;
  float: left;
  text-align: center; }
  .button-section .buttonSet {
    padding-top: 24px; }
  .button-section button {
    margin: 0 10px;
    min-width: 140px !important; }

.tbl-data {
  border: 1px solid #ddd; }
  .tbl-data thead {
    background: #f8f8f8; }
    .tbl-data thead tr td {
      padding: 15px 10px 15px 10px;
      border-bottom: 1px solid #ddd;
      border-left: 0px solid #ddd;
      color: #9b9b9b;
      font-weight: 700;
      line-height: 20px;
      font-size: 14px; }
  .tbl-data tbody tr td {
    padding: 8px 10px 8px 10px;
    border-bottom: 1px solid #ddd;
    border-left: 0px solid #ddd;
    font-weight: 400;
    line-height: 20px;
    font-size: 12px;
    color: #4a4a4a; }

.error {
  color: #ff6868 !important; }

.success {
  color: #8bc34a !important; }

.initiated {
  color: #2E5394 !important; }

.newInitiated {
  color: #F8700D !important; }

.col .col25 {
  width: 25% !important;
  float: left;
  padding-right: 20px; }

.selectSingle.viewMode .formControl label + div:hover:before {
  border: 0px !important; }

.selectSingle.viewMode .formControl label + div:before {
  border-bottom: 0px; }

.selectSingle.viewMode .formControl label + div:after {
  border-bottom: 0px; }

.selectSingle.viewMode .formControl label + div svg {
  display: none; }

.selectSingle.viewMode .formControl label + div > div > div > div > div[role="button"] {
  background: transparent; }
  .selectSingle.viewMode .formControl label + div > div > div > div > div[role="button"] span {
    padding: 0;
    color: #4a4a4a;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 700; }

.boldlabel1 {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #9b9b9b;
  margin-bottom: 20px; }

.viewCheckbox .checkBoxWrap span:first-child {
  color: #9b9b9b; }

.formSelectBoxRdonly.formControl label + div:after, .formSelectBoxRdonly.formControl label + div:before {
  border-bottom: 0 solid #0093d0; }

.formSelectBoxRdonly div[role=button] {
  background: transparent !important; }

.formSelectBoxRdonly svg {
  display: none; }

.formSelectBoxRdonly.formControl label + div:hover:before {
  border: 0px !important; }

.formSelectBoxRdonly.formControl label + div > div > div > div > div[role="button"] span {
  padding-left: 0;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  font-weight: 700; }

.checkBox-boldLbl .checkBoxWrap span:last-child {
  font-weight: 700; }

.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: .25rem; }
  .alert i {
    font-size: 20px;
    vertical-align: middle;
    margin-right: 6px; }

.alert-danger {
  color: #813838;
  background-color: #fee2e1;
  border-color: #fdd6d6; }

.formControl label + div > div > div:focus {
  background: transparent; }

.selectSingle .formSelectBoxImp.formControl div > div > div[aria-pressed] > div > div[role=button] {
  border-bottom: 0px; }

.radioBtnBlock.wd480 .radioGroup {
  max-width: 480px; }
  .radioBtnBlock.wd480 .radioGroup .radio-col {
    cursor: default; }
    .radioBtnBlock.wd480 .radioGroup .radio-col span {
      cursor: pointer; }

.inputField .label:empty {
  display: none; }

.inputField .labelData:empty {
  display: none; }

.zipViewMode .formControl.autosuggest > label + div {
  border: 0px !important; }
  .zipViewMode .formControl.autosuggest > label + div > div > div {
    background: transparent; }
    .zipViewMode .formControl.autosuggest > label + div > div > div div {
      padding-left: 0 !important;
      color: #4a4a4a !important;
      font-family: "latoregular", sans-serif !important;
      font-size: 14px;
      font-weight: 400; }

.zipViewMode .formControl.autosuggest > label + div > div div[aria-hidden="true"] {
  display: none; }

/* view mode */
.zipViewMode.formControl.autosuggest > label div {
  border: 0px !important; }
  .zipViewMode.formControl.autosuggest > label div > div > div > div {
    padding: 0px; }
    .zipViewMode.formControl.autosuggest > label div > div > div > div > div {
      margin: 0; }
    .zipViewMode.formControl.autosuggest > label div > div > div > div + div {
      display: none; }

.requiredClass .zipViewMode.formControl.autosuggest > label + div {
  border: 0px !important; }

/* end view mode */
.datePicker .calendar-icon button i:before {
  cursor: pointer; }

.upload-image-input {
  height: 0;
  overflow: hidden;
  width: 0; }

.upload-image-btn {
  cursor: pointer; }

/*COMMON CLASSES*/
.floatLeft {
  float: left !important; }

.floatRight {
  float: right !important; }

.floatNone {
  float: none !important; }

.txtCenter {
  text-align: center !important; }

.txtLeft {
  text-align: left; }

.txtRight {
  text-align: right; }

.textJustify {
  text-align: justify; }

.textNowrap {
  white-space: nowrap !important; }

.lowercase {
  text-transform: lowercase !important; }

.uppercase {
  text-transform: uppercase !important; }

.capitalize {
  text-transform: capitalize !important; }

.transformNone {
  text-transform: none !important; }

.txtBlack {
  color: #000 !important; }

.relative {
  position: relative; }

.staticPos {
  position: static !important; }

.height-Auto {
  height: auto !important;
  min-height: 100%; }

.minH-auto {
  min-height: auto !important; }

.ellip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.breakAll {
  word-break: break-all; }

.noBreak {
  word-break: normal !important; }

.noScroll {
  overflow: hidden !important; }

.no_bg {
  background: none !important; }

.noBorder {
  border: none !important; }

.center-block {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.hide {
  display: none !important; }

.show {
  display: block !important; }

.invisible {
  visibility: hidden; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.paddingLR0 {
  padding-left: 0px !important;
  padding-right: 0px !important; }

.pt0 {
  padding-top: 0 !important; }

.pt5 {
  padding-top: 5px !important; }

.pt10 {
  padding-top: 10px !important; }

.pb0 {
  padding-bottom: 0px !important; }

.pb10 {
  padding-bottom: 10px !important; }

.pt20 {
  padding-top: 20px !important; }

.pb4 {
  padding-bottom: 4px !important; }

.pb20 {
  padding-bottom: 20px !important; }

.pl10 {
  padding-left: 10px !important; }

.pl20 {
  padding-left: 20px !important; }

.pl24 {
  padding-left: 24px !important; }

.pr10 {
  padding-right: 10px !important; }

.pr20 {
  padding-right: 20px !important; }

.pr0 {
  padding-right: 0px !important; }

.margin0 {
  margin: 0 !important; }

.mt0 {
  margin-top: 0; }

.mt5 {
  margin-top: 5px !important; }

.mt10 {
  margin-top: 10px !important; }

.mt15 {
  margin-top: 15px !important; }

.mt20 {
  margin-top: 20px !important; }

.mt30 {
  margin-top: 30px !important; }

.mt-20 {
  margin-top: -20px !important; }

.mb0 {
  margin-bottom: 0 !important; }

.mb5 {
  margin-bottom: 5px !important; }

.mb10 {
  margin-bottom: 10px !important; }

.mt-10 {
  margin-top: -10px !important; }

.mb20 {
  margin-bottom: 20px !important; }

.mb30 {
  margin-bottom: 30px !important; }

.mr5 {
  margin-right: 5px !important; }

.mr10 {
  margin-right: 10px !important; }

.mr20 {
  margin-right: 20px !important; }

.mr30 {
  margin-right: 30px !important; }

.ml5 {
  margin-left: 5px !important; }

.ml10 {
  margin-left: 10px !important; }

.ml15 {
  margin-left: 15px !important; }

.ml0 {
  margin-left: 0 !important; }

.width20p {
  width: 20% !important; }

.ml20 {
  margin-left: 20px !important; }

.ml30 {
  margin-left: 30px !important; }

.orange {
  color: #ff6c2f; }

.gray4a {
  color: #4a4a4a; }

.middleIcon {
  display: inline-block;
  width: 24px;
  height: 22px;
  margin: 0 10px;
  vertical-align: middle; }

.leftIcon {
  display: inline-block;
  width: 24px;
  height: 22px;
  margin: 0 10px 0 0;
  vertical-align: middle; }

.valign svg {
  vertical-align: middle !important; }

.commonList {
  list-style: disc;
  margin-left: 30px; }

.commonBg {
  position: fixed;
  left: 0;
  top: 0;
  display: none;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 999; }

.inlineB {
  display: inline-block !important; }

.inline {
  display: inline !important; }

.lineH30 {
  line-height: 30px !important; }

.pointer {
  cursor: pointer !important; }

.iconbgcolor {
  color: #bebebe; }

.tooltipWrap {
  position: relative; }
  .tooltipWrap .tooltiptext {
    position: absolute;
    background-color: #fff;
    color: #4a4a4a;
    text-align: left;
    z-index: 1;
    top: 0px;
    left: 24px;
    margin-left: 0px;
    font-family: 'Lato', sans-serif;
    border-radius: 2px;
    transition: opacity 0.6s ease 0s;
    box-shadow: -1px 1px 6px 1px #e5e2e2;
    width: 230px;
    padding: 10px;
    font-size: 14px;
    line-height: 20px;
    display: none;
    opacity: 0; }
    .tooltipWrap .tooltiptext:after {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent #000 transparent  transparent; }
    .tooltipWrap .tooltiptext.tooltip-top {
      bottom: 100%;
      left: 50%; }
      .tooltipWrap .tooltiptext.tooltip-top:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #000 transparent transparent transparent; }
    .tooltipWrap .tooltiptext.fullScreenTp {
      left: 280px;
      width: calc(100% - 280px);
      margin-top: -18px;
      min-height: 80px;
      line-height: 22px;
      padding: 20px; }
      .tooltipWrap .tooltiptext.fullScreenTp:after {
        content: "";
        position: absolute;
        bottom: 38%;
        left: 0%;
        margin-left: -20px;
        border-width: 10px;
        border-style: solid;
        border-color: transparent #fff transparent  transparent; }
    .tooltipWrap .tooltiptext.smallTP {
      background: #000;
      color: #fff;
      width: 100px;
      top: 38px;
      bottom: 0px;
      left: -35px;
      min-height: 30px;
      padding: 5px 10px;
      text-align: center; }
      .tooltipWrap .tooltiptext.smallTP:after {
        border-color: transparent transparent #000   transparent; }
  .tooltipWrap:hover .tooltiptext {
    display: block;
    opacity: 1; }

/* error toast */
.Toastify {
  color: #fff; }
  .Toastify .Toastify__toast--warning {
    color: #fff;
    padding: 6px 8px;
    min-height: 60px;
    background: #ffbf00 !important;
    border-radius: 10px; }
    .Toastify .Toastify__toast--warning > div {
      background: transparent !important;
      padding-left: 6px; }
      .Toastify .Toastify__toast--warning > div h1 {
        color: #fff;
        font-family: 'Lato', sans-serif;
        font-weight: 700 !important;
        font-size: 18px;
        margin: 0 0 1px 0; }
        .Toastify .Toastify__toast--warning > div h1 i:before {
          font-size: 14px;
          margin-right: 5px; }
        .Toastify .Toastify__toast--warning > div h1 span {
          font-size: 12px;
          font-family: 'Lato', sans-serif;
          color: #ffdccd;
          padding-left: 10px; }
      .Toastify .Toastify__toast--warning > div p {
        color: #fff;
        font-family: 'Lato', sans-serif;
        font-size: 14px;
        font-weight: 400; }
  .Toastify .Toastify__toast--error {
    color: #fff;
    padding: 6px 8px;
    min-height: 60px;
    background: #ff6c2f !important;
    border-radius: 10px; }
    .Toastify .Toastify__toast--error > div {
      background: transparent !important;
      padding-left: 6px; }
      .Toastify .Toastify__toast--error > div h1 {
        color: #fff;
        font-family: 'Lato', sans-serif;
        font-weight: 700 !important;
        font-size: 18px;
        margin: 0 0 1px 0; }
        .Toastify .Toastify__toast--error > div h1 i:before {
          font-size: 14px;
          margin-right: 5px; }
        .Toastify .Toastify__toast--error > div h1 span {
          font-size: 12px;
          font-family: 'Lato', sans-serif;
          color: #ffdccd;
          padding-left: 10px; }
      .Toastify .Toastify__toast--error > div p {
        color: #fff;
        font-family: 'Lato', sans-serif;
        font-size: 14px;
        font-weight: 400; }
  .Toastify .Toastify__toast--success,
  .Toastify .Toastify__toast--info {
    color: #fff;
    padding: 6px 8px;
    min-height: 60px;
    background: #00B67A !important;
    border-radius: 10px; }
    .Toastify .Toastify__toast--success > div,
    .Toastify .Toastify__toast--info > div {
      background: transparent !important;
      padding-left: 6px; }
      .Toastify .Toastify__toast--success > div h1,
      .Toastify .Toastify__toast--info > div h1 {
        color: #fff;
        font-family: 'Lato', sans-serif;
        font-size: 18px;
        font-weight: 700 !important;
        margin: 0 0 1px 0px; }
        .Toastify .Toastify__toast--success > div h1 i:before,
        .Toastify .Toastify__toast--info > div h1 i:before {
          font-size: 14px;
          margin-right: 5px; }
        .Toastify .Toastify__toast--success > div h1 span,
        .Toastify .Toastify__toast--info > div h1 span {
          font-size: 12px;
          font-family: 'Lato', sans-serif;
          color: #fff;
          padding-left: 10px; }
      .Toastify .Toastify__toast--success > div p,
      .Toastify .Toastify__toast--info > div p {
        color: #fff;
        font-family: 'Lato', sans-serif;
        font-size: 14px;
        font-weight: normal; }

.Toastify__close-button {
  font-weight: normal !important;
  font-size: 18px !important;
  color: black !important; }

.Toastify__toast-container {
  z-index: 100000 !important; }

.cursorPointer {
  cursor: pointer; }

.cursorDefault {
  cursor: default !important; }

input[type=date]::-webkit-inner-spin-button,
input[type=date]::-webkit-outer-spin-button {
  -webkit-appearance: none; }

input[type=time]::-webkit-inner-spin-button,
input[type=time]::-webkit-outer-spin-button {
  -webkit-appearance: none; }

.pointerD {
  cursor: default; }

.left-sidebar-nav {
  height: 100%;
  width: 109px;
  float: left;
  position: fixed;
  top: 66px;
  background-color: #f0f0f0;
  z-index: 3;
  transition: .5s;
  box-shadow: 0 5px 5px 1px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 5px 5px 1px rgba(0, 0, 0, 0.3); }
  .left-sidebar-nav .user-account {
    height: 137px;
    padding: 41.5px 0;
    background-color: #ff6b0b;
    overflow: hidden; }
    .left-sidebar-nav .user-account .info {
      display: flex;
      width: max-content;
      height: 41px;
      margin: auto;
      cursor: pointer; }
      .left-sidebar-nav .user-account .info .avatar {
        margin: auto;
        height: 41px;
        width: 41px; }
        .left-sidebar-nav .user-account .info .avatar img, .left-sidebar-nav .user-account .info .avatar svg {
          width: 100%;
          height: 100%; }
      .left-sidebar-nav .user-account .info .names {
        display: none; }
    .left-sidebar-nav .user-account .expander {
      width: 22px;
      height: 22px;
      background-color: #fff;
      border-radius: 50%;
      position: absolute;
      top: 126px;
      float: right;
      right: -10px;
      border: .5px solid grey; }
      .left-sidebar-nav .user-account .expander img {
        width: 100%;
        height: 100%;
        transform: rotate(270deg); }
  .left-sidebar-nav .menu-items {
    height: 100%;
    width: 100%;
    overflow: hidden; }
    .left-sidebar-nav .menu-items .row {
      height: 36px;
      margin: 40px auto;
      cursor: pointer; }
      .left-sidebar-nav .menu-items .row .icon {
        width: 36px;
        height: 36px;
        margin: auto; }
        .left-sidebar-nav .menu-items .row .icon img, .left-sidebar-nav .menu-items .row .icon svg {
          width: 100%;
          height: 100%; }
      .left-sidebar-nav .menu-items .row .link {
        display: none; }
  .left-sidebar-nav.sideNav-active {
    width: 258px; }
    .left-sidebar-nav.sideNav-active .user-account {
      height: 137px;
      padding: 41.5px 0; }
      .left-sidebar-nav.sideNav-active .user-account .info {
        display: flex;
        width: max-content;
        height: 61px;
        margin: auto; }
        .left-sidebar-nav.sideNav-active .user-account .info .avatar {
          height: 61px;
          width: 61px; }
        .left-sidebar-nav.sideNav-active .user-account .info .names {
          display: table;
          text-align: center;
          margin: auto;
          margin-left: 10px;
          height: 100%;
          max-width: min-content; }
          .left-sidebar-nav.sideNav-active .user-account .info .names .name {
            color: #fff;
            font-size: 20px;
            line-height: 20px;
            font-weight: bold;
            width: 100%;
            height: 100%;
            display: table-cell;
            vertical-align: middle; }
          .left-sidebar-nav.sideNav-active .user-account .info .names .title {
            color: #fff;
            font-size: 12px; }
      .left-sidebar-nav.sideNav-active .user-account .expander img {
        transform: rotate(90deg); }
    .left-sidebar-nav.sideNav-active .menu-items .row {
      display: flex;
      width: 205px; }
      .left-sidebar-nav.sideNav-active .menu-items .row .icon {
        margin: 10px; }
      .left-sidebar-nav.sideNav-active .menu-items .row .link {
        display: inline-block;
        font-size: 18px;
        text-decoration: underline;
        color: #555;
        margin-left: 7px;
        margin-top: 19px; }

@media only screen and (min-width: 1154px) and (max-width: 1680px) {
  .left-sidebar-nav {
    width: 80px; }
    .left-sidebar-nav .user-account {
      height: 120px; }
      .left-sidebar-nav .user-account .expander {
        top: 109px; }
    .left-sidebar-nav .menu-items .row .icon {
      width: 32px;
      height: 32px; }
    .left-sidebar-nav.sideNav-active {
      width: 258px; }
      .left-sidebar-nav.sideNav-active .user-account {
        height: 120px;
        padding: 34px 0; }
        .left-sidebar-nav.sideNav-active .user-account .info {
          height: 61px; }
          .left-sidebar-nav.sideNav-active .user-account .info .avatar {
            height: 50px;
            width: 50px; }
          .left-sidebar-nav.sideNav-active .user-account .info .names .name {
            font-size: 20px;
            line-height: 20px; }
          .left-sidebar-nav.sideNav-active .user-account .info .names .title {
            font-size: 12px; }
      .left-sidebar-nav.sideNav-active .menu-items .row .icon {
        margin: 8px; }
      .left-sidebar-nav.sideNav-active .menu-items .row .link {
        margin-left: 14px;
        margin-top: 13px; } }

@media only screen and (min-width: 300px) and (max-width: 1154px) {
  .left-sidebar-nav {
    display: none; }
    .left-sidebar-nav.sideNav-active {
      display: block;
      width: 180px;
      top: 50px;
      right: 0; }
      .left-sidebar-nav.sideNav-active .user-account {
        height: 120px; }
        .left-sidebar-nav.sideNav-active .user-account .info {
          width: 150px;
          height: 41px; }
          .left-sidebar-nav.sideNav-active .user-account .info .avatar {
            height: 40px;
            width: 40px; }
          .left-sidebar-nav.sideNav-active .user-account .info .names {
            margin-left: 5px; }
            .left-sidebar-nav.sideNav-active .user-account .info .names .name {
              font-size: 14px;
              line-height: 14px; }
            .left-sidebar-nav.sideNav-active .user-account .info .names .title {
              font-size: 8px; }
        .left-sidebar-nav.sideNav-active .user-account .expander {
          display: none; }
      .left-sidebar-nav.sideNav-active .menu-items .row {
        margin: 25px auto;
        width: 158px; }
        .left-sidebar-nav.sideNav-active .menu-items .row .icon {
          width: 25px;
          height: 25px;
          margin: 8px 8px 8px 5px; }
          .left-sidebar-nav.sideNav-active .menu-items .row .icon svg {
            width: 32px;
            height: 32px; }
        .left-sidebar-nav.sideNav-active .menu-items .row .link {
          font-size: 14px;
          margin-left: 5px;
          margin-top: 16px; } }

.crossedLink {
  position: relative; }

.crossedLink::after {
  pointer-events: none;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom left, transparent calc(50% - 1px), grey, transparent calc(50% + 2px)); }

.credit-app-progress-bar {
  height: 140px;
  padding-top: 100px;
  margin: auto;
  display: table;
  width: inherit; }
  .credit-app-progress-bar .first-row {
    width: auto;
    display: flex;
    padding-left: 40px;
    margin: auto; }
    .credit-app-progress-bar .first-row .point {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      border: 3px solid #ff6b0b;
      margin: 0 10px; }
      .credit-app-progress-bar .first-row .point.filled {
        background-color: #ff6b0b; }
    .credit-app-progress-bar .first-row .space {
      margin: auto 4px;
      width: 67px;
      height: 1px;
      border-radius: 2.5px;
      border: 2px solid #ff6b0b;
      background-color: #ff6b0b; }
  .credit-app-progress-bar .second-row {
    display: inline-flex;
    margin: auto; }
    .credit-app-progress-bar .second-row .description {
      font-size: 14px;
      line-height: 14px;
      color: #676767;
      margin: 7px auto 0;
      width: 111px;
      text-align: center; }

@media only screen and (min-width: 530px) and (max-width: 630px) {
  .credit-app-progress-bar {
    height: 140px;
    padding-top: 85px; }
    .credit-app-progress-bar .first-row {
      padding-left: 30px; }
      .credit-app-progress-bar .first-row .point {
        margin: 0 10px; }
      .credit-app-progress-bar .first-row .space {
        width: 58px; }
    .credit-app-progress-bar .second-row .description {
      font-size: 10px;
      line-height: 10px;
      margin: 5px auto 0;
      width: 100px; } }

@media only screen and (min-width: 300px) and (max-width: 530px) {
  .credit-app-progress-bar {
    height: 140px;
    padding-top: 70px; }
    .credit-app-progress-bar .first-row {
      width: max-content;
      padding-left: 0; }
      .credit-app-progress-bar .first-row .point {
        width: 10px;
        height: 10px;
        margin: 0 5px;
        border: 2px solid #ff6b0b; }
      .credit-app-progress-bar .first-row .space {
        width: 22px; }
    .credit-app-progress-bar .second-row {
      margin: auto; }
      .credit-app-progress-bar .second-row .description {
        font-size: 10px;
        line-height: 10px;
        margin: 5px auto 0;
        width: 50px; } }

.page {
  width: 100%;
  float: left; }
  .page .headerSection {
    width: 100%;
    float: left;
    margin: 0;
    padding: 0;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1501;
    box-shadow: 1px 1px 30px #d4d4d4;
    -webkit-box-shadow: 1px 1px 30px #d4d4d4; }
    .page .headerSection .logoSection {
      float: left; }
      .page .headerSection .logoSection h1 {
        padding: 8px 0;
        line-height: 24px;
        float: left; }
        .page .headerSection .logoSection h1 img {
          width: 180px;
          height: 50px;
          cursor: pointer; }
      .page .headerSection .logoSection .phone-number {
        float: left;
        font-size: 14px;
        font-weight: 700;
        margin: 28px 0 0 10px;
        color: #bf3501;
        text-decoration: none; }
    .page .headerSection .hederRight {
      float: right; }
      .page .headerSection .hederRight .topNav {
        float: left; }
        .page .headerSection .hederRight .topNav ul {
          float: left;
          margin: 0;
          padding: 0; }
          .page .headerSection .hederRight .topNav ul li {
            float: left;
            margin: 0 12px;
            padding: 18px 2px 15px;
            font-family: 'Lato', sans-serif;
            line-height: 34px;
            text-transform: uppercase;
            font-size: 14px;
            color: #808080;
            font-weight: 400;
            border-bottom: 3px solid transparent;
            cursor: pointer; }
            .page .headerSection .hederRight .topNav ul li:first-child {
              font-weight: 900;
              color: #7b7b7b; }
            .page .headerSection .hederRight .topNav ul li:hover {
              color: #ff6c2f; }
            .page .headerSection .hederRight .topNav ul li.active {
              color: #ff6c2f;
              border-bottom: 3px solid #ff6c2f; }
            .page .headerSection .hederRight .topNav ul li i {
              margin-left: 5px;
              font-size: 15px; }
        .page .headerSection .hederRight .topNav.account ul > li {
          padding: 0;
          margin: 15px 5px 8px; }
          .page .headerSection .hederRight .topNav.account ul > li > a {
            color: #4a4a4a;
            font-weight: 400;
            font-size: 14px;
            padding: 12px 12px;
            line-height: 16px;
            display: block;
            border-radius: 40px;
            transition: .2s; }
          .page .headerSection .hederRight .topNav.account ul > li:hover a {
            background: #f2f2f2;
            text-decoration: none; }
          .page .headerSection .hederRight .topNav.account ul > li.active {
            border: 0; }
            .page .headerSection .hederRight .topNav.account ul > li.active a {
              background: #f2f2f2;
              text-decoration: none;
              color: #ff6c2f; }
      .page .headerSection .hederRight .searchSection {
        float: left;
        margin-top: 12px;
        margin-left: 15px; }
        .page .headerSection .hederRight .searchSection input[type="text"] {
          border: 0;
          border-bottom: 1px solid #000;
          padding: 10px 0 1px 0;
          line-height: 22px;
          font-family: 'Lato', sans-serif;
          font-weight: 700;
          width: 140px;
          margin-right: 8px; }
        .page .headerSection .hederRight .searchSection i {
          font-size: 24px;
          color: #080808; }
      .page .headerSection .hederRight .accountSection {
        float: left;
        margin-top: 17px;
        margin-left: 2px;
        cursor: pointer; }
        .page .headerSection .hederRight .accountSection i {
          font-size: 34px;
          color: #9b9b9b; }
        .page .headerSection .hederRight .accountSection button {
          padding: 0 !important;
          margin: 0 !important;
          width: 36px;
          height: 36px;
          min-width: inherit !important;
          border: 4px solid #fff;
          border-radius: 100%;
          min-height: inherit !important; }
          .page .headerSection .hederRight .accountSection button:hover {
            border: 4px solid #f3ecec; }
        .page .headerSection .hederRight .accountSection:hover .popupMenu {
          display: block; }
    .page .headerSection.noShodow {
      box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3); }
    .page .headerSection .headerWrapper {
      max-width: 1366px;
      margin: 0 auto;
      padding: 0 40px; }
  .page .mainSection {
    width: 100%;
    float: left;
    background: #555; }
  .page .footer {
    display: none; }
  .page .footerSection {
    width: 100%;
    float: left; }
    .page .footerSection h2 {
      color: #87aacb;
      font-weight: 900;
      font-size: 14px;
      margin-bottom: 6px; }
    .page .footerSection ul {
      margin: 0;
      padding: 0;
      list-style: none;
      float: left; }
      .page .footerSection ul li {
        margin: 0;
        padding: 0;
        color: #66686a; }
        .page .footerSection ul li a {
          color: #66686a;
          font-size: 13px;
          line-height: 1.7;
          text-decoration: none; }
    .page .footerSection svg {
      overflow: hidden;
      vertical-align: middle; }
    .page .footerSection .footerinn {
      width: 100%;
      float: none;
      margin: 0 auto;
      max-width: 1466px; }
      .page .footerSection .footerinn .wraper {
        width: 100%;
        float: left;
        padding: 0 70px 0 118px;
        display: flex;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-left: -15px; }
        .page .footerSection .footerinn .wraper .cal1 {
          width: 23%;
          float: left;
          min-height: 200px;
          padding: 0 20px; }
          .page .footerSection .footerinn .wraper .cal1:first-child {
            border-left: 0; }
          .page .footerSection .footerinn .wraper .cal1:last-child {
            border-right: 0; }
          .page .footerSection .footerinn .wraper .cal1 .social-icons {
            clear: both;
            padding-top: 10px; }
            .page .footerSection .footerinn .wraper .cal1 .social-icons ul {
              width: 100%; }
              .page .footerSection .footerinn .wraper .cal1 .social-icons ul li {
                display: inline-block;
                margin: 0 15px 0 0;
                position: relative; }
                .page .footerSection .footerinn .wraper .cal1 .social-icons ul li:after {
                  content: '';
                  position: absolute;
                  background: #fff;
                  width: 24px;
                  height: 25px;
                  left: 2px;
                  top: 4px;
                  border-radius: 6px; }
                .page .footerSection .footerinn .wraper .cal1 .social-icons ul li i {
                  font-size: 32px;
                  position: relative;
                  z-index: 2;
                  border-radius: 6px;
                  overflow: hidden;
                  cursor: pointer; }
                  .page .footerSection .footerinn .wraper .cal1 .social-icons ul li i.zmdi-facebook-box {
                    color: #355480; }
                  .page .footerSection .footerinn .wraper .cal1 .social-icons ul li i.zmdi-twitter-box {
                    color: #479098; }
                  .page .footerSection .footerinn .wraper .cal1 .social-icons ul li i.zmdi-google-plus-box {
                    color: #da463a; }
        .page .footerSection .footerinn .wraper .cal2 {
          width: 50%;
          float: left;
          padding: 0 20px; }
          .page .footerSection .footerinn .wraper .cal2:first-child {
            border-left: 0; }
          .page .footerSection .footerinn .wraper .cal2:last-child {
            border-right: 0; }
        .page .footerSection .footerinn .wraper p {
          clear: both;
          padding-top: 6px;
          color: #66686a;
          font-size: 10px;
          font-weight: 700; }
  .page .mr-5 {
    margin-right: 6rem !important; }
  .page .mr-6 {
    margin-right: 128px !important; }
  .page .text-md-right {
    text-align: right !important; }

.popupMenu {
  background: #fff;
  padding: 15px 0;
  float: left;
  width: 140px;
  position: absolute;
  z-index: 1;
  top: 36px;
  right: -32px;
  -webkit-box-shadow: 2px 2px 14px #ccc;
  box-shadow: 2px 2px 14px #ccc;
  display: none; }
  .popupMenu .menuList {
    margin: 0;
    padding: 0;
    list-style: none; }
    .popupMenu .menuList li {
      padding: 8px 17px;
      font-family: 'Lato', sans-serif;
      font-weight: 700;
      font-size: 14px;
      color: #0093d0; }
    .popupMenu .menuList li:hover {
      color: #4a4a4a; }
  .popupMenu:before {
    content: '';
    border: 7px solid #ccc;
    border-color: transparent transparent #ffffff;
    position: absolute;
    top: -13px;
    right: 40px; }
  .popupMenu.open {
    width: auto;
    display: block; }

.not-logged-in {
  background-color: #0e1923;
  background-image: url("../../assets/images/registration-bg-new.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center; }

.DialogBox.medium > div[role=document] {
  max-width: 780px;
  width: 100%;
  border-radius: 0; }
  .DialogBox.medium > div[role=document] .DialogBoxcontent {
    padding: 0; }

.DialogBox.mediumPopup > div[role=document] {
  max-width: 980px;
  width: 100%;
  border-radius: 4px; }
  .DialogBox.mediumPopup > div[role=document] .DialogBoxcontent {
    padding: 24px 24px 20px; }

.DialogBox.small > div[role="document"] {
  max-width: 450px;
  width: 100%;
  border-radius: 4px; }

.DialogBox.small #alert-dialog-title {
  border-bottom: 1px solid #ddd;
  background: #0093d0;
  padding: 15px 24px 15px 24px; }
  .DialogBox.small #alert-dialog-title h2 {
    color: #fff; }

.DialogBox.small .DialogTitle {
  padding-bottom: 0; }
  .DialogBox.small .DialogTitle h2 {
    color: #000; }
    .DialogBox.small .DialogTitle h2 i:before {
      font-size: 22px;
      margin-right: 8px; }

.DialogBox.small .DialogBoxcontent {
  padding-bottom: 10px; }
  .DialogBox.small .DialogBoxcontent .col {
    color: #4a4a4a; }
  .DialogBox.small .DialogBoxcontent .sm-popupMessage {
    padding: 20px 0; }
    .DialogBox.small .DialogBoxcontent .sm-popupMessage p {
      line-height: 20px;
      font-family: 'Lato', sans-serif;
      font-weight: 400;
      font-size: 16px;
      color: #4a4a4a; }

.DialogBox.small .DialogBoxfooter {
  background: #fff;
  border-top: 2px solid #ececec;
  margin: 0;
  padding: 8px 24px; }
  .DialogBox.small .DialogBoxfooter button {
    min-width: 133px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-align: center; }

.DialogBox.mediumSmall > div[role=document] {
  max-width: 630px;
  width: 100%;
  border-radius: 0; }
  .DialogBox.mediumSmall > div[role=document] .DialogBoxcontent {
    padding: 0; }
  @media (max-width: 600px) {
    .DialogBox.mediumSmall > div[role=document] {
      margin-top: 48px; } }

.DialogBox.extraSmall > div[role=document] {
  max-width: 370px;
  width: 100%;
  border-radius: 0; }
  .DialogBox.extraSmall > div[role=document] .DialogBoxcontent {
    padding: 0; }
  @media (max-width: 600px) {
    .DialogBox.extraSmall > div[role=document] {
      margin-top: 48px; } }

.DialogBox.extraSmall [class*="inputContainer"] {
  margin: 0; }

.DialogBox.extraSmall [class*="submitButton"] {
  margin-bottom: 16px; }

.DialogBox.extraSmall [class*="itemText"] {
  padding-left: 16px; }

.DialogBox.extraSmall [class*="termsAndConditionsText"] {
  padding: 5px 0 20px 0; }

.DialogBox.extraSmall [class*="contentTitleText"] {
  margin-bottom: 16px;
  text-align: left; }

.DialogBox.extraSmall .googleSignIn {
  padding: 0px 40px 18px; }

.DialogBoxShippingFrequency.medium > div[role=document] {
  max-width: 350px;
  width: 100%;
  border-radius: 10px; }
  .DialogBoxShippingFrequency.medium > div[role=document] .DialogBoxcontent {
    padding: 0; }

.DialogBoxShippingFrequency.mediumPopup > div[role=document] {
  max-width: 980px;
  width: 100%;
  border-radius: 4px; }
  .DialogBoxShippingFrequency.mediumPopup > div[role=document] .DialogBoxcontent {
    padding: 24px 24px 20px; }

.DialogBoxShippingFrequency.small > div[role="document"] {
  max-width: 450px;
  width: 100%;
  border-radius: 4px; }

.DialogBoxShippingFrequency.small #alert-dialog-title {
  border-bottom: 1px solid #ddd;
  background: #0093d0;
  padding: 15px 24px 15px 24px; }
  .DialogBoxShippingFrequency.small #alert-dialog-title h2 {
    color: #fff; }

.DialogBoxShippingFrequency.small .DialogTitle {
  padding-bottom: 0; }
  .DialogBoxShippingFrequency.small .DialogTitle h2 {
    color: #000; }
    .DialogBoxShippingFrequency.small .DialogTitle h2 i:before {
      font-size: 22px;
      margin-right: 8px; }

.DialogBoxShippingFrequency.small .DialogBoxcontent {
  padding-bottom: 10px; }
  .DialogBoxShippingFrequency.small .DialogBoxcontent .col {
    color: #4a4a4a; }
  .DialogBoxShippingFrequency.small .DialogBoxcontent .sm-popupMessage {
    padding: 20px 0; }
    .DialogBoxShippingFrequency.small .DialogBoxcontent .sm-popupMessage p {
      line-height: 20px;
      font-family: 'Lato', sans-serif;
      font-weight: 400;
      font-size: 16px;
      color: #4a4a4a; }

.DialogBoxShippingFrequency.small .DialogBoxfooter {
  background: #fff;
  border-top: 2px solid #ececec;
  margin: 0;
  padding: 8px 24px; }
  .DialogBoxShippingFrequency.small .DialogBoxfooter button {
    min-width: 133px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-align: center; }

.mdlScroll {
  flex: 0 1 auto;
  -webkit-flex: 0 1 auto;
  -webkit-display: flex;
  display: flex;
  max-height: calc(100% - 64px);
  overflow-y: auto;
  flex-direction: column; }

.closePopup {
  position: absolute;
  right: 12px;
  top: 10px;
  color: #7e7e7e;
  font-size: 24px;
  z-index: 1;
  cursor: pointer; }

.section-loader {
  position: fixed;
  left: 0;
  right: 0;
  text-align: center;
  background: #fff;
  height: 100%;
  top: 0;
  bottom: 0;
  margin-top: 0;
  opacity: .5;
  z-index: 10; }
  .section-loader > div {
    top: 50%;
    position: relative; }

.freight-quote-section-loader {
  position: fixed;
  left: 0;
  right: 0;
  text-align: center;
  background: #fff;
  height: 100%;
  top: 0;
  bottom: 0;
  margin-top: 0;
  opacity: 1;
  z-index: 10; }
  .freight-quote-section-loader > div {
    top: 50%;
    position: relative; }

.hidden {
  display: none; }

.DialogBox {
  top: 35px !important;
  bottom: -35px !important; }

.ca-dialog-title {
  background-color: #4193b2;
  padding: 12px 24px !important;
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .ca-dialog-title .h2 {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 36px; }
    .ca-dialog-title .h2 i.white {
      color: white; }
  .ca-dialog-title .label {
    font-size: 14px;
    font-weight: bold;
    color: white;
    width: 250px; }
  .ca-dialog-title .sign {
    display: flex;
    align-items: center;
    flex-grow: 1;
    cursor: pointer; }
    .ca-dialog-title .sign .signature {
      flex-grow: 1;
      padding: 0 0 0 10px;
      margin: 0 !important;
      background-color: white;
      border-radius: 20px 0 0 20px;
      width: 530px; }
      .ca-dialog-title .sign .signature input {
        font-size: 12px; }
      .ca-dialog-title .sign .signature ::placeholder {
        font-size: 12px; }
      .ca-dialog-title .sign .signature.border {
        border: 2px solid red; }
    .ca-dialog-title .sign button {
      padding: 0 30px !important;
      border-radius: 0 20px 20px 0 !important;
      cursor: pointer;
      color: white !important;
      min-height: 32px !important;
      text-transform: none;
      background-color: #ff6600 !important; }
      .ca-dialog-title .sign button span {
        font-size: 12px; }
  .ca-dialog-title.normal {
    height: 44px;
    background-color: white; }
  @media (max-width: 768px) {
    .ca-dialog-title .label {
      display: none; }
    .ca-dialog-title .signature {
      width: 205px !important; } }

.col-center {
  margin-left: 33%;
  margin-right: 33%;
  width: 34%;
  text-align: center; }

.td-middle {
  vertical-align: middle; }

.icon-button {
  font-size: 24px;
  cursor: pointer; }

.unsubscribe-text {
  text-align: center;
  padding-top: 250px; }

.cra-signature {
  position: absolute !important;
  left: 130px;
  bottom: 12px;
  width: 200px; }

.cra-signature input::placeholder {
  font-size: 12px; }

.cra-signature input:-ms-input-placeholder {
  /* IE 10+ */
  font-size: 12px; }

.cra-signature input::-ms-input-placeholder {
  /*Edge - only work on some properties */
  letter-spacing: 3px; }

.embeded {
  padding: 20px;
  text-align: center; }

.autosize {
  height: auto;
  width: 100%; }

.scroll {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto; }

.pagination-section {
  margin: 0px 0 0 0; }
  .pagination-section .pagination-wraper .pagination {
    margin: 0;
    padding: 0;
    list-style: none;
    background: #fff;
    display: inline-block;
    height: 30px;
    border: 1px solid rgba(204, 204, 204, 0.5);
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-box-sizing: 3px;
    border-radius: 3px;
    vertical-align: top; }
    .pagination-section .pagination-wraper .pagination li {
      margin: 1px 1px 0 0px;
      padding: 0;
      display: inline-block;
      vertical-align: top; }
      .pagination-section .pagination-wraper .pagination li a {
        text-decoration: none;
        margin: 0;
        padding: 4px 4px 2px;
        min-width: 20px;
        font-family: "latobold", sans-serif;
        display: inline-block;
        text-align: center;
        font-size: 14px;
        color: #4a4a4a;
        line-height: 17px; }
        .pagination-section .pagination-wraper .pagination li a:hover {
          color: #0093d0; }
        .pagination-section .pagination-wraper .pagination li a.icon {
          padding: 0; }
      .pagination-section .pagination-wraper .pagination li button {
        width: 22px;
        height: 22px; }
      .pagination-section .pagination-wraper .pagination li.skip-previous button {
        margin-left: 2px; }
      .pagination-section .pagination-wraper .pagination li.skip-previous i {
        position: relative;
        top: -2px; }
      .pagination-section .pagination-wraper .pagination li.previous button {
        margin-left: 2px; }
      .pagination-section .pagination-wraper .pagination li.skip-next button {
        margin-right: 1px; }
      .pagination-section .pagination-wraper .pagination li.skip-next i {
        position: relative;
        top: -2px; }
      .pagination-section .pagination-wraper .pagination li.next button {
        margin-right: 1px;
        margin-left: 1px; }
      .pagination-section .pagination-wraper .pagination li.current a {
        color: #0093d0;
        border-bottom: 2px solid #0093d0;
        padding-top: 1px;
        padding-bottom: 2px;
        line-height: 23px; }
      .pagination-section .pagination-wraper .pagination li.act i:before {
        color: #0093d0; }
      .pagination-section .pagination-wraper .pagination li i::before {
        font-size: 18px; }
      .pagination-section .pagination-wraper .pagination li .zmdi-caret-left:before {
        font-size: 24px;
        line-height: 27px; }
      .pagination-section .pagination-wraper .pagination li .zmdi-caret-right:before {
        font-size: 24px;
        line-height: 27px; }
  .pagination-section .pagination-wraper .item-per-page {
    display: inline-block;
    margin: 0 5px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-box-sizing: 3px;
    border-radius: 3px; }
    .pagination-section .pagination-wraper .item-per-page .text-perpage {
      padding-left: 12px;
      font-weight: normal;
      font-size: 14px;
      color: #9b9b9b; }

.selectoption div div {
  border: 0; }
  .selectoption div div::before {
    border: 0; }
  .selectoption div div:hover {
    border: 0; }
  .selectoption div div:hover:before {
    border: 0 !important; }

.selectoption select {
  background: #fff;
  padding: 5px 15px 5px 10px !important;
  min-width: 35px;
  font-size: 14px !important;
  border: 1px solid rgba(204, 204, 204, 0.5) !important;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-box-sizing: 3px;
  border-radius: 3px; }
  .selectoption select:hover {
    background: #fff; }
  .selectoption select option {
    font-size: 14px; }

.floatRight p {
  font-size: 14px;
  color: #9b9b9b; }
  .floatRight p i {
    vertical-align: middle;
    margin-left: 10px; }
    .floatRight p i::before {
      font-size: 22px; }

.sideNavHidden {
  margin-left: 109px !important;
  width: calc(100% - 109px) !important;
  transition: .5s; }

.sideNavOpen {
  margin-left: 258px !important;
  width: calc(100% - 258px) !important;
  transition: .5s; }

@media only screen and (min-width: 1154px) and (max-width: 1680px) {
  .sideNavHidden {
    margin-left: 80px !important;
    width: calc(100% - 80px) !important; } }

@media only screen and (min-width: 300px) and (max-width: 1154px) {
  .sideNavHidden {
    margin-left: inherit !important;
    width: inherit !important; }
  .sideNavOpen {
    margin-left: inherit !important;
    width: inherit !important; } }

.icon-footer {
  height: 14px;
  width: 17px; }

.mr-2 {
  margin-right: 0.5rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.ml-3,
.mx-3 {
  margin-left: 21px !important; }

.text-body {
  color: #495057 !important; }

.text-secondary {
  color: #489be8 !important; }

.text-gray {
  color: #cccecf !important; }

.bg-dark {
  background-color: #343a40 !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mx-md-0 {
  margin-left: 0 !important; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
  padding-top: 1rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
  padding-top: 3rem !important; }

.text-md-left {
  text-align: left !important; }

.text-muted {
  color: #6c757d !important; }

.d-md-block {
  display: block !important; }

.col-12 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 100%;
  max-width: 100%; }

.col-md-6 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  flex: 0 0 50%;
  max-width: 50%; }

.align-items-center {
  align-items: center !important; }

.text-center {
  text-align: center !important; }

.mb-1,
.my-1 {
  margin-bottom: .25rem !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.justify-content-between {
  justify-content: space-between !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.icon-social {
  height: 27.2px;
  width: 27.2px; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mb16 {
  margin-bottom: 46px; }

.gridsBase, .col-fullwidth, .col-grid1 > .col, .col-grid2 > .col, .col-grid3 > .col, .col-grid4 > .col, .col-grid2-1 > .col {
  display: block;
  position: relative;
  min-height: 1px;
  float: left;
  width: 100%;
  box-sizing: border-box; }

.col-fullwidth {
  width: 100%; }

.col-grid1 {
  width: 100%;
  float: left;
  display: block;
  flex-direction: row;
  margin-bottom: 9px; }
  .col-grid1 > .col {
    margin-bottom: 15px;
    width: 100%; }

.col-grid2 {
  width: 100%;
  float: left;
  display: block;
  flex-direction: row;
  margin-bottom: 9px; }
  .col-grid2 > .col {
    width: 50%;
    margin-bottom: 15px; }
    .col-grid2 > .col:first-child {
      padding-right: 30px;
      padding-left: 0 !important; }
    .col-grid2 > .col:last-child {
      padding-right: 0px; }

.col-grid3 {
  width: 100%;
  float: left;
  display: block;
  flex-direction: row;
  margin-bottom: 9px; }
  .col-grid3 > .col {
    margin-bottom: 15px;
    width: 33.33%;
    padding-right: 28px; }
    .col-grid3 > .col:last-child {
      padding-right: 0px; }
    .col-grid3 > .col.last {
      padding-right: 28px; }

.col-grid4 {
  width: 100%;
  float: left;
  display: block;
  flex-direction: row;
  margin-bottom: 9px; }
  .col-grid4 > .col {
    margin-bottom: 15px;
    width: 25%;
    padding-right: 28px; }
    .col-grid4 > .col:last-child {
      padding-right: 0px; }
    .col-grid4 > .col.first {
      padding-right: 28px; }

.col100 {
  width: 100%;
  float: left; }

.col60 {
  width: 60%;
  float: left; }

.col40 {
  width: 40%;
  float: left; }

.col50 {
  width: 50%;
  float: left; }

.col70 {
  width: 70%;
  float: left; }

.col30 {
  width: 30%;
  float: left; }

.col-grid2-1 {
  width: 100%;
  float: left;
  display: block;
  flex-direction: row; }
  .col-grid2-1 > .col {
    margin-bottom: 15px;
    padding-right: 28px; }
    .col-grid2-1 > .col:first-child {
      width: 66.66% !important;
      padding-right: 0; }
    .col-grid2-1 > .col:last-child {
      padding-right: 0px;
      width: 33.33%; }
    .col-grid2-1 > .col .col-grid2 > .col:last-child {
      padding-right: 28px; }

.loginwrapper {
  width: 100%;
  float: left;
  background: url("../../assets/images/bg@3x.png") 0 0 no-repeat;
  background-size: 100% 100%;
  min-height: calc(100vh - 70px); }
  .loginwrapper .innWrapperShippingFrequency {
    width: 100%;
    float: none;
    max-width: 430px;
    margin: 0 auto; }
    .loginwrapper .innWrapperShippingFrequency .loginTitle {
      width: 100%;
      height: 140px;
      padding: 0 40px;
      float: left; }
      .loginwrapper .innWrapperShippingFrequency .loginTitle h2 {
        color: #fff;
        float: right;
        font-size: 64px;
        text-transform: uppercase;
        font-weight: 700;
        line-height: 50px;
        margin-top: 92px; }
    .loginwrapper .innWrapperShippingFrequency .loginOverlay {
      width: 100%;
      float: left;
      padding: 0 40px 40px; }
      .loginwrapper .innWrapperShippingFrequency .loginOverlay .loginOverlayinn {
        width: 100%;
        float: left;
        background: #f4f4f4;
        border-radius: 10px; }
        .loginwrapper .innWrapperShippingFrequency .loginOverlay .loginOverlayinn .rightColumn {
          float: none;
          overflow: hidden; }
  .loginwrapper .innWrapper {
    width: 100%;
    float: none;
    max-width: 1366px;
    margin: 0 auto; }
    .loginwrapper .innWrapper .loginTitle {
      width: 100%;
      height: 140px;
      padding: 0 40px;
      float: left; }
      .loginwrapper .innWrapper .loginTitle h2 {
        color: #fff;
        float: right;
        font-size: 64px;
        text-transform: uppercase;
        font-weight: 700;
        line-height: 50px;
        margin-top: 92px; }
    .loginwrapper .innWrapper .loginOverlay {
      width: 100%;
      float: left;
      padding: 0 40px 40px; }
      .loginwrapper .innWrapper .loginOverlay .loginOverlayinn {
        width: 100%;
        float: left;
        background: #f4f4f4; }
        .loginwrapper .innWrapper .loginOverlay .loginOverlayinn .leftColumn {
          width: 300px;
          height: 100%;
          float: left;
          min-height: auto;
          background: url("../../assets/images/base@2x.png") center center no-repeat;
          background-size: 300px 100%;
          position: absolute; }
          .loginwrapper .innWrapper .loginOverlay .loginOverlayinn .leftColumn .wraper {
            width: 100%;
            padding: 80px 16px 40px 40px;
            float: left; }
            .loginwrapper .innWrapper .loginOverlay .loginOverlayinn .leftColumn .wraper ul li {
              color: #fff;
              font-size: 24px;
              font-family: 'Lato', sans-serif;
              font-weight: 300;
              margin: 0 0 26px 0;
              line-height: 26px;
              position: relative;
              cursor: pointer; }
              .loginwrapper .innWrapper .loginOverlay .loginOverlayinn .leftColumn .wraper ul li.active {
                font-weight: 700;
                cursor: default; }
                .loginwrapper .innWrapper .loginOverlay .loginOverlayinn .leftColumn .wraper ul li.active i {
                  display: block; }
              .loginwrapper .innWrapper .loginOverlay .loginOverlayinn .leftColumn .wraper ul li:last-child {
                margin-bottom: 18px; }
              .loginwrapper .innWrapper .loginOverlay .loginOverlayinn .leftColumn .wraper ul li i {
                position: absolute;
                right: 0;
                top: 0;
                display: none; }
            .loginwrapper .innWrapper .loginOverlay .loginOverlayinn .leftColumn .wraper p {
              color: #fff;
              font-family: 'Lato', sans-serif;
              font-size: 14px;
              line-height: 26px;
              padding-right: 25px; }
        .loginwrapper .innWrapper .loginOverlay .loginOverlayinn .rightColumn {
          float: none;
          padding: 40px 60px 60px 360px;
          min-height: 400px;
          overflow: hidden; }

.loginOverlayinn input:-webkit-autofill {
  -webkit-box-shadow: 0px 0 0 30px #f4f4f4 inset; }

.shippingFrequencyFormCheckBoxTitle {
  text-align: center;
  margin-top: 30px; }

.shippingFrequencyFormCheckBoxTitle2 {
  text-align: center;
  margin-top: 30px;
  font-weight: bold; }

.shippingFrequencyFormCheckBoxGroup {
  align-items: center;
  margin-left: 75px;
  margin-bottom: 20px; }

.shippingFrequencyForm {
  float: left;
  width: 100%;
  padding-bottom: 0;
  border-radius: 25px; }
  .shippingFrequencyForm .h3 {
    text-align: center; }
  .shippingFrequencyForm .checkboxRow > div {
    padding-right: 20px; }
    .shippingFrequencyForm .checkboxRow > div:first-child {
      padding-left: 30px; }
  .shippingFrequencyForm .radioGroupWrap {
    margin-left: 0;
    margin-right: 50px;
    margin-top: 50px; }
    .shippingFrequencyForm .radioGroupWrap .h3 {
      text-align: center; }
    .shippingFrequencyForm .radioGroupWrap.inline .radioGroup {
      align-items: center !important;
      display: block; }
    .shippingFrequencyForm .radioGroupWrap.inline .radio-col {
      float: left; }
    .shippingFrequencyForm .radioGroupWrap.inline h3 {
      text-align: center;
      margin-left: 50px;
      margin-top: 0px;
      margin-bottom: 1px; }
  .shippingFrequencyForm .formAction button {
    float: left; }
  .shippingFrequencyForm .formAction p {
    float: left;
    margin-top: 10px;
    margin-left: 30px;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #4a4a4a; }
  .shippingFrequencyForm .holdarRadio {
    position: absolute;
    top: 30px; }
    .shippingFrequencyForm .holdarRadio .radioGroupWrap h5 {
      padding-bottom: 6px; }
    .shippingFrequencyForm .holdarRadio .radioGroup label + label {
      margin-top: -6px; }
      .shippingFrequencyForm .holdarRadio .radioGroup label + label + label {
        margin-top: -6px; }
  .shippingFrequencyForm .col60 {
    padding-right: 20px; }

.signupForm {
  float: left;
  width: 100%; }
  .signupForm .checkboxRow > div {
    padding-right: 20px; }
    .signupForm .checkboxRow > div:first-child {
      padding-left: 30px; }
  .signupForm .radioGroupWrap.inline .radioGroup {
    display: block; }
  .signupForm .radioGroupWrap.inline .radio-col {
    float: left; }
  .signupForm .radioGroupWrap.inline h5 {
    margin-top: 0px;
    margin-bottom: 1px; }
  .signupForm .formAction button {
    float: left; }
  .signupForm .formAction p {
    float: left;
    margin-top: 10px;
    margin-left: 0;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #4a4a4a; }
  .signupForm .holdarRadio {
    position: absolute;
    top: 30px; }
    .signupForm .holdarRadio .radioGroupWrap h5 {
      padding-bottom: 6px; }
    .signupForm .holdarRadio .radioGroup label + label {
      margin-top: -6px; }
      .signupForm .holdarRadio .radioGroup label + label + label {
        margin-top: -6px; }
  .signupForm .col {
    margin-bottom: 7px; }
  .signupForm .col60 {
    padding-right: 20px; }

.login-page {
  width: 100%;
  float: left; }
  .login-page .formSection {
    width: 50%;
    float: left;
    padding-right: 60px; }
  .login-page .linkForm {
    width: 76%; }
    .login-page .linkForm h2.title {
      font-size: 38px;
      font-family: 'Lato', sans-serif;
      font-weight: 300;
      color: #ff6c2f;
      margin: 0 0 15px 0; }
    .login-page .linkForm h3 {
      font-size: 18px;
      font-family: 'Lato', sans-serif;
      font-weight: 700;
      color: #000;
      margin: 0 0 5px 0; }
  .login-page .linkSection {
    float: left;
    padding-right: 60px; }
    .login-page .linkSection h2.title {
      font-size: 38px;
      font-family: 'Lato', sans-serif;
      font-weight: 300;
      color: #ff6c2f;
      margin: 0 0 15px 0; }
    .login-page .linkSection h3 {
      font-size: 18px;
      font-family: 'Lato', sans-serif;
      font-weight: 700;
      color: #000;
      margin: 0 0 5px 0; }
  .login-page .textSection {
    width: 50%;
    float: left;
    padding-left: 60px;
    padding-bottom: 15px;
    border-left: 1px solid #d8d8d8;
    min-height: 440px; }
    .login-page .textSection h2 {
      font-size: 36px;
      font-family: 'Lato', sans-serif;
      font-weight: 300;
      color: #ff6c2f;
      margin: 0 0 30px 0; }
    .login-page .textSection h3 {
      font-size: 18px;
      font-family: 'Lato', sans-serif;
      font-weight: 700;
      color: #000;
      margin: 0 0 20px 0; }
    .login-page .textSection ul li {
      font-size: 14px;
      font-family: 'Lato', sans-serif;
      font-weight: 400;
      color: #262626;
      line-height: 26px; }
      .login-page .textSection ul li i {
        font-size: 7px;
        vertical-align: middle;
        margin-right: 12px;
        margin-left: 10px;
        color: #262626; }
    .login-page .textSection .buttonSet {
      clear: both;
      padding: 30px 0 10px 0; }

.LoginForm {
  padding-top: 15px;
  width: 100%;
  float: left; }
  .LoginForm .formActionRight {
    float: right;
    padding-top: 11px; }
    .LoginForm .formActionRight p {
      float: left;
      font-size: 14px;
      font-family: 'Lato', sans-serif;
      font-weight: 400;
      color: #4a4a4a;
      padding-right: 4px; }
    .LoginForm .formActionRight a {
      float: left;
      font-size: 14px; }

.LinkSocialForm {
  padding-top: 15px;
  width: 100%;
  float: left; }
  .LinkSocialForm .formActionRight {
    float: right;
    padding-top: 11px; }
    .LinkSocialForm .formActionRight p {
      float: left;
      font-size: 14px;
      font-family: 'Lato', sans-serif;
      font-weight: 400;
      color: #4a4a4a;
      padding-right: 4px; }
    .LinkSocialForm .formActionRight a {
      float: left;
      font-size: 14px; }

.guestLogin {
  float: none !important;
  padding-top: 5px !important; }

.ForgotPasswordForm {
  padding-top: 0px;
  width: 100%;
  float: left;
  position: relative; }
  .ForgotPasswordForm .form-group {
    padding-top: 15px;
    position: absolute;
    left: -30px;
    right: -30px;
    float: left;
    background: #fff;
    padding: 30px 32px 10px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-box-sizing: 4px;
    border-radius: 4px;
    -webkit-box-shadow: -1px 0px 6px #e0dfdf;
    -moz-box-shadow: -1px 0px 6px #e0dfdf;
    -ms-box-shadow: -1px 0px 6px #e0dfdf;
    -o-box-shadow: -1px 0px 6px #e0dfdf;
    box-shadow: -1px 0px 6px #e0dfdf; }
    .ForgotPasswordForm .form-group:before {
      content: '';
      border: 20px solid #fff;
      border-color: transparent transparent #fff transparent;
      position: absolute;
      top: -30px;
      right: 40px; }
  .ForgotPasswordForm .buttonSet {
    padding: 5px 0 0 0; }
    .ForgotPasswordForm .buttonSet .btnInactive {
      margin-left: 5px; }

.gestUserLogin {
  width: 100%;
  float: left;
  position: relative; }
  .gestUserLogin .loginOverlay .leftColumn {
    width: 300px;
    float: left;
    background-image: linear-gradient(152deg, #e86d3b 24%, #e83b78);
    min-height: 500px;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%; }
    .gestUserLogin .loginOverlay .leftColumn .wraper {
      width: 100%;
      padding: 40px 16px 40px 40px;
      float: left; }
      .gestUserLogin .loginOverlay .leftColumn .wraper ul li {
        color: #fff;
        font-size: 24px;
        font-family: 'Lato', sans-serif;
        font-weight: 300;
        margin: 0 0 26px 0;
        line-height: 26px;
        position: relative;
        cursor: pointer; }
        .gestUserLogin .loginOverlay .leftColumn .wraper ul li.active {
          font-weight: 700; }
          .gestUserLogin .loginOverlay .leftColumn .wraper ul li.active i {
            display: block; }
        .gestUserLogin .loginOverlay .leftColumn .wraper ul li:last-child {
          margin-bottom: 18px; }
        .gestUserLogin .loginOverlay .leftColumn .wraper ul li i {
          position: absolute;
          right: 0;
          top: 0;
          display: none; }
      .gestUserLogin .loginOverlay .leftColumn .wraper p {
        color: #fff;
        font-family: 'Lato', sans-serif;
        font-size: 14px;
        line-height: 26px;
        padding-right: 25px; }
      .gestUserLogin .loginOverlay .leftColumn .wraper .list {
        margin: 0;
        padding: 15px 45px 0 0; }
        .gestUserLogin .loginOverlay .leftColumn .wraper .list li {
          color: #fff;
          font-size: 14px;
          font-family: 'Lato', sans-serif;
          font-weight: 300;
          margin: 0 0 26px 20px;
          line-height: 26px;
          position: relative;
          cursor: pointer; }
          .gestUserLogin .loginOverlay .leftColumn .wraper .list li i {
            position: absolute;
            left: -20px;
            top: 10px;
            font-size: 7px;
            vertical-align: middle;
            margin-right: 8px;
            margin-left: 2px;
            color: #fff;
            display: inline-block; }
  .gestUserLogin .loginOverlay .linkSection {
    float: left; }
    .gestUserLogin .loginOverlay .linkSection h2.title {
      font-size: 36px;
      font-family: 'Lato', sans-serif;
      font-weight: 300;
      color: #ff6c2f;
      margin: 0 0 15px 0; }
    .gestUserLogin .loginOverlay .linkSection h3 {
      font-size: 18px;
      font-family: 'Lato', sans-serif;
      font-weight: 700;
      color: #000;
      margin: 0 0 5px 0; }
  .gestUserLogin .loginOverlay .rightColumn {
    float: none;
    margin-left: 300px;
    padding: 40px 60px 40px 60px;
    overflow: hidden;
    min-height: 520px; }
    .gestUserLogin .loginOverlay .rightColumn .wraper {
      width: 100%;
      float: left; }
      .gestUserLogin .loginOverlay .rightColumn .wraper h1 {
        font-size: 44px;
        font-family: 'Lato', sans-serif;
        font-weight: 300;
        color: #7e7e7e;
        margin: 0 0 15px 0;
        line-height: 40px; }
        .gestUserLogin .loginOverlay .rightColumn .wraper h1 span {
          font-size: 14px;
          font-weight: 400; }
      .gestUserLogin .loginOverlay .rightColumn .wraper h2 {
        font-size: 20px;
        font-family: 'Lato', sans-serif;
        font-weight: 700;
        color: #7e7e7e;
        margin: 0 0 26px 0; }

.GuestLoginForm h2 {
  font-size: 44px;
  font-family: Lato, sans-serif;
  font-weight: 300;
  color: #ff6c2f;
  margin: 0 0 15px;
  line-height: 62px; }

.GuestLoginForm .not-register {
  padding-top: 10px;
  border-top: 1px solid #ddd;
  margin-top: 20px; }

.NotRegisteredSection h2 {
  font-size: 44px;
  font-family: Lato, sans-serif;
  font-weight: 300;
  color: #ff6c2f;
  margin: 0 0 15px;
  line-height: 62px; }

.NotRegisteredSection .not-register {
  padding-top: 10px;
  border-top: 1px solid #ddd;
  margin-top: 20px; }

.formTitle .col {
  width: auto;
  margin: 0 !important;
  display: table;
  min-height: 20px; }
  .formTitle .col h2 {
    margin: 0 !important;
    display: table-cell;
    vertical-align: bottom; }
  .formTitle .col div {
    display: table-cell;
    vertical-align: bottom; }

.terms {
  font-size: 10px;
  line-height: 1.3; }

.headerLink {
  font-size: 14px;
  padding-top: 4px; }

.headerText {
  font-size: 20px; }

.notificationTriangle {
  width: 170px;
  height: 170px;
  display: block;
  margin: 0 auto; }
  @media (min-width: 1155px) {
    .notificationTriangle {
      margin: 50px auto; } }

.manyLoginAttemptErrorSection p {
  margin-top: 1rem;
  line-height: 20px;
  font-size: 14px;
  margin-bottom: 10px; }
  @media (min-width: 1154px) {
    .manyLoginAttemptErrorSection p {
      margin-left: 1.8rem; } }

.manyLoginAttemptErrorSection h2 {
  margin-top: 1.3rem; }

.manyLoginAttemptErrorSection h1 {
  color: #ff6c2f;
  margin-top: 2rem;
  font-size: 27px;
  font-weight: 500; }
  @media (min-width: 1154px) {
    .manyLoginAttemptErrorSection h1 {
      font-size: 34px; } }

.manyLoginAttemptLogin {
  padding-right: 20px; }
  @media (min-width: 1154px) {
    .manyLoginAttemptLogin {
      padding-right: 25px !important; } }

.requiredClass label + div {
  border-left: 2px solid #ff6868 !important;
  padding-left: 10px !important;
  border-bottom: 1px solid #9b9b9b; }
  .requiredClass label + div:hover {
    border-bottom: 1px solid #0093d0; }

.main-panel {
  width: 100%;
  float: left;
  margin-top: 66px;
  min-height: calc(100vh - 301px) !important; }

.layout-onecolLeft {
  width: 100%;
  float: left; }
  .layout-onecolLeft .innWrapper {
    width: 100%;
    float: none;
    margin: 0 auto;
    max-width: 1366px;
    padding: 0 40px; }
    .layout-onecolLeft .innWrapper .sidebaar-left {
      width: 304px;
      padding: 40px 40px;
      float: left;
      background: #fff;
      min-height: calc(100vh - 301px); }
      .layout-onecolLeft .innWrapper .sidebaar-left h1 {
        color: #000;
        margin: 0px 0px 20px 0px; }
      .layout-onecolLeft .innWrapper .sidebaar-left .accountMenu {
        margin: 0;
        padding: 0; }
        .layout-onecolLeft .innWrapper .sidebaar-left .accountMenu li {
          margin: 0px;
          padding: 15px 0px;
          font-family: 'Lato', sans-serif;
          font-size: 18px;
          color: #000;
          font-weight: 300;
          cursor: pointer; }
          .layout-onecolLeft .innWrapper .sidebaar-left .accountMenu li:hover {
            color: #ff6c2f; }
          .layout-onecolLeft .innWrapper .sidebaar-left .accountMenu li.active {
            color: #ff6c2f;
            font-weight: 400; }
    .layout-onecolLeft .innWrapper .mainContent {
      float: none;
      padding-left: 304px; }
      .layout-onecolLeft .innWrapper .mainContent .contenWrapper {
        width: 100%;
        float: left;
        padding: 40px 0px 40px 60px; }
        .layout-onecolLeft .innWrapper .mainContent .contenWrapper fieldset .legend {
          color: #9e9e9e;
          font-size: 18px;
          font-weight: 400;
          font-family: 'Lato', sans-serif;
          margin: 20px 0 20px 0; }

.accounDetail .fullName {
  font-size: 54px;
  font-weight: 700;
  color: #ff6c2f; }

.accounDetail .h5 {
  font-size: 20px;
  color: #9b9b9b;
  font-weight: 400; }
  .accounDetail .h5 i {
    font-size: 9px;
    vertical-align: middle;
    margin: 0 8px; }

.accounDetail .inputField .labelData {
  font-size: 14px;
  font-weight: 700; }

.accounDetail .boldlabel .radioGroup {
  display: block; }

.accounDetail .holdarRadio {
  margin-top: 6px; }

.accounDetail .rowBdr {
  border-top: 1px solid #ddd;
  margin-top: 15px;
  padding-top: 12px; }

.accounDetail .rowBdrbtm {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px; }

.accounDetail .errorShow .inputField {
  display: none; }
  .accounDetail .errorShow .inputField .label {
    display: none; }

.accounDetail .checkboxRow {
  padding-left: 30px; }

.accounDetail .viwModeCheckbox {
  border: 0px !important;
  margin: 0;
  padding: 0; }
  .accounDetail .viwModeCheckbox .checkBoxWrap {
    margin: 0; }
    .accounDetail .viwModeCheckbox .checkBoxWrap span:first-child {
      display: none; }
    .accounDetail .viwModeCheckbox .checkBoxWrap span:last-child {
      font-size: 18px;
      color: #9b9b9b;
      font-weight: 400; }

.whiteSection.std {
  padding: 0; }
  .whiteSection.std .head-section {
    border-bottom: 1px solid #ddd;
    padding: 14px 30px;
    margin: 0; }
    .whiteSection.std .head-section .date {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      font-style: italic;
      color: #262626; }
  .whiteSection.std .content {
    width: 100%;
    float: left;
    padding: 20px 30px;
    min-height: 300px; }
    .whiteSection.std .content .checkBoxWrap {
      margin-left: 0; }

.wrapperClassName {
  border: 1px solid #ddd;
  padding: 10px; }

.editorClassName.rdw-editor-main {
  min-height: 150px; }

.layout-onecol {
  width: 100%;
  float: left;
  min-height: 580px; }
  .layout-onecol .innWrapper {
    width: 100%;
    float: none;
    margin: 0 auto;
    max-width: 1366px; }
    .layout-onecol .innWrapper .mainContent {
      float: left;
      width: 100%; }
      .layout-onecol .innWrapper .mainContent .contenWrapper {
        width: 100%;
        float: left;
        padding: 40px; }
        .layout-onecol .innWrapper .mainContent .contenWrapper h1 {
          line-height: 36px; }
          .layout-onecol .innWrapper .mainContent .contenWrapper h1 i {
            font-size: 22px;
            color: #9b9b9b;
            cursor: pointer; }
            .layout-onecol .innWrapper .mainContent .contenWrapper h1 i:hover {
              color: #ff6c2f; }

.status-section {
  width: 100%;
  float: left;
  margin: 40px 0 0 0; }
  .status-section .block {
    width: 33.3%;
    float: left;
    padding-right: 28px; }
    .status-section .block .wraper {
      width: 100%;
      padding: 30px 22px 10px 30px;
      float: left;
      background-image: linear-gradient(294deg, #00d0a9, #0093d0);
      min-height: 180px;
      border-radius: 6px;
      color: #fff; }
      .status-section .block .wraper h2 {
        color: #fff;
        margin: 0 0 15px 0; }
      .status-section .block .wraper p {
        font-size: 14px;
        font-weight: 300;
        line-height: 16px;
        margin: 4px 0 13px 0; }
        .status-section .block .wraper p i {
          margin-right: 10px; }
        .status-section .block .wraper p a {
          color: #fff; }
      .status-section .block .wraper .buttonSet {
        clear: both;
        margin: 32px 0 0 0; }
        .status-section .block .wraper .buttonSet button {
          background: #fff !important;
          padding: 8px 16px !important;
          line-height: 20px; }
          .status-section .block .wraper .buttonSet button span {
            color: #ff6c2f;
            font-size: 15px; }
          .status-section .block .wraper .buttonSet button i {
            margin-left: 15px;
            font-size: 22px; }
      .status-section .block .wraper.orange {
        background-image: linear-gradient(294deg, #e86d3b, #e83b78); }
      .status-section .block .wraper.purpal {
        position: relative;
        background-image: linear-gradient(294deg, #3023ae, #c86dd7); }
        .status-section .block .wraper.purpal .buttonSet {
          position: absolute;
          right: 0px;
          margin-top: 9px; }
          .status-section .block .wraper.purpal .buttonSet span {
            color: #0093d0; }
        .status-section .block .wraper.purpal .col-grid1 {
          margin: 0; }
          .status-section .block .wraper.purpal .col-grid1 > .col {
            padding-right: 140px; }
            .status-section .block .wraper.purpal .col-grid1 > .col label {
              color: #fff !important;
              opacity: 0.5; }

.layout-onecol .wideWrapper {
  width: 100%;
  float: left; }
  .layout-onecol .wideWrapper .fullWrapper {
    width: 100%;
    float: left; }
  .layout-onecol .wideWrapper .mainWrapper {
    clear: both;
    margin: 0 auto;
    max-width: 1366px; }
  .layout-onecol .wideWrapper .homeMainbanner {
    background-image: linear-gradient(to bottom right, rgba(49, 0, 80, 0.98), rgba(49, 0, 80, 0.98) 35%, rgba(17, 72, 170, 0.98) 70%, rgba(0, 217, 216, 0.9) 100%);
    background-color: #310050;
    background-position: center;
    color: #fff;
    width: 100%;
    overflow: hidden;
    float: left; }
    .layout-onecol .wideWrapper .homeMainbanner .banner1 {
      width: 100%;
      float: left;
      background: url("./../../assets/images/freight-shipping-logistics-technology.png") right 30px no-repeat; }
    .layout-onecol .wideWrapper .homeMainbanner .banner2 {
      width: 100%;
      float: left;
      background: url("./../../assets/images/less-than-truckload-freight-shipping.png") right 30px no-repeat; }
    .layout-onecol .wideWrapper .homeMainbanner h1 {
      font-size: 42px;
      line-height: 54px;
      font-weight: 900;
      text-transform: uppercase;
      margin-bottom: 30px; }
    .layout-onecol .wideWrapper .homeMainbanner p {
      line-height: 30px;
      font-size: 18px;
      margin-bottom: 20px; }
    .layout-onecol .wideWrapper .homeMainbanner .left {
      width: 60%;
      float: left;
      padding: 70px 40px 80px; }
      .layout-onecol .wideWrapper .homeMainbanner .left button {
        border: 2px solid #fff;
        margin-right: 8px;
        padding: 16px 15px !important; }
        .layout-onecol .wideWrapper .homeMainbanner .left button span {
          font-weight: 900;
          font-size: 18px; }
  .layout-onecol .wideWrapper .whiteBg {
    background: #fff; }
  .layout-onecol .wideWrapper .fade-bg {
    background: -webkit-linear-gradient(top, #f0f4f7, #fff) no-repeat;
    background: linear-gradient(to bottom, #f0f4f7, #fff) no-repeat; }
  .layout-onecol .wideWrapper .featured {
    color: #fff;
    background: -webkit-linear-gradient(left top, #360033, #300e41, #223166, #105e94);
    background: linear-gradient(to right bottom, #360033, #300e41, #223166, #105e94);
    background-color: #360033; }
    .layout-onecol .wideWrapper .featured .mainWrapper {
      padding: 40px; }
      .layout-onecol .wideWrapper .featured .mainWrapper .left {
        width: 60%;
        float: left;
        padding-bottom: 40px; }
      .layout-onecol .wideWrapper .featured .mainWrapper h2 {
        font-size: 30px;
        font-weight: 400;
        margin-bottom: 20px; }
      .layout-onecol .wideWrapper .featured .mainWrapper p {
        line-height: 24px;
        font-size: 16px;
        margin-bottom: 20px; }
  .layout-onecol .wideWrapper .fServiceList {
    text-align: center; }
    .layout-onecol .wideWrapper .fServiceList ul {
      margin: 0;
      padding: 0; }
      .layout-onecol .wideWrapper .fServiceList ul li {
        display: inline-block;
        padding: 20px;
        background-color: #f0f4f7;
        margin: 10px;
        min-width: 14.6%; }
        .layout-onecol .wideWrapper .fServiceList ul li h4 {
          margin-top: 20px;
          margin-bottom: 20px;
          color: #63738c;
          font-weight: 900; }
  .layout-onecol .wideWrapper .shippingType {
    text-align: center;
    padding: 30px 0; }
    .layout-onecol .wideWrapper .shippingType ul {
      margin: 0;
      padding: 0; }
      .layout-onecol .wideWrapper .shippingType ul li {
        vertical-align: top;
        display: inline-block;
        padding: 30px;
        background-color: #fff;
        margin: 10px;
        min-width: 160px;
        width: 22%;
        height: 260px;
        margin: 0 15px;
        border: 1px solid #ddd;
        border-radius: 4px;
        cursor: pointer; }
        .layout-onecol .wideWrapper .shippingType ul li h4 {
          font-size: 12px;
          font-weight: 900;
          color: #bf3500;
          margin-top: 15px; }
  .layout-onecol .wideWrapper .shippingAnywhere {
    width: 100%;
    float: left; }
    .layout-onecol .wideWrapper .shippingAnywhere > img {
      float: left;
      padding-left: 100px;
      padding-top: 30px; }
    .layout-onecol .wideWrapper .shippingAnywhere .right {
      width: 50%;
      float: right;
      padding: 40px 0 60px; }
    .layout-onecol .wideWrapper .shippingAnywhere h1 {
      font-size: 30px;
      font-weight: 400;
      color: #2886bc; }
    .layout-onecol .wideWrapper .shippingAnywhere h2 {
      line-height: 1.8em;
      margin: 20px 0;
      font-size: 18px;
      color: #63738c;
      font-weight: 400; }
    .layout-onecol .wideWrapper .shippingAnywhere ul li {
      color: #bf3500;
      font-size: 18px;
      margin-top: .75rem;
      padding-top: .75rem; }
      .layout-onecol .wideWrapper .shippingAnywhere ul li i {
        color: #63738c;
        font-size: 25px;
        vertical-align: middle;
        margin-right: 12px; }
    .layout-onecol .wideWrapper .shippingAnywhere p {
      color: #63738c;
      padding-top: 30px;
      font-size: 18px; }

.corporateList {
  float: left;
  width: 100%;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 20px; }
  .corporateList .leftImg {
    width: 33%;
    float: left; }
  .corporateList .rtcontent {
    width: 67%;
    float: left; }
  .corporateList h3 {
    color: #2886bc;
    font-weight: 400;
    font-size: 24px; }
  .corporateList span,
  .corporateList p {
    line-height: 1.8em;
    margin: 20px 0;
    font-size: 18px;
    color: #63738c; }
  .corporateList a {
    color: #bf3500; }
  .corporateList ul li {
    display: inline-block;
    padding-right: 30px;
    line-height: 1.4em;
    font-size: 18px;
    margin-bottom: .5em; }
  .corporateList .btnPrimary.home {
    border: 0 !important;
    padding: 10px !important; }
  .corporateList .btnPrimary.home span {
    color: #fff;
    border: 0;
    padding: 0 !important;
    margin: 0 !important; }

.full-section {
  width: 100%;
  float: left; }
  .full-section .wraper {
    width: 100%;
    float: left; }

.white-section {
  background: #fff;
  width: 100%;
  float: left;
  padding: 30px 40px;
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 10px; }

.white-section.quote {
  width: 100%;
  margin-bottom: 10px;
  padding: 20px 25px; }
  .white-section.quote .fSize30 {
    font-size: 30px;
    line-height: 32px;
    font-family: 'latoregular'; }
  .white-section.quote .fSize15 {
    font-size: 15px;
    color: #4a4a4a;
    font-style: normal; }
  .white-section.quote .grayIcon {
    vertical-align: middle; }
    .white-section.quote .grayIcon:before {
      font-size: 15px;
      color: #c9c9c9;
      margin-left: 4px; }
  .white-section.quote .head-section {
    width: 100%;
    float: left; }
    .white-section.quote .head-section .col-grid2 .col {
      margin-bottom: 0; }
    .white-section.quote .head-section h2 {
      margin-bottom: 8px; }
    .white-section.quote .head-section .iconlink {
      font-size: 14px;
      vertical-align: top;
      line-height: 18px;
      display: inline-block;
      font-weight: 700; }
      .white-section.quote .head-section .iconlink i {
        vertical-align: top;
        display: inline-block; }
        .white-section.quote .head-section .iconlink i:before {
          font-size: 18px;
          margin-right: 6px;
          vertical-align: top; }
  .white-section.quote .content-section {
    width: 100%;
    float: left; }
    .white-section.quote .content-section .item-detail {
      width: 100%;
      float: left; }
      .white-section.quote .content-section .item-detail .col-left {
        width: 80%;
        float: left;
        height: auto;
        padding: 0;
        min-height: 212px;
        border-right: 1px solid #d8d8d8; }
        .white-section.quote .content-section .item-detail .col-left .row {
          width: 100%;
          float: left;
          margin-bottom: 20px; }
          .white-section.quote .content-section .item-detail .col-left .row .column1 {
            width: 25%;
            float: left; }
            .white-section.quote .content-section .item-detail .col-left .row .column1 .col20 {
              width: 40px;
              float: right; }
              .white-section.quote .content-section .item-detail .col-left .row .column1 .col20 .formSelectBoxImp label + div > div > div {
                padding-left: 0px !important;
                padding-right: 15px !important;
                padding-top: 0px; }
                .white-section.quote .content-section .item-detail .col-left .row .column1 .col20 .formSelectBoxImp label + div > div > div > div div[role="button"] {
                  background: transparent !important; }
                  .white-section.quote .content-section .item-detail .col-left .row .column1 .col20 .formSelectBoxImp label + div > div > div > div div[role="button"] span {
                    padding-left: 0;
                    background: transparent; }
          .white-section.quote .content-section .item-detail .col-left .row .column3 {
            width: 75%;
            float: left;
            padding-left: 25px;
            padding-right: 25px; }
            .white-section.quote .content-section .item-detail .col-left .row .column3 .dimension {
              width: 80%;
              float: left; }
              .white-section.quote .content-section .item-detail .col-left .row .column3 .dimension .col {
                width: 25%;
                float: left;
                padding-right: 20px; }
              .white-section.quote .content-section .item-detail .col-left .row .column3 .dimension .custom-select .formControl {
                margin-top: 0;
                margin-bottom: 0; }
              .white-section.quote .content-section .item-detail .col-left .row .column3 .dimension a {
                font-size: 12px; }
            .white-section.quote .content-section .item-detail .col-left .row .column3 .dimension-check {
              width: 20%;
              float: left;
              padding-top: 8px; }
              .white-section.quote .content-section .item-detail .col-left .row .column3 .dimension-check label {
                margin: 0; }
              .white-section.quote .content-section .item-detail .col-left .row .column3 .dimension-check .checkbox-lbl {
                margin-top: 0px; }
          .white-section.quote .content-section .item-detail .col-left .row .noteTxt {
            color: #9b9b9b;
            font-size: 11px;
            clear: both;
            max-width: 210px;
            line-height: 14px;
            padding-top: 6px; }
          .white-section.quote .content-section .item-detail .col-left .row .or {
            color: #9b9b9b;
            font-size: 11px;
            line-height: 14px;
            font-style: normal; }
      .white-section.quote .content-section .item-detail .col-right {
        width: 20%;
        float: left;
        padding: 0 0 0 25px;
        overflow: inherit; }
        .white-section.quote .content-section .item-detail .col-right .row {
          padding-top: 20px;
          width: 100%;
          float: left;
          padding-right: 20px;
          position: relative; }
          .white-section.quote .content-section .item-detail .col-right .row .label-highlight {
            background: #c9c9c9;
            border-bottom: 1px solid #9b9b9b;
            color: #4a4a4a;
            padding: 8px 12px;
            float: left;
            width: 100%;
            font-size: 14px; }
          .white-section.quote .content-section .item-detail .col-right .row .grayIcon {
            position: absolute;
            right: 0;
            top: 31px; }

.white-section.Extrashipmentservices label {
  margin-bottom: 2px; }

.icon-field {
  border: 0px solid #cccfd4;
  border-right: 0px;
  width: 25%;
  float: left;
  min-height: 35px;
  margin-top: 13px;
  border-right: 0px; }
  .icon-field:last-child {
    border: 1px solid #cccfd4;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px; }
  .icon-field:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px; }
  .icon-field.icon-field75 {
    width: 75%; }
    .icon-field.icon-field75 .radioGroupWrap {
      height: 33px;
      width: 67%;
      float: left; }
      .icon-field.icon-field75 .radioGroupWrap .radioGroup {
        display: block;
        width: 100%; }
        .icon-field.icon-field75 .radioGroupWrap .radioGroup .radio-col {
          margin: 0;
          width: 50%;
          float: left;
          height: 33px;
          border-right: 1px solid #cccfd4; }
          .icon-field.icon-field75 .radioGroupWrap .radioGroup .radio-col span:first-child {
            width: 30px;
            height: 30px;
            opacity: 0; }
          .icon-field.icon-field75 .radioGroupWrap .radioGroup .radio-col span:last-child {
            font-size: 12px !important;
            width: 80%;
            text-align: center;
            padding-right: 24px;
            font-weight: 700;
            font-family: 'Lato', sans-serif; }
    .icon-field.icon-field75 .checkboxGroupSelect {
      width: 33%;
      float: left;
      background: url("./../../assets/images/icon-pallet.png") 10px 6px no-repeat; }
      .icon-field.icon-field75 .checkboxGroupSelect > .div {
        width: 100%;
        float: left; }
        .icon-field.icon-field75 .checkboxGroupSelect > .div.custom-select {
          border: 1px solid #cccfd4;
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
          height: 35px; }
          .icon-field.icon-field75 .checkboxGroupSelect > .div.custom-select span {
            padding-left: 40px !important;
            font-weight: 700;
            line-height: 24px; }
      .icon-field.icon-field75 .checkboxGroupSelect label.checkBoxWrap {
        margin: 0; }
        .icon-field.icon-field75 .checkboxGroupSelect label.checkBoxWrap span:first-child {
          width: 33px;
          height: 33px; }
  .icon-field .formControl {
    margin-top: 0;
    margin-bottom: 0;
    padding-right: 10px;
    padding-left: 0px; }
    .icon-field .formControl label + div {
      margin-top: 0px; }
      .icon-field .formControl label + div:before {
        border: 0px !important; }
      .icon-field .formControl label + div:after {
        border: 0px !important; }
      .icon-field .formControl label + div div[role="button"] span {
        color: #9b9b9b !important;
        font-size: 12px !important; }
      .icon-field .formControl label + div div[aria-pressed] {
        padding-right: 20px !important; }
  .icon-field.pallet .radioGroupWrap .radioGroup label {
    border: 1px solid #cccfd4 !important;
    height: 35px !important;
    background: url("./../../assets/images/icon-create.png") 10px 6px no-repeat; }
    .icon-field.pallet .radioGroupWrap .radioGroup label.checked {
      background: #e5f4fa url("./../../assets/images/icon-create-act.png") 10px 6px no-repeat;
      border: 1px solid #2da6d8 !important; }
      .icon-field.pallet .radioGroupWrap .radioGroup label.checked span + span {
        color: #1a9ed4 !important; }
    .icon-field.pallet .radioGroupWrap .radioGroup label + label {
      background: url("./../../assets/images/icon-box.png") 10px 6px no-repeat; }
      .icon-field.pallet .radioGroupWrap .radioGroup label + label.checked {
        background: #e5f4fa url("./../../assets/images/icon-box-act.png") 10px 6px no-repeat; }
  .icon-field.other-unit-type .formControl label + div div[role="button"] span {
    padding-left: 20px !important; }

.checkboxGroupSelect .formControl label {
  transition: inherit !important;
  transform: inherit !important;
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 12px !important;
  color: #9b9b9b !important;
  margin-top: 8px;
  padding-left: 36px !important; }
  .checkboxGroupSelect .formControl label + div div[role="button"] span {
    padding-left: 20px !important; }

.pickup-delivery-info h3 i {
  vertical-align: middle;
  line-height: 18px;
  cursor: pointer; }
  .pickup-delivery-info h3 i:before {
    color: #0093d0; }

.pickup-delivery-info .datePicker .calendar-icon {
  right: 4px; }

.pickup-delivery-info .pickupInfo:first-child {
  border-right: 1px solid #ddd; }

.pickup-delivery-info .col-grid2 .col:first-child {
  padding-right: 20px; }

.pickup-delivery-info .col-grid2 .col:last-child {
  padding-left: 20px; }

.pickup-delivery-info .fType {
  width: 100%;
  float: left;
  margin-top: 15px;
  margin-bottom: 15px; }
  .pickup-delivery-info .fType h4 {
    color: #000;
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 12px; }
  .pickup-delivery-info .fType .gCol1 .col {
    padding-right: 0;
    width: 100%; }
    .pickup-delivery-info .fType .gCol1 .col:last-child {
      padding-left: 0; }
    .pickup-delivery-info .fType .gCol1 .col .radioGroup {
      display: block;
      float: left;
      width: 100%; }
      .pickup-delivery-info .fType .gCol1 .col .radioGroup .radio-col {
        margin-bottom: 12px;
        margin-right: 0;
        width: 50%;
        float: left;
        align-items: flex-start; }
        .pickup-delivery-info .fType .gCol1 .col .radioGroup .radio-col:nth-child(2n) {
          padding-left: 40px; }
        .pickup-delivery-info .fType .gCol1 .col .radioGroup .radio-col:nth-child(2n+1) {
          clear: both; }
        .pickup-delivery-info .fType .gCol1 .col .radioGroup .radio-col > span:first-child {
          width: 40px;
          height: 40px;
          margin-top: -12px; }
        .pickup-delivery-info .fType .gCol1 .col .radioGroup .radio-col > span + span {
          font-size: 14px;
          color: #000; }
    .pickup-delivery-info .fType .gCol1 .col .checkBoxWrap {
      margin-bottom: 0; }
      .pickup-delivery-info .fType .gCol1 .col .checkBoxWrap > span + span {
        font-size: 14px;
        font-family: "latoregular", sans-serif !important;
        letter-spacing: 0.3px !important; }
    .pickup-delivery-info .fType .gCol1 .col .noteTxt {
      font-size: 13px;
      color: #4a4a4a;
      line-height: 20px; }
  .pickup-delivery-info .fType .gCol2 {
    width: 100%;
    float: left; }
    .pickup-delivery-info .fType .gCol2 .col {
      width: 50%;
      float: left;
      padding-right: 20px;
      margin-bottom: 0; }
      .pickup-delivery-info .fType .gCol2 .col:last-child {
        padding-left: 0px; }
      .pickup-delivery-info .fType .gCol2 .col:nth-child(2n) {
        padding-left: 20px; }
      .pickup-delivery-info .fType .gCol2 .col .radioGroup .radio-col {
        margin-bottom: 0;
        margin-right: 0; }
        .pickup-delivery-info .fType .gCol2 .col .radioGroup .radio-col > span:first-child {
          width: 40px;
          height: 40px; }
        .pickup-delivery-info .fType .gCol2 .col .radioGroup .radio-col > span + span {
          font-size: 14px;
          color: #000; }
  .pickup-delivery-info .fType.bdr-bottom {
    border-bottom: 1px solid #ddd; }

.a-link12 {
  cursor: pointer;
  font-size: 12px; }
  .a-link12:hover {
    color: #0093d0; }

.formControl.autosuggest {
  margin-top: 16px; }
  .formControl.autosuggest > label {
    transform: translate(-10px, -3px) scale(0.75); }
    .formControl.autosuggest > label + div {
      border: 0 !important;
      border-bottom: 1px solid #9b9b9b !important; }
      .formControl.autosuggest > label + div:before {
        border: 0 !important; }
      .formControl.autosuggest > label + div > div {
        border: 0 !important;
        border-bottom: 0px solid #9b9b9b !important;
        border-radius: 0;
        box-shadow: 0 0 0 #fff;
        background: none;
        min-height: inherit; }
        .formControl.autosuggest > label + div > div:hover {
          border-bottom: 0px solid #9b9b9b !important; }
        .formControl.autosuggest > label + div > div + div {
          border: 0 !important; }
        .formControl.autosuggest > label + div > div div[aria-hidden="true"] {
          padding: 4px;
          background: transparent;
          margin-left: -2px; }
          .formControl.autosuggest > label + div > div div[aria-hidden="true"]:before {
            content: '\F2F2';
            font-family: "Material-Design-Iconic-Font" !important;
            position: absolute;
            right: 4px;
            font-size: 25px;
            color: #4a4a4a;
            height: 22px; }
          .formControl.autosuggest > label + div > div div[aria-hidden="true"] svg {
            opacity: 0; }
        .formControl.autosuggest > label + div > div input {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
          padding-left: 0 !important; }
        .formControl.autosuggest > label + div > div > div {
          padding-top: 0 !important;
          padding-bottom: 0 !important;
          border: 0;
          min-height: 30px;
          background: transparent; }
          .formControl.autosuggest > label + div > div > div > div {
            color: #4a4a4a;
            font-family: 'Lato', sans-serif;
            padding-bottom: 0; }
            .formControl.autosuggest > label + div > div > div > div span {
              background: transparent; }
            .formControl.autosuggest > label + div > div > div > div > div:hover:before {
              border-bottom: 0 !important; }

.autosuggest > label + div > div > span[aria-live="assertive"] + div {
  box-shadow: 0 0 0 #fff !important;
  border: 0; }

.autosuggest > label + div > div > span[aria-live="assertive"] + div + div > div > div {
  color: #4a4a4a;
  padding: 4px 10px;
  font-size: 14px !important;
  line-height: 24px !important;
  font-weight: normal !important; }

.formControl.autosuggest > label + div > div > span + div + div {
  z-index: 2;
  background: #fff; }

.react-select__option {
  background: #000 !important; }

.requiredClass .formControl.autosuggest label + div {
  border-left: 2px solid #ff6868 !important; }

.formAction.quoteForm {
  padding: 15px;
  clear: both; }
  .formAction.quoteForm .btnInactive.tooltipWrap {
    pointer-events: auto; }
    .formAction.quoteForm .btnInactive.tooltipWrap .tooltiptext {
      bottom: 40px;
      top: inherit;
      width: 106%;
      left: -3%;
      margin: 0;
      text-transform: none;
      text-align: left;
      padding: 8px 10px;
      color: #fff;
      background: #000;
      height: 60px;
      line-height: 15px;
      font-weight: 400; }
      .formAction.quoteForm .btnInactive.tooltipWrap .tooltiptext:after {
        bottom: -10px;
        border-color: #000 transparent transparent transparent;
        display: none; }
  .formAction.quoteForm button {
    margin: 0 18px;
    min-width: 160px;
    max-width: 209px;
    padding-left: 0 !important;
    padding-right: 0 !important; }

.tooltipWrap {
  cursor: pointer; }
  .tooltipWrap .tooltiptext.stackable {
    bottom: 43px;
    top: inherit;
    width: 220px;
    line-height: 15px;
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 0;
    margin-right: 0;
    left: -86px;
    background: #000;
    color: #fff; }
    .tooltipWrap .tooltiptext.stackable:after {
      bottom: -10px;
      border-color: #000 transparent transparent transparent; }
  .tooltipWrap .tooltiptext.hUnit {
    bottom: 33px;
    top: inherit;
    width: 240px;
    line-height: 15px;
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 0;
    margin-right: 0;
    left: 0;
    background: #000;
    color: #fff;
    z-index: 2; }
    .tooltipWrap .tooltiptext.hUnit:after {
      bottom: -10px;
      border-color: #000 transparent transparent transparent; }
  .tooltipWrap .tooltiptext.fClass {
    margin: 0;
    left: inherit;
    right: 20px;
    width: 240px;
    top: -10px;
    bottom: inherit;
    text-align: left;
    padding: 8px 10px;
    line-height: 14px;
    background: #000;
    color: #fff; }
    .tooltipWrap .tooltiptext.fClass:after {
      bottom: inherit;
      margin: 0;
      top: 14px;
      height: 8px;
      border-color: transparent transparent transparent #000;
      left: inherit;
      right: -10px; }

.div.custom-select.selectSingle.active {
  border-color: #0093d0 !important;
  background: #e5f4fa url("./../../assets/images/icon-pallet-act.png") 10px 6px no-repeat !important; }

.div.custom-select.selectSingle.active .formSelectBoxImp.formControl div > div > div[aria-pressed] > div > div[role="button"] {
  border: 0; }

.div.custom-select.selectSingle.active .formSelectBoxImp.formControl div > div > div[aria-pressed] > div > div[role=button] span {
  color: #0093d0 !important; }

.icon-field.other-unit-type.active {
  border-color: #0093d0 !important;
  background: #e5f4fa; }

.icon-field.other-unit-type.active .formSelectBoxImp.formControl div > div > div[aria-pressed] > div > div[role="button"] {
  border: 0; }

.icon-field.other-unit-type.active .formSelectBoxImp.formControl div > div > div[aria-pressed] > div > div[role=button] span {
  color: #0093d0 !important;
  font-weight: 700;
  line-height: 24px; }

.col80 {
  width: 80% !important;
  float: left; }

.white-section.quote .content-section .item-detail .col-left .row .column1 .col20 {
  width: 40px;
  float: right; }

.white-section.quote .content-section .item-detail .col-left .row .column1 .col20 .formSelectBoxImp.formControl label + div:before {
  border-left: 0px; }

.datePicker {
  position: relative; }
  .datePicker .calendar-icon {
    position: absolute;
    top: 19px;
    right: 32px;
    pointer-events: none; }
    .datePicker .calendar-icon button {
      border: 0;
      background: #fff; }
      .datePicker .calendar-icon button i:before {
        color: #000;
        font-size: 24px; }

.col.deliveryInfo {
  padding-left: 30px; }

.white-section.quote .content-section .item-detail .col-left .row .column1 .col20 .formSelectBoxImp label + div > div > div {
  padding-left: 0 !important;
  padding-right: 15px !important; }

.selectSingle .formSelectBoxImp.formControl div > div > div[aria-pressed] > div > div[role=button] {
  display: block; }

.selectSingle .formSelectBoxImp.formControl div > div > div[aria-pressed] > div > div[role=button] span {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%; }

.formSelectBoxRdonly.formControl div > div > div[aria-pressed] > div > div[role=button] span {
  white-space: normal;
  word-break: break-word; }

.formSelectBoxImp.formControl div > div > div[aria-pressed] > div > div[role=button] {
  margin-right: 4px;
  height: 20px !important;
  font-size: 14px;
  border-bottom: 1px solid #fff; }

ul[role="listbox"] li {
  background: none;
  font-size: 14px !important;
  line-height: 16px !important;
  font-family: "latoregular", sans-serif !important;
  font-weight: bold !important;
  padding: 4px 20px 8px !important;
  margin-bottom: 0px !important;
  background: #fff !important;
  white-space: normal;
  height: auto; }
  ul[role="listbox"] li:hover {
    color: #0093d0;
    background: #fff; }
  ul[role="listbox"] li[class*="MuiMenuItem-selected-"] {
    color: #0093d0; }
  ul[role="listbox"] li em {
    font-style: normal; }

.contenWrapper.quoteFormpage {
  padding: 20px 40px !important; }
  .contenWrapper.quoteFormpage .highlighted input:focus,
  .contenWrapper.quoteFormpage .highlighted [class*='MuiSelect-root']:focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 107, 11, 0.25) !important;
    width: 100%; }
  .contenWrapper.quoteFormpage .highlighted-on-focus {
    box-shadow: 0 0 0 0.25rem rgba(255, 107, 11, 0.25) !important; }

.white-section.quote h2 {
  position: relative; }

.white-section.quote h2 .closeBtn {
  position: absolute;
  left: -24px;
  top: -28px; }

.closeBtn {
  background: #0093d0;
  border: 0;
  color: #fff;
  width: 24px;
  height: 24px;
  line-height: 23px;
  text-align: center;
  border-radius: 100%;
  cursor: pointer; }

.other-unit-type .formControl label {
  transition: inherit !important;
  transform: inherit !important;
  font-family: 'Lato', sans-serif;
  font-size: 12px !important;
  color: #9b9b9b !important;
  margin-top: 8px;
  padding-left: 10px !important;
  font-weight: 700; }
  .other-unit-type .formControl label + div div[role="button"] span {
    padding-left: 20px !important; }

.zmdi-format-list-bulleted-blue {
  background: #0093d0;
  width: 20px;
  height: 18px;
  text-align: center;
  border-radius: 4px;
  margin-right: 5px;
  vertical-align: middle;
  font-size: 16px; }
  .zmdi-format-list-bulleted-blue:before {
    color: #fff !important; }

.textBlue {
  color: #0093d0; }

.fSize14 {
  font-size: 14px !important; }

.DialogBox .DialogBoxcontent {
  padding: 0px 0px 0px; }
  .DialogBox .DialogBoxcontent fieldset {
    padding: 20px 30px; }
    .DialogBox .DialogBoxcontent fieldset.row-margin-b20 .col-grid2 {
      margin-bottom: 0px; }
    .DialogBox .DialogBoxcontent fieldset.graybg {
      background: #f9f9f9; }

.DialogBox.large > div[role="document"] {
  max-width: 960px;
  width: 100%; }

.DialogBox.large #scroll-dialog-title {
  border-bottom: 1px solid #ddd;
  background: #f8f8f8; }

.DialogBox.xlarge > div[role="document"] {
  max-width: 1060px;
  width: 100%; }

.DialogBox.xlarge #scroll-dialog-title {
  border-bottom: 1px solid #ddd;
  background: #f8f8f8; }

.DialogBox.xtall > div[role="document"] {
  max-width: 1060px;
  width: 100%;
  max-height: 800px;
  height: 100%; }

.DialogBox.xtall #scroll-dialog-title {
  border-bottom: 1px solid #ddd;
  background: #f8f8f8; }

.tooltip-title {
  font-size: 13px;
  font-weight: bold;
  color: #fff;
  height: auto !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5) !important;
  width: max-content !important;
  left: inherit !important;
  max-width: 255px !important;
  line-height: 1.2;
  text-align: left !important;
  padding: 0 !important;
  background-color: #FF781F !important;
  border-radius: 10px !important; }

.tooltip-title ul {
  background-color: #fff;
  color: #000;
  border-radius: 10px;
  font-weight: 500;
  margin-top: 7px;
  width: auto;
  padding: 10px 10px 10px 50px;
  list-style-type: inherit; }

.tooltip-title p {
  margin: auto;
  padding-top: 5px;
  word-wrap: break-word;
  width: 125px;
  text-align: center; }

.tooltip-title li {
  margin-left: -25px;
  width: auto;
  color: #000;
  line-height: 1.4em; }

.column1.focus .tooltipWrap .tooltiptext.hUnit {
  visibility: visible;
  opacity: 1;
  display: block; }

.tooltipWrap.focus .tooltiptext.stackable {
  visibility: visible;
  opacity: 1;
  display: block; }

.formAction.quoteForm button.focus .tooltiptext {
  visibility: visible;
  opacity: 1; }

.formSelectBoxImp.customChecked > label + div.highlighted-on-focus {
  min-height: 33px !important; }

.btnInactive span.product-error {
  font-weight: normal;
  line-height: 1.6em;
  margin-bottom: 10px; }
  .btnInactive span.product-error br {
    content: "";
    margin: 2em;
    display: block;
    font-size: 24%; }
  .btnInactive span.product-error span {
    margin-left: -41px; }

.newFormErrorInput {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  width: 100%; }

.newFormErrorInput [class*='MuiInput-root'] {
  border-color: red; }

.newFormErrorInput .invalidTxt {
  padding-left: 12px; }

.amountduePopup {
  background: #fff;
  width: 374px;
  max-width: 580px;
  box-shadow: 0px 1px 5px 1px #ddd;
  position: absolute;
  left: -35px;
  top: 24px;
  z-index: 10;
  display: table; }
  .amountduePopup:before {
    position: absolute;
    content: '';
    border: 5px solid transparent;
    border-bottom: 5px solid #dfdfe4;
    left: 160px;
    top: -11px; }
  .amountduePopup:after {
    position: absolute;
    content: '';
    border: 5px solid transparent;
    border-bottom: 5px solid #fff;
    left: 160px;
    top: -10px; }
  .amountduePopup .popup-left {
    width: 78%;
    float: none;
    display: table-cell;
    border-right: 1px solid #dfdfe4;
    vertical-align: top; }
    .amountduePopup .popup-left .head-section {
      width: 100%;
      float: left;
      border-bottom: 2px solid #ebebeb;
      padding: 15px 0; }
      .amountduePopup .popup-left .head-section:before {
        position: absolute;
        content: '';
        border: 10px solid transparent;
        border-left: 10px solid #dfdfe4;
        left: 77.8%;
        top: 6%; }
      .amountduePopup .popup-left .head-section:after {
        position: absolute;
        content: '';
        border: 10px solid transparent;
        border-left: 10px solid #fff;
        left: 77.4%;
        top: 6%; }
      .amountduePopup .popup-left .head-section .col-grid2 {
        margin: 0 !important; }
        .amountduePopup .popup-left .head-section .col-grid2 .col {
          line-height: 22px;
          text-align: center; }
          .amountduePopup .popup-left .head-section .col-grid2 .col h4 {
            color: #9b9b9b;
            font-weight: normal;
            font-family: "latobold", sans-serif;
            font-size: 12px;
            text-align: left;
            margin-bottom: 6px; }
          .amountduePopup .popup-left .head-section .col-grid2 .col em {
            color: #000;
            font-family: "latobold", sans-serif;
            font-style: normal;
            font-size: 12px; }
          .amountduePopup .popup-left .head-section .col-grid2 .col:first-child {
            width: 60% !important;
            padding-left: 10px !important; }
          .amountduePopup .popup-left .head-section .col-grid2 .col:last-child {
            width: 40% !important; }
    .amountduePopup .popup-left .content-section {
      width: 100%;
      float: left;
      padding: 0 8px 10px; }
  .amountduePopup .popup-right {
    width: 22%;
    float: none;
    display: table-cell;
    vertical-align: top;
    background: #f9f9f9;
    padding: 18px 0; }
    .amountduePopup .popup-right .row {
      width: 100%;
      float: left;
      padding: 0 0 12px 12px;
      text-align: left; }
      .amountduePopup .popup-right .row h4 {
        color: #9b9b9b;
        font-weight: normal;
        font-family: "latobold", sans-serif;
        font-size: 12px;
        text-align: left;
        margin-bottom: 6px; }
      .amountduePopup .popup-right .row em {
        color: #000;
        font-family: "latobold", sans-serif;
        font-style: normal;
        font-size: 12px;
        display: block; }
      .amountduePopup .popup-right .row span {
        color: #9b9b9b;
        font-style: normal;
        font-size: 11px !important; }
    .amountduePopup .popup-right .zmdi-close {
      position: absolute;
      right: 3px;
      top: 3px; }
      .amountduePopup .popup-right .zmdi-close:before {
        font-size: 18px !important; }
  .amountduePopup .col-grid2 {
    margin: 0 !important; }
    .amountduePopup .col-grid2 .col {
      line-height: 22px;
      font-size: 11px; }
      .amountduePopup .col-grid2 .col:first-child {
        width: 60% !important;
        padding-left: 10px !important;
        text-align: right; }
      .amountduePopup .col-grid2 .col:last-child {
        width: 40% !important; }
      .amountduePopup .col-grid2 .col .col50 {
        min-height: 1px;
        text-align: left;
        padding-right: 5px;
        width: 100%;
        float: left;
        padding-left: 10px; }
    .amountduePopup .col-grid2:nth-child(even) {
      background: #f9f9f9; }

.quote-result-wraper {
  width: 100%;
  float: left; }
  .quote-result-wraper .left-section {
    width: 252px;
    float: left;
    background: #fff;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-box-sizing: 4px;
    border-radius: 4px;
    padding: 15px 0; }
    .quote-result-wraper .left-section h2 {
      padding: 0 15px;
      margin: 0 0 20px 0;
      font-family: 'Lato', sans-serif; }
    .quote-result-wraper .left-section .block-section {
      width: 100%;
      float: left;
      padding: 0 15px;
      border-bottom: 1px solid #ddd;
      margin-bottom: 20px; }
      .quote-result-wraper .left-section .block-section:last-child {
        border: 0; }
      .quote-result-wraper .left-section .block-section .block-title h3 {
        margin: 0 0 26px 0; }
      .quote-result-wraper .left-section .block-section .block-title .blue-arrow {
        position: absolute;
        left: 50%;
        margin-left: -2px;
        margin-top: -2px;
        cursor: pointer; }
        .quote-result-wraper .left-section .block-section .block-title .blue-arrow i {
          cursor: pointer; }
          .quote-result-wraper .left-section .block-section .block-title .blue-arrow i:before {
            font-size: 17px;
            color: #0093d0; }
      .quote-result-wraper .left-section .block-section .block-content .ship-icons {
        margin: 0;
        padding: 0;
        list-style: none; }
        .quote-result-wraper .left-section .block-section .block-content .ship-icons li {
          float: left;
          width: 25%;
          margin-bottom: 20px; }
          .quote-result-wraper .left-section .block-section .block-content .ship-icons li .tooltiptext {
            margin: 0 0 0 -4%;
            top: -34px;
            bottom: inherit;
            text-align: left;
            padding-left: 10px;
            padding-right: 10px;
            width: auto;
            white-space: nowrap;
            left: inherit;
            right: inherit;
            background: #000;
            color: #fff;
            padding: 4px 8px;
            font-size: 12px; }
            .quote-result-wraper .left-section .block-section .block-content .ship-icons li .tooltiptext:after {
              left: 48%;
              bottom: inherit;
              top: 100%;
              border-color: #000 transparent transparent transparent; }
          .quote-result-wraper .left-section .block-section .block-content .ship-icons li.active svg path {
            fill: #ff6c2f; }
      .quote-result-wraper .left-section .block-section h3 {
        font-family: 'Lato', sans-serif;
        color: #000; }
      .quote-result-wraper .left-section .block-section .line-item {
        width: 100%;
        float: left;
        padding: 30px 0 0 0; }
        .quote-result-wraper .left-section .block-section .line-item .row {
          width: 100%;
          float: left;
          margin-bottom: 30px; }
          .quote-result-wraper .left-section .block-section .line-item .row h3 {
            margin-bottom: 10px;
            color: #000; }
          .quote-result-wraper .left-section .block-section .line-item .row .col {
            width: 33.3%;
            float: left;
            padding-right: 8px; }
            .quote-result-wraper .left-section .block-section .line-item .row .col.colwd41 {
              width: 41%; }
            .quote-result-wraper .left-section .block-section .line-item .row .col.colwd25 {
              width: 25%; }
            .quote-result-wraper .left-section .block-section .line-item .row .col:last-child {
              padding-right: 0; }
            .quote-result-wraper .left-section .block-section .line-item .row .col h4 {
              font-family: 'Lato', sans-serif;
              font-size: 14px;
              color: #000;
              margin-bottom: 6px; }
            .quote-result-wraper .left-section .block-section .line-item .row .col p {
              font-size: 12px;
              color: #4a4a4a; }
    .quote-result-wraper .left-section .col-grid2 .col {
      padding: 0;
      margin-bottom: 8px; }
      .quote-result-wraper .left-section .col-grid2 .col h3 {
        margin: 0;
        padding: 0; }
      .quote-result-wraper .left-section .col-grid2 .col p {
        font-size: 12px; }
  .quote-result-wraper .quote-result-section {
    float: none;
    padding-left: 262px; }
    .quote-result-wraper .quote-result-section .wraper {
      width: 100%;
      float: left; }
      .quote-result-wraper .quote-result-section .wraper .result-list {
        width: 100%;
        float: left;
        margin-bottom: 20px; }
        .quote-result-wraper .quote-result-section .wraper .result-list .border-bottom {
          border-bottom: 1px solid #9b9b9b; }
        .quote-result-wraper .quote-result-section .wraper .result-list ul {
          margin: 0;
          padding: 0;
          width: 100%;
          list-style: none; }
          .quote-result-wraper .quote-result-section .wraper .result-list ul li {
            margin: 0 0 10px 0;
            padding: 0;
            float: left;
            width: 100%;
            background: #fff;
            min-height: 160px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            -ms-border-radius: 4px;
            -o-box-sizing: 4px;
            border-radius: 4px; }
            .quote-result-wraper .quote-result-section .wraper .result-list ul li .col-grid2 {
              padding: 10px 0; }
              .quote-result-wraper .quote-result-section .wraper .result-list ul li .col-grid2 .col {
                margin-bottom: 0; }
                .quote-result-wraper .quote-result-section .wraper .result-list ul li .col-grid2 .col:last-child {
                  border-left: 1px solid #e5e5e5; }
            .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section {
              width: 100%;
              float: left;
              padding-left: 30px; }
              .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type {
                width: 50%;
                float: left;
                padding: 6px 0;
                padding-left: 30px;
                padding-right: 20px; }
                .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type:first-child {
                  border-right: 1px solid #e5e5e5;
                  padding-left: 0px; }
                .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type .ship-status {
                  width: 100%;
                  min-height: 34px;
                  margin-bottom: 12px; }
                .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type h3 {
                  margin: 0;
                  padding: 0;
                  color: #000;
                  font-family: 'Lato', sans-serif;
                  font-size: 14px; }
                .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type .smText-greyish {
                  color: #4a4a4a;
                  font-size: 12px;
                  line-height: 18px; }
                .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type .price {
                  font-family: 'Lato', sans-serif;
                  margin-bottom: 7px;
                  text-overflow: ellipsis;
                  font-weight: 700; }
                  .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type .price samp {
                    color: #000000;
                    font-family: 'Lato', sans-serif;
                    margin-bottom: 12px; }
                  .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type .price i {
                    margin-left: 4px;
                    cursor: pointer; }
                    .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type .price i:before {
                      font-size: 14px;
                      color: #b1b1b1; }
                  .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type .price .amountduePopup {
                    left: -147px;
                    max-width: 526px; }
                    .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type .price .amountduePopup .popup-left {
                      width: 100%; }
                      .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type .price .amountduePopup .popup-left .head-section:before {
                        border: 0; }
                      .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type .price .amountduePopup .popup-left .head-section:after {
                        border: 0; }
                      .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type .price .amountduePopup .popup-left .col-grid2 {
                        padding: 0; }
                        .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type .price .amountduePopup .popup-left .col-grid2 .col {
                          border: 0;
                          padding-left: 10px;
                          padding-right: 0px; }
                          .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type .price .amountduePopup .popup-left .col-grid2 .col h4 {
                            color: #9b9b9b; }
                    .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type .price .amountduePopup .popup-right {
                      width: 1px; }
                      .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type .price .amountduePopup .popup-right i {
                        right: 8px; }
                .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type h4 {
                  margin: 0;
                  padding: 0;
                  color: #000;
                  font-size: 12px; }
                .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type .btn-set {
                  margin: 14px 0 0 0; }
                  .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type .btn-set button {
                    padding: 0 24px !important;
                    min-width: inherit;
                    min-height: 28px; }
                    .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type .btn-set button span {
                      font-size: 14px;
                      text-transform: none; }
                .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type .price-disable {
                  color: #cdcccd;
                  margin-bottom: 12px; }
                .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type.guranteedOff {
                  border: 0;
                  position: relative; }
                  .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type.guranteedOff .btn-set {
                    position: absolute;
                    right: -90px;
                    top: 50%;
                    margin-top: -12px; }
              .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section.left-section {
                min-height: inherit;
                padding: 0; }
                .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section.left-section .ship-type {
                  padding-left: 20px;
                  border: 0;
                  padding-right: 0; }
                  .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section.left-section .ship-type:last-child {
                    padding-right: 0; }
                  .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section.left-section .ship-type .ship-image {
                    float: left;
                    width: 50%;
                    text-align: center; }
                    .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section.left-section .ship-type .ship-image h4 {
                      margin-top: 8px; }
                  .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section.left-section .ship-type .shiping {
                    float: left;
                    width: 50%;
                    text-align: center; }
                    .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section.left-section .ship-type .shiping .zmdi-truck {
                      border-left: 3px solid #0093d0;
                      border-right: 3px solid #0093d0;
                      border-top: 3px solid #0093d0;
                      height: 19px;
                      width: 24px;
                      position: relative;
                      cursor: pointer; }
                      .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section.left-section .ship-type .shiping .zmdi-truck:before {
                        position: relative;
                        top: -1px;
                        left: 1px;
                        font-size: 18px;
                        color: #0093d0; }
                      .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section.left-section .ship-type .shiping .zmdi-truck:after {
                        content: '';
                        position: absolute;
                        border: 12px solid transparent;
                        border-bottom: 5px solid #0093d0;
                        left: -3px;
                        top: -20px; }
                      .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section.left-section .ship-type .shiping .zmdi-truck em {
                        width: 5px;
                        height: 20px;
                        background: #ffff;
                        display: block;
                        position: absolute;
                        right: -4px;
                        top: 6px; }
                .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section.left-section .table {
                  display: table;
                  width: 100%; }
                  .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section.left-section .table .cell {
                    display: table-cell;
                    height: 120px;
                    vertical-align: middle; }
                    .quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section.left-section .table .cell img {
                      max-width: 100%; }
            .quote-result-wraper .quote-result-section .wraper .result-list ul li .carrierDetails {
              width: 100%;
              float: left;
              background: #e8e8e8; }
              .quote-result-wraper .quote-result-section .wraper .result-list ul li .carrierDetails .col-grid2 {
                padding: 0; }
                .quote-result-wraper .quote-result-section .wraper .result-list ul li .carrierDetails .col-grid2 .col {
                  border: 0px; }

.carriersNotused-section {
  width: 100%;
  float: left;
  margin-bottom: 40px; }
  .carriersNotused-section .white-section.search-cutomer {
    padding: 20px 0; }
    .carriersNotused-section .white-section.search-cutomer .filter-title-row {
      padding: 0 20px 15px; }
      .carriersNotused-section .white-section.search-cutomer .filter-title-row h2 {
        margin-left: 0;
        font-size: 18px;
        float: left; }
      .carriersNotused-section .white-section.search-cutomer .filter-title-row .collapsible {
        float: right; }
    .carriersNotused-section .white-section.search-cutomer .inwraper {
      padding: 0px 20px;
      background: #f8f6f6; }
      .carriersNotused-section .white-section.search-cutomer .inwraper h2 {
        color: #5b595a;
        font-size: 16px; }
  .carriersNotused-section .grid-carriersNotused {
    width: 100%;
    float: left; }
    .carriersNotused-section .grid-carriersNotused h2 {
      margin-top: 10px;
      font-size: 14px; }
    .carriersNotused-section .grid-carriersNotused .col {
      width: 25%;
      float: left;
      text-align: left; }
      .carriersNotused-section .grid-carriersNotused .col img {
        margin: -5px 0 0 0; }
      .carriersNotused-section .grid-carriersNotused .col + .col {
        width: 75%;
        float: left; }
  .carriersNotused-section .carriersNotusedResult {
    width: 100%;
    float: left; }
    .carriersNotused-section .carriersNotusedResult .white-section {
      padding: 15px 20px;
      margin-top: 6px; }

.a-link13R {
  font-size: 13px;
  vertical-align: top;
  line-height: 24px; }
  .a-link13R i:before {
    font-size: 15px;
    top: 2px;
    position: relative;
    margin-left: 2px; }

.trace-link {
  position: absolute;
  right: 30px;
  bottom: 0; }

.shipDestinationpopup {
  background: #fff;
  width: 560px;
  max-width: 560px;
  box-shadow: 0px 1px 5px 1px #ddd;
  position: absolute;
  left: -54px;
  top: 14px;
  z-index: 10;
  display: block;
  text-align: left; }
  .shipDestinationpopup .col-grid2 {
    padding: 15px 0px 10px 0px !important; }
    .shipDestinationpopup .col-grid2 .col {
      padding: 20px 30px !important; }
      .shipDestinationpopup .col-grid2 .col p {
        max-width: 140px;
        margin-top: 10px; }
  .shipDestinationpopup .button-set {
    width: 100%;
    float: left;
    padding: 15px 0;
    text-align: center;
    border-top: 2px solid #ebebeb; }
    .shipDestinationpopup .button-set button {
      padding: 10px 32px !important;
      margin: 0 10px; }
  .shipDestinationpopup .zmdi-close {
    position: absolute;
    right: 12px;
    top: 12px;
    z-index: 1; }
    .shipDestinationpopup .zmdi-close:before {
      font-size: 18px !important; }

.cDetailcollapsible {
  border-top: 1px solid #e5e5e5;
  width: 100%;
  float: left;
  padding: 24px 20px 20px; }
  .cDetailcollapsible h2 {
    color: #000;
    margin-bottom: 8px; }
  .cDetailcollapsible .smallTxt {
    font-size: 13px; }

.quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section .ship-type .price sup {
  color: #ff6c2f;
  margin-bottom: 12px;
  position: relative;
  top: -3px;
  font-size: 14px; }

.rangefilter .slider {
  -webkit-appearance: none;
  width: 100%;
  height: 2px;
  background: #ddd;
  outline: none;
  -webkit-transition: .2s;
  padding: 0 !important;
  margin-bottom: 18px;
  float: left;
  border: 0 !important; }

.rangefilter .slider:hover {
  opacity: 1; }

.rangefilter .slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 12px;
  height: 12px;
  background: #0092cc;
  cursor: pointer;
  border-radius: 10px;
  -webkit-border-radius: 10px; }

.rangefilter .slider::-moz-range-thumb {
  width: 12px;
  height: 12px;
  background: #0092cc;
  cursor: pointer;
  border-radius: 10px;
  -webkit-border-radius: 10px; }

.rangefilter p {
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  padding: 2px 2px;
  font-size: 12px;
  color: #868585;
  float: right;
  width: 21%;
  margin-top: -12px;
  text-align: center; }

.rangefilter .handle {
  position: absolute;
  width: 13%;
  height: 2px;
  background: #0092cc;
  max-width: 100%; }

.rangefilter .rfilterLeft {
  float: left;
  width: 70%; }

.rangefilter div[role="slider"] > div div:first-child {
  background: #0092cc;
  z-index: inherit; }

.rangefilter button {
  background: #0092cc;
  z-index: inherit; }
  .rangefilter button + div {
    background: #717171; }

.rfilterLeft.relative div {
  z-index: inherit; }

.quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section.left-section .ship-type .shiping .zmdi-truck {
  border-color: #ddd;
  border-left: 3px solid #ddd;
  border-right: 3px solid #ddd;
  border-top: 3px solid #ddd; }

.quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section.left-section .ship-type .shiping .zmdi-truck:before {
  color: #ddd; }

.quote-result-wraper .quote-result-section .wraper .result-list ul li .shiping-section.left-section .ship-type .shiping .zmdi-truck:after {
  border-bottom: 5px solid #ddd; }

.page .headerSection .hederRight .topNav.account ul > li > a {
  text-decoration: none; }

.blue-attention-icon {
  height: 16px !important;
  width: 16px !important;
  color: #0089ce; }

.carrierDetails {
  min-height: 7px; }

.field-price {
  font-size: 24px;
  font-weight: 700; }

.carrier-loader {
  text-align: center;
  font-size: 24px;
  font-weight: 700; }
  .carrier-loader .header {
    display: flex;
    justify-content: center;
    align-items: center; }
    .carrier-loader .header .dot-container div {
      margin: 0 10px; }
  .carrier-loader .progress-bar {
    margin: 20px 0; }
    .carrier-loader .progress-bar div {
      background-color: #dfe3e5;
      height: 10px;
      border-radius: 5px; }
      .carrier-loader .progress-bar div div {
        background-color: #008acf; }

.dot-container {
  display: flex;
  justify-content: center;
  align-items: center; }
  .dot-container div {
    height: 10px;
    width: 10px;
    background-color: #008acf;
    border-radius: 5px;
    display: inline-block;
    animation: pulse 1.2s infinite ease-in-out both; }
  .dot-container .dot-first {
    animation-delay: -0.8s; }
  .dot-container .dot-second {
    animation-delay: -0.4s; }
  .dot-container .dot-third {
    animation-delay: 0s; }

@keyframes pulse {
  0%,
  100%,
  100% {
    transform: scale(0); }
  50% {
    transform: scale(1); } }

.ship-id {
  position: absolute;
  top: 10px;
  right: 0;
  font-size: 18px;
  font-weight: 700;
  color: #000; }
  .ship-id span {
    color: #ff6c2f; }
  .ship-id.topPos {
    font-size: 16px;
    text-align: right;
    margin-top: -10px; }

.breadcrumbBar {
  width: 100%;
  float: left;
  padding: 0 30px 5px; }
  .breadcrumbBar .breadcrumb {
    float: left;
    background: none;
    padding: 0;
    margin: 5px 0 0; }
    .breadcrumbBar .breadcrumb li {
      vertical-align: middle; }
      .breadcrumbBar .breadcrumb li.breadcrumb-item a {
        font-size: 12px;
        line-height: 14px;
        font-weight: 700;
        color: #000;
        letter-spacing: .1px;
        text-transform: uppercase; }

.backLink {
  display: inline-block;
  margin-right: 20px;
  position: absolute;
  top: -4px;
  z-index: 1;
  left: 0; }
  .backLink a {
    background: #fff;
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 3px;
    text-align: center; }
    .backLink a i {
      position: relative;
      top: 3px;
      font-size: 24px; }
      .backLink a i:before {
        color: #4a4a4a; }

.darkGraybg {
  background-image: linear-gradient(#eaeaea, #e5e5e6 8%, #e9e9e9 82%); }

.shipmentProgressSection {
  width: 100%;
  float: left;
  min-height: 30px;
  margin-bottom: 30px;
  position: relative; }
  .shipmentProgressSection .wraper {
    width: 100%;
    float: left;
    padding: 0px 180px 0 30px;
    text-align: center; }
    .shipmentProgressSection .wraper ul {
      margin: 0 auto;
      padding: 0;
      list-style: none; }
      .shipmentProgressSection .wraper ul li {
        margin: 0 34px 0 0;
        padding: 0 8% 0 0;
        display: inline-block;
        position: relative; }
        .shipmentProgressSection .wraper ul li:last-child {
          padding-right: 0;
          margin-right: 0; }
          .shipmentProgressSection .wraper ul li:last-child:before {
            right: 6px; }
          .shipmentProgressSection .wraper ul li:last-child a {
            padding-right: 0; }
        .shipmentProgressSection .wraper ul li i {
          display: none;
          position: absolute;
          left: -26px;
          top: -2px;
          font-size: 24px; }
          .shipmentProgressSection .wraper ul li i:before {
            color: #0093d0; }
        .shipmentProgressSection .wraper ul li.active a {
          color: #0093d0; }
          .shipmentProgressSection .wraper ul li.active a:before {
            border-color: #0093d0; }
        .shipmentProgressSection .wraper ul li.done a {
          color: #0093d0; }
          .shipmentProgressSection .wraper ul li.done a:before {
            border-color: #0093d0; }
        .shipmentProgressSection .wraper ul li.done i {
          display: block; }
        .shipmentProgressSection .wraper ul li.done:before {
          background: #0093d0; }
        .shipmentProgressSection .wraper ul li a {
          display: block;
          font-size: 17px;
          position: relative;
          background: #e5e5e6;
          padding-right: 10px;
          color: #9b9b9b;
          font-weight: 700;
          text-decoration: none; }
          .shipmentProgressSection .wraper ul li a:hover {
            text-decoration: none; }
          .shipmentProgressSection .wraper ul li a:before {
            content: '';
            position: absolute;
            border: 2px solid #9b9b9b;
            width: 22px;
            height: 22px;
            border-radius: 20px;
            top: -1px;
            left: -26px; }
        .shipmentProgressSection .wraper ul li:before {
          content: '';
          position: absolute;
          width: 68%;
          height: 2px;
          background: #9b9b9b;
          left: inherit;
          right: -8px;
          top: 10px; }
        .shipmentProgressSection .wraper ul li .edit_link {
          float: left;
          font-style: normal;
          color: #ff6c2f;
          font-size: 12px; }
    .shipmentProgressSection .wraper .message12Txt {
      font-size: 12px;
      color: #4a4a4a;
      padding: 8px 0 0; }

.whiteSection {
  width: 100%;
  float: left;
  margin-bottom: 10px;
  padding: 20px 25px;
  background: #fff;
  border-radius: 5px; }
  .whiteSection .head-section {
    width: 100%;
    float: left;
    margin-bottom: 20px;
    padding-top: 10px; }
    .whiteSection .head-section h2 {
      float: left; }
    .whiteSection .head-section .iconlink {
      font-size: 14px;
      vertical-align: top;
      line-height: 18px;
      display: inline-block;
      font-weight: 700; }
      .whiteSection .head-section .iconlink i {
        vertical-align: top;
        display: inline-block; }
        .whiteSection .head-section .iconlink i:before {
          font-size: 18px;
          margin-right: 6px;
          vertical-align: top; }

.InfolabelText {
  font-size: 14px;
  font-weight: 700;
  margin-left: 10px; }
  .InfolabelText.green {
    color: #8bc34a; }
    .InfolabelText.green i {
      color: #8bc34a;
      margin-right: 6px;
      vertical-align: middle; }

.twoColumn .leftSection {
  width: 356px;
  float: left;
  padding: 0 30px 0  0; }
  .twoColumn .leftSection .head-section {
    margin-bottom: 14px; }
  .twoColumn .leftSection .content {
    clear: both; }
    .twoColumn .leftSection .content p {
      color: #4a4a4a;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 20px; }
      .twoColumn .leftSection .content p.orangeLink {
        color: #ff6c2f;
        font-weight: 700; }
        .twoColumn .leftSection .content p.orangeLink a {
          color: #ff6c2f; }
    .twoColumn .leftSection .content .itemListing {
      margin: 26px 0 0;
      padding: 0; }
      .twoColumn .leftSection .content .itemListing li {
        margin: 0 0 0px 0;
        padding: 0; }
        .twoColumn .leftSection .content .itemListing li a {
          font-size: 14px;
          line-height: 18px;
          color: #000;
          font-weight: 700;
          display: block;
          background: transparent;
          border: 1px solid transparent;
          border-right: 0px;
          margin-right: -31px;
          border-right: 1px solid #ddd;
          padding: 6px 8px;
          text-decoration: none; }
          .twoColumn .leftSection .content .itemListing li a i {
            display: inline-block;
            color: #ff6c2f; }
          .twoColumn .leftSection .content .itemListing li a.active {
            color: #ff6c2f;
            background: #f3f3f3;
            border: 1px solid #ddd;
            position: relative; }
            .twoColumn .leftSection .content .itemListing li a.active:before {
              content: '';
              position: absolute;
              width: 14px;
              height: 14px;
              border-top: 1px solid #ddd;
              border-right: 1px solid #ddd;
              background: #f3f3f3;
              transform: rotate(45deg);
              right: -8px;
              top: 17px; }
          .twoColumn .leftSection .content .itemListing li a:hover {
            text-decoration: none;
            color: #ff6c2f;
            background: #f3f3f3;
            border: 1px solid #ddd; }
    .twoColumn .leftSection .content h3 {
      margin-bottom: 40px; }

.twoColumn .rightSection {
  float: none;
  margin: 0 0 0 356px; }
  .twoColumn .rightSection .inputField .labelData:empty,
  .twoColumn .rightSection .inputField .label:empty {
    display: none; }
  .twoColumn .rightSection .wrapper {
    float: left;
    width: 100%;
    border-left: 1px solid #ddd;
    padding: 0 0 0 30px; }
    .twoColumn .rightSection .wrapper .radioGroup .radio-col span:first-child {
      width: 40px;
      height: 40px; }
    .twoColumn .rightSection .wrapper .chargesBlock {
      margin-left: 30px; }
      @media (min-width: 1150px) {
        .twoColumn .rightSection .wrapper .chargesBlock {
          width: 500px; } }
      .twoColumn .rightSection .wrapper .chargesBlock .col {
        margin-bottom: 10px; }
      .twoColumn .rightSection .wrapper .chargesBlock .supPrice {
        color: #ff6c2f;
        font-weight: 700;
        font-size: 18px; }
        .twoColumn .rightSection .wrapper .chargesBlock .supPrice sup {
          font-weight: 700;
          color: #ff6c2f;
          font-size: 14px;
          position: relative;
          top: -6px; }
      .twoColumn .rightSection .wrapper .chargesBlock .lblValue span {
        font-weight: 400;
        color: #7e7e7e;
        font-size: 18px; }
      .twoColumn .rightSection .wrapper .chargesBlock .lblValue.total span {
        color: #000;
        font-size: 18px;
        font-weight: 700; }
      .twoColumn .rightSection .wrapper .chargesBlock .lblValue.total label {
        color: #000; }
      .twoColumn .rightSection .wrapper .chargesBlock .lblValue.grey4aColor label {
        color: #4a4a4a !important; }
    .twoColumn .rightSection .wrapper .InfolabelText i {
      float: left; }
    .twoColumn .rightSection .wrapper .InfolabelText p {
      margin-left: 20px; }
    .twoColumn .rightSection .wrapper .checkBoxWrap span:last-child {
      color: #4a4a4a; }

.boxModel-gray {
  width: 100%;
  float: left;
  background: #fafafa;
  border: 1px solid #e6e6e6;
  padding: 20px 4px 20px 20px; }
  .boxModel-gray h3 {
    margin: 0 0 15px 0; }
  .boxModel-gray p {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 20px;
    padding-right: 20px; }

.note {
  clear: both;
  font-size: 14px;
  line-height: 18px;
  padding-top: 20px;
  color: #4a4a4a; }

.lblValue {
  width: 100%;
  float: left; }
  .lblValue label {
    font-size: 13px;
    color: #7e7e7e;
    float: left;
    line-height: 20px;
    background: #fff;
    position: relative;
    padding-right: 20px; }
  .lblValue span {
    float: right;
    font-weight: 700;
    line-height: 20px;
    background: #fff;
    position: relative;
    padding-left: 30px; }
  .lblValue:before {
    content: '';
    border-bottom: 2px dashed #ddd;
    height: 2px;
    width: 100%;
    position: absolute;
    top: 14px; }

.whiteSection.shipment-confirm {
  padding: 20px 40px 40px; }
  .whiteSection.shipment-confirm .head-section {
    position: relative; }
    .whiteSection.shipment-confirm .head-section h2 {
      font-size: 54px;
      font-weight: 700;
      color: #ff6c2f;
      float: none;
      margin: 0 0 24px 0; }
      .whiteSection.shipment-confirm .head-section h2.showQues {
        font-size: 30px;
        color: #000;
        margin-bottom: 8px; }
        .whiteSection.shipment-confirm .head-section h2.showQues span {
          color: #ff6c2f; }
    .whiteSection.shipment-confirm .head-section h3 {
      font-size: 18px;
      color: #8bc34a;
      font-weight: 700;
      margin: 0 0 24px 0; }
    .whiteSection.shipment-confirm .head-section p {
      font-size: 14px;
      line-height: 18px;
      color: #000;
      margin: 0 0 20px 0; }
    .whiteSection.shipment-confirm .head-section .block-link {
      margin: 0;
      padding: 5px 0 0 0; }
      .whiteSection.shipment-confirm .head-section .block-link p {
        margin-bottom: 12px; }
      .whiteSection.shipment-confirm .head-section .block-link a {
        font-weight: 400;
        display: inline-block; }
    .whiteSection.shipment-confirm .head-section .ship-id {
      position: absolute;
      top: 10px;
      right: 0;
      font-size: 18px;
      font-weight: 700;
      color: #000; }
      .whiteSection.shipment-confirm .head-section .ship-id span {
        color: #ff6c2f; }
  .whiteSection.shipment-confirm .shipment-summary {
    padding: 20px 30px; }
    .whiteSection.shipment-confirm .shipment-summary .leftSection {
      width: 345px;
      float: left;
      padding-right: 20px; }
      .whiteSection.shipment-confirm .shipment-summary .leftSection h2 {
        margin: 0 0 15px 0; }
      .whiteSection.shipment-confirm .shipment-summary .leftSection p {
        padding-right: 0;
        color: #7e7e7e;
        font-size: 14px;
        line-height: 18px; }
        .whiteSection.shipment-confirm .shipment-summary .leftSection p.orangeLink {
          color: #ff6c2f; }
          .whiteSection.shipment-confirm .shipment-summary .leftSection p.orangeLink a {
            color: #ff6c2f; }
      .whiteSection.shipment-confirm .shipment-summary .leftSection i {
        font-size: 18px;
        margin-right: 6px;
        vertical-align: top; }
    .whiteSection.shipment-confirm .shipment-summary .rightSection {
      float: none;
      padding-left: 345px; }
      .whiteSection.shipment-confirm .shipment-summary .rightSection .wrapper {
        width: 100%;
        float: left;
        border-left: 1px solid #ddd;
        min-height: 100px;
        padding: 0 0px 0 30px; }
        .whiteSection.shipment-confirm .shipment-summary .rightSection .wrapper .address-block {
          width: 37%;
          margin-bottom: 20px; }
          .whiteSection.shipment-confirm .shipment-summary .rightSection .wrapper .address-block.cDetails {
            width: 40%; }
          .whiteSection.shipment-confirm .shipment-summary .rightSection .wrapper .address-block p {
            margin: 0; }
            .whiteSection.shipment-confirm .shipment-summary .rightSection .wrapper .address-block p i {
              color: #0093d0;
              margin-right: 4px;
              vertical-align: middle; }
              .whiteSection.shipment-confirm .shipment-summary .rightSection .wrapper .address-block p i.f17 {
                font-size: 17px; }
          .whiteSection.shipment-confirm .shipment-summary .rightSection .wrapper .address-block:last-child {
            width: 26%; }
            .whiteSection.shipment-confirm .shipment-summary .rightSection .wrapper .address-block:last-child img {
              width: 28px;
              vertical-align: middle;
              margin-left: 19px; }
            .whiteSection.shipment-confirm .shipment-summary .rightSection .wrapper .address-block:last-child p {
              padding-bottom: 10px;
              border-bottom: 1px solid #ddd;
              margin-bottom: 15px; }
              .whiteSection.shipment-confirm .shipment-summary .rightSection .wrapper .address-block:last-child p:last-child {
                border: 0;
                margin-bottom: 0; }
          .whiteSection.shipment-confirm .shipment-summary .rightSection .wrapper .address-block.block-last {
            width: 60%;
            max-width: 340px; }
            .whiteSection.shipment-confirm .shipment-summary .rightSection .wrapper .address-block.block-last .col-grid1 > .col {
              margin-bottom: 7px; }
          .whiteSection.shipment-confirm .shipment-summary .rightSection .wrapper .address-block .lblValue span {
            font-size: 15px;
            background: #fafafa;
            font-weight: 400; }
          .whiteSection.shipment-confirm .shipment-summary .rightSection .wrapper .address-block .lblValue label {
            color: #000;
            background: #fafafa; }
        .whiteSection.shipment-confirm .shipment-summary .rightSection .wrapper .row {
          width: 100%;
          float: left;
          margin-bottom: 20px; }
          .whiteSection.shipment-confirm .shipment-summary .rightSection .wrapper .row.bdr-bottom {
            border-bottom: 2px solid #ddd; }
    .whiteSection.shipment-confirm .shipment-summary.tracking {
      background: #fff;
      border: 0px; }

.address-block {
  float: left; }
  .address-block h3 {
    margin: 0 0 12px 0; }
  .address-block p {
    font-size: 12px;
    font-weight: 400;
    color: #000; }
  .address-block.wide {
    width: 100% !important; }

.shipmentConfirmation .whiteSection.shipment-confirm {
  padding: 0; }
  .shipmentConfirmation .whiteSection.shipment-confirm .head-section h2 {
    font-size: 42px; }
    .shipmentConfirmation .whiteSection.shipment-confirm .head-section h2.showQues {
      font-size: 18px; }
  .shipmentConfirmation .whiteSection.shipment-confirm .head-section .ship-id {
    font-size: 16px; }
  .shipmentConfirmation .whiteSection.shipment-confirm .shipment-summary {
    padding: 20px 20px; }
    .shipmentConfirmation .whiteSection.shipment-confirm .shipment-summary .leftSection {
      width: 100%; }
    .shipmentConfirmation .whiteSection.shipment-confirm .shipment-summary .rightSection {
      padding-left: 0px;
      width: 100%;
      float: left; }
      .shipmentConfirmation .whiteSection.shipment-confirm .shipment-summary .rightSection .wrapper {
        border-left: 0px solid #ddd;
        border-top: 1px solid #ddd;
        padding-left: 0;
        padding-top: 30px; }

.shipmentConfirmation .DialogBoxcontent {
  padding: 5px 24px 20px !important; }

.linkBdr {
  border: 1px solid #0093d0;
  border-radius: 4px;
  padding: 5px;
  font-size: 14px;
  font-weight: 600; }
  .linkBdr i {
    margin-right: 6px; }
  .linkBdr:hover {
    text-decoration: none; }

strong {
  font-weight: 700; }

@media print {
  @page {
    size: letter;
    margin: 20px 20px 0; }
  body {
    zoom: 80%;
    width: 100%;
    overflow: visible; }
  .DialogBox.mediumPopup > div[role=document] {
    margin: 0;
    max-width: 100% !important;
    box-shadow: none !important; }
  .DialogBox.mediumPopup.shipmentConfirmation, .DialogBox.mediumPopup.viewReceiptDialog {
    position: static;
    display: block;
    float: left; }
  #root {
    display: none; }
  #scroll-dialog-title {
    display: none; }
  .DialogBox.mediumPopup > div[role=document] .DialogBoxcontent {
    padding: 20px 0 0 0; }
  .DialogBox.mediumPopup.viewReceiptDialog .DialogBoxcontent {
    overflow: hidden;
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important; }
  .DialogBox.mediumPopup.viewReceiptDialog .defaultTable th, .DialogBox.mediumPopup.viewReceiptDialog .defaultTable td, .DialogBox.mediumPopup.viewReceiptDialog .emptyCellTable th, .DialogBox.mediumPopup.viewReceiptDialog .emptyCellTable td, .DialogBox.mediumPopup.viewReceiptDialog .clearHeaderTable th, .DialogBox.mediumPopup.viewReceiptDialog .clearHeaderTable td {
    -webkit-print-color-adjust: exact;
    color-adjust: exact; }
  .DialogBox.mediumPopup.viewReceiptDialog .divider {
    height: 1px;
    border-top: 1px dashed black; }
  .DialogBox.mediumPopup.viewReceiptDialog .viewPage {
    box-shadow: none;
    padding: 0;
    width: 1050px;
    height: 1400px;
    overflow: hidden;
    margin-bottom: 0; }
    .DialogBox.mediumPopup.viewReceiptDialog .viewPage:last-child {
      margin-bottom: 0; }
    .DialogBox.mediumPopup.viewReceiptDialog .viewPage .totalAmountBlock {
      padding-top: 55px; }
    .DialogBox.mediumPopup.viewReceiptDialog .viewPage .totalAmountBlockWithoutDescription {
      padding-top: 65px; } }

/* reacking */
@media only screen and (max-width: 440px) {
  #trackingInformation .table-content .table-content-grid {
    display: grid; }
  #trackingInformation .table-content .table-content-grid > td {
    width: 100%;
    display: table-row; } }

.f14Black {
  color: #4a4a4a;
  font-size: 14px; }

.shiping-tracker-section {
  width: 100%;
  float: left;
  padding: 10px 0 0 0; }
  .shiping-tracker-section .track-wraper {
    width: 100%;
    float: left;
    padding: 0 0 0 76px;
    position: relative; }
    .shiping-tracker-section .track-wraper .progressBaar-horizontal {
      width: 15px;
      float: left;
      background: #fff;
      height: 40px; }
      .shiping-tracker-section .track-wraper .progressBaar-horizontal .fill-bg {
        position: absolute;
        height: auto;
        background: #f5f5f5;
        width: 15px;
        border-radius: 10px;
        top: 46px;
        bottom: 0px; }
    .shiping-tracker-section .track-wraper .content-section {
      float: none; }
      .shiping-tracker-section .track-wraper .content-section .content-row {
        float: none;
        margin-left: 40px;
        height: auto; }
        .shiping-tracker-section .track-wraper .content-section .content-row .content-right {
          width: auto;
          margin-left: 0px; }
          .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data {
            width: 100%;
            float: left;
            border: 0px solid #ebeef1; }
            .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .map-wraper {
              width: 100%;
              float: left;
              padding-bottom: 15px;
              position: relative; }
              .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .map-wraper .indicator {
                width: 15px;
                position: absolute;
                height: calc(100% + 12px);
                background: #f5f5f5;
                left: -40px;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px; }
                .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .map-wraper .indicator.green {
                  background: #89c34a; }
                  .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .map-wraper .indicator.green:RadiusCorner {
                    height: 15px;
                    background: #89c34a; }
                .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .map-wraper .indicator.blue {
                  background: #0093d0; }
                  .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .map-wraper .indicator.blue.RadiusCorner {
                    height: 100%;
                    background: #0093d0; }
              .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .map-wraper:last-child {
                margin-bottom: 0;
                padding-top: 0px; }
                .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .map-wraper:last-child .indicator {
                  border-radius: 0px;
                  border-bottom-left-radius: 10px;
                  border-bottom-right-radius: 10px;
                  margin-top: 0px; }
              .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .map-wraper:nth-last-child(2) .tbl-body .row:last-child .left-column {
                background: transparent; }
            .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-head {
              display: table;
              width: 100%;
              float: left;
              position: relative; }
              .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-head .left-column {
                width: 162px;
                display: table-cell;
                margin: 0;
                padding: 0;
                vertical-align: top;
                font-size: 14px;
                color: #4a4a4a;
                line-height: 14px; }
                .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-head .left-column span {
                  display: none;
                  font-size: 12px;
                  color: #9b9b9b; }
              .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-head .col-grid2 {
                border-bottom: 1px solid #ebeef1;
                border-left: 1px solid #ebeef1;
                border-right: 1px solid #ebeef1;
                margin: 0 !important;
                width: 100%;
                display: table-cell; }
                .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-head .col-grid2 .col {
                  margin: 8px 0;
                  padding: 0 16px !important;
                  font-size: 14px;
                  color: #4a4a4a;
                  line-height: 20px; }
              .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-head.head .col-grid2 {
                border-left: 0;
                border-right: 0; }
                .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-head.head .col-grid2 .col {
                  font-size: 12px;
                  color: #4a4a4a;
                  font-weight: 700; }
              .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-head.headWhite .left-column:before {
                content: '';
                position: absolute;
                left: -20px;
                top: 8px;
                border: 6px solid #4a4a4a;
                border-color: transparent #4a4a4a transparent transparent; }
              .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-head.headWhite .left-column.arrowHide:before {
                border: 0px solid #4a4a4a; }
              .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-head.headWhite .left-column samp {
                position: absolute;
                left: -110px;
                font-style: normal;
                width: 70px;
                font-size: 12px;
                color: #4a4a4a;
                font-weight: 700;
                line-height: 14px; }
              .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-head.headWhite .col-grid2 {
                border-top: 1px solid #ebeef1; }
                .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-head.headWhite .col-grid2:before {
                  content: '';
                  position: absolute;
                  left: 143px;
                  top: 10px;
                  border: 10px solid #ebeef1;
                  border-color: transparent #ebeef1 transparent transparent; }
                .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-head.headWhite .col-grid2:after {
                  content: '';
                  position: absolute;
                  left: 145px;
                  top: 10px;
                  border: 10px solid #ebeef1;
                  border-color: transparent #fff transparent transparent; }
            .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-body {
              clear: both; }
              .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-body.close {
                display: none; }
              .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-body .row {
                width: 100%;
                display: table;
                margin-bottom: 0px; }
              .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-body .left-column {
                width: 162px;
                display: table-cell;
                margin: 0;
                padding: 0;
                vertical-align: middle;
                font-size: 14px;
                color: #4a4a4a;
                line-height: 15px;
                background: url("./../../assets/images/border.png") 20px 0 repeat-y; }
                .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-body .left-column span {
                  display: block;
                  font-size: 12px;
                  color: #9b9b9b;
                  background: #fff; }
              .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-body .col-grid2 {
                border-bottom: 1px solid #ebeef1;
                border-left: 1px solid #ebeef1;
                border-right: 1px solid #ebeef1;
                margin: 0 !important;
                background: #f9f9f9;
                display: table-cell; }
                .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-body .col-grid2:before {
                  content: '';
                  border-bottom: 2px solid #000; }
                .shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-body .col-grid2 .col {
                  margin: 6px 0;
                  padding: 0 16px !important;
                  font-size: 14px;
                  color: #4a4a4a; }

.shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-head .left-column span:empty {
  background: #fff;
  height: 20px;
  width: 20px;
  position: relative;
  top: -19px;
  left: -21px; }

.shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .tbl-body .row:last-child .left-column span {
  padding-bottom: 27px;
  padding-top: 3px;
  line-height: 15px; }

.shiping-tracker-section .track-wraper .content-section .content-row .content-right .shiping-table-data .map-wraper:last-child .left-column:before {
  top: 4px; }

/* end */
/** Manage Payment methods **/
.cardList-wrapper {
  width: 100%;
  float: left; }
  .cardList-wrapper .mainContent {
    width: 100%;
    float: left !important;
    padding-left: 0px !important; }
    .cardList-wrapper .mainContent .wraper {
      width: 100%;
      float: left; }
      .cardList-wrapper .mainContent .wraper .section-block {
        width: 100%;
        float: left; }
        .cardList-wrapper .mainContent .wraper .section-block fieldset {
          width: 100%;
          float: left; }
          .cardList-wrapper .mainContent .wraper .section-block fieldset .card-section-outer {
            width: 100%;
            float: left; }
            .cardList-wrapper .mainContent .wraper .section-block fieldset .card-section-outer .scrollDiv {
              width: 100%;
              float: left;
              border: 0;
              padding: 0 1px;
              overflow: visible; }
              .cardList-wrapper .mainContent .wraper .section-block fieldset .card-section-outer .scrollDiv .card-steps {
                width: 100%;
                float: left;
                background: #fff;
                margin-bottom: 20px;
                padding: 20px 0;
                -webkit-box-shadow: 0px 3px 4px #dedcdc;
                -moz-box-shadow: 0px 3px 4px #dedcdc;
                -ms-box-shadow: 0px 3px 4px #dedcdc;
                -o-box-shadow: 0px 3px 4px #dedcdc;
                box-shadow: 0px 3px 4px #dedcdc;
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                -ms-border-radius: 5px;
                -o-box-sizing: 5px;
                border-radius: 5px; }
                .cardList-wrapper .mainContent .wraper .section-block fieldset .card-section-outer .scrollDiv .card-steps .strip-head {
                  width: 100%;
                  float: left;
                  padding: 0 20px; }
                  .cardList-wrapper .mainContent .wraper .section-block fieldset .card-section-outer .scrollDiv .card-steps .strip-head .cart-radio {
                    float: left;
                    padding-left: 2%; }
                    .cardList-wrapper .mainContent .wraper .section-block fieldset .card-section-outer .scrollDiv .card-steps .strip-head .cart-radio .radioGroup .radio-col span:first-child {
                      width: 40px;
                      height: 40px; }
                  .cardList-wrapper .mainContent .wraper .section-block fieldset .card-section-outer .scrollDiv .card-steps .strip-head .cart-name {
                    float: left;
                    font-size: 12px;
                    color: #7e7e7e;
                    padding-top: 5px; }
                    .cardList-wrapper .mainContent .wraper .section-block fieldset .card-section-outer .scrollDiv .card-steps .strip-head .cart-name img {
                      vertical-align: middle;
                      margin: 0 30px 0 5px; }
                    .cardList-wrapper .mainContent .wraper .section-block fieldset .card-section-outer .scrollDiv .card-steps .strip-head .cart-name span {
                      font-size: 16px;
                      font-weight: 700;
                      color: #4a4a4a;
                      padding: 0px 10px; }
                  .cardList-wrapper .mainContent .wraper .section-block fieldset .card-section-outer .scrollDiv .card-steps .strip-head .cart-type {
                    float: left;
                    padding: 0 10px;
                    font-size: 14px;
                    margin-top: 10px;
                    font-weight: 700;
                    color: #000; }
                    .cardList-wrapper .mainContent .wraper .section-block fieldset .card-section-outer .scrollDiv .card-steps .strip-head .cart-type i {
                      font-size: 24px;
                      vertical-align: middle;
                      line-height: 17px; }
                  .cardList-wrapper .mainContent .wraper .section-block fieldset .card-section-outer .scrollDiv .card-steps .strip-head .detail {
                    float: right;
                    margin-top: 10px; }
                    .cardList-wrapper .mainContent .wraper .section-block fieldset .card-section-outer .scrollDiv .card-steps .strip-head .detail a {
                      font-size: 14px;
                      font-weight: 400; }
                      .cardList-wrapper .mainContent .wraper .section-block fieldset .card-section-outer .scrollDiv .card-steps .strip-head .detail a i {
                        margin-left: 4px;
                        font-size: 22px;
                        line-height: 15px;
                        vertical-align: middle; }
                  .cardList-wrapper .mainContent .wraper .section-block fieldset .card-section-outer .scrollDiv .card-steps .strip-head.addNewCardHead {
                    padding: 0 40px; }
                    .cardList-wrapper .mainContent .wraper .section-block fieldset .card-section-outer .scrollDiv .card-steps .strip-head.addNewCardHead h3 {
                      font-weight: 400;
                      font-size: 18px; }
                    .cardList-wrapper .mainContent .wraper .section-block fieldset .card-section-outer .scrollDiv .card-steps .strip-head.addNewCardHead + .strip-content {
                      border: 0px !important;
                      margin: 0; }
                .cardList-wrapper .mainContent .wraper .section-block fieldset .card-section-outer .scrollDiv .card-steps .strip-content {
                  float: left;
                  width: 100%;
                  border-top: 1px solid #ddd !important;
                  margin: 20px 0 0 0;
                  padding: 20px 20px; }
                  .cardList-wrapper .mainContent .wraper .section-block fieldset .card-section-outer .scrollDiv .card-steps .strip-content .col .inputField {
                    width: 100%;
                    clear: both; }
                    .cardList-wrapper .mainContent .wraper .section-block fieldset .card-section-outer .scrollDiv .card-steps .strip-content .col .inputField .labelData {
                      font-size: 14px;
                      font-weight: 700; }
                  .cardList-wrapper .mainContent .wraper .section-block fieldset .card-section-outer .scrollDiv .card-steps .strip-content .button-set {
                    float: left;
                    padding: 20px 0 0 0;
                    width: 100%; }
                    .cardList-wrapper .mainContent .wraper .section-block fieldset .card-section-outer .scrollDiv .card-steps .strip-content .button-set button {
                      float: left;
                      min-width: 120px;
                      margin: 0 0 10px 10px; }
                  .cardList-wrapper .mainContent .wraper .section-block fieldset .card-section-outer .scrollDiv .card-steps .strip-content.detailsEdit {
                    padding-left: 0px;
                    padding-right: 0px; }
                    .cardList-wrapper .mainContent .wraper .section-block fieldset .card-section-outer .scrollDiv .card-steps .strip-content.detailsEdit .row {
                      padding: 0 40px; }
                      .cardList-wrapper .mainContent .wraper .section-block fieldset .card-section-outer .scrollDiv .card-steps .strip-content.detailsEdit .row.section {
                        padding: 0; }
                        .cardList-wrapper .mainContent .wraper .section-block fieldset .card-section-outer .scrollDiv .card-steps .strip-content.detailsEdit .row.section .col-grid2 {
                          padding: 0 40px; }
                        .cardList-wrapper .mainContent .wraper .section-block fieldset .card-section-outer .scrollDiv .card-steps .strip-content.detailsEdit .row.section .col-grid3 {
                          padding: 0 40px; }
                  .cardList-wrapper .mainContent .wraper .section-block fieldset .card-section-outer .scrollDiv .card-steps .strip-content .row {
                    width: 100%;
                    float: left; }
                    .cardList-wrapper .mainContent .wraper .section-block fieldset .card-section-outer .scrollDiv .card-steps .strip-content .row .headCardbillInfo {
                      width: 100%;
                      float: left;
                      border-top: 1px solid #ddd;
                      padding: 20px 40px 0 40px;
                      margin-top: 10px; }
                      .cardList-wrapper .mainContent .wraper .section-block fieldset .card-section-outer .scrollDiv .card-steps .strip-content .row .headCardbillInfo .labelTxt {
                        float: left;
                        padding-top: 14px; }
                      .cardList-wrapper .mainContent .wraper .section-block fieldset .card-section-outer .scrollDiv .card-steps .strip-content .row .headCardbillInfo .checkboxRight {
                        float: right; }
                    .cardList-wrapper .mainContent .wraper .section-block fieldset .card-section-outer .scrollDiv .card-steps .strip-content .row .labelTxt {
                      font-size: 14px;
                      color: #9b9b9b;
                      font-weight: 700;
                      margin-bottom: 10px;
                      text-transform: uppercase; }
                  .cardList-wrapper .mainContent .wraper .section-block fieldset .card-section-outer .scrollDiv .card-steps .strip-content.detail {
                    padding-left: 88px;
                    padding-right: 88px; }
            .cardList-wrapper .mainContent .wraper .section-block fieldset .card-section-outer .buttonSet {
              float: left;
              width: 100%; }
              .cardList-wrapper .mainContent .wraper .section-block fieldset .card-section-outer .buttonSet button.newCard {
                background: transparent !important;
                width: 100%;
                border: 2px dashed #d8d8d8;
                padding: 24px 15px !important; }
                .cardList-wrapper .mainContent .wraper .section-block fieldset .card-section-outer .buttonSet button.newCard span {
                  color: #9e9e9e !important;
                  font-weight: 400;
                  text-transform: none !important;
                  font-size: 18px; }
                  .cardList-wrapper .mainContent .wraper .section-block fieldset .card-section-outer .buttonSet button.newCard span i {
                    margin-right: 6px; }

.buttonSet {
  clear: both; }
  .buttonSet.paymentInfo {
    text-align: center;
    padding: 40px 0 20px 0; }
    .buttonSet.paymentInfo button {
      text-align: center;
      min-width: 150px;
      margin: 0 10px; }
      .buttonSet.paymentInfo button.btnCancel {
        border: 1px solid #e7e7e7; }

.section-block {
  /** End Manage Payment methods **/ }
  .section-block.agreetc a {
    text-decoration: none; }
  .section-block.agreetc p {
    padding: 10px 0 10px 0;
    color: #565656; }
  .section-block .paymentPage .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-content.detailsEdit {
    padding-top: 20px !important; }
    .section-block .paymentPage .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-content.detailsEdit .headCardbillInfo {
      width: 100%;
      float: left;
      border-top: 1px solid #ddd;
      padding: 20px 40px 0 0px;
      margin-top: 10px; }
      .section-block .paymentPage .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-content.detailsEdit .headCardbillInfo .labelTxt {
        float: left; }
      .section-block .paymentPage .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-content.detailsEdit .headCardbillInfo .checkboxRight {
        float: right; }
  .section-block .btnCancel.gray,
  .section-block .btnSecondary.gray,
  .section-block .btnInactive.gray {
    padding: 0 15px !important;
    background: #fff !important;
    border: 1px solid #e6e6e6 !important;
    min-width: inherit !important; }
    .section-block .btnCancel.gray span,
    .section-block .btnSecondary.gray span,
    .section-block .btnInactive.gray span {
      color: #9b9b9b !important;
      font-weight: 700 !important; }
    .section-block .btnCancel.gray i,
    .section-block .btnSecondary.gray i,
    .section-block .btnInactive.gray i {
      margin-right: 10px;
      font-size: 20px; }
      .section-block .btnCancel.gray i::before,
      .section-block .btnSecondary.gray i::before,
      .section-block .btnInactive.gray i::before {
        font-size: inherit;
        margin: 0; }
  .section-block .btnSecondary.gray:hover {
    background: #eae4e4 !important; }
  .section-block .btnInactive.gray {
    opacity: .7; }
  .section-block .DialogBox .boxModel-gray.shipment-summary.tracking {
    background: transparent;
    padding: 15px; }
  .section-block .DialogBox.mediumPopup.shipmentConfirmation.tracking .DialogBoxcontent {
    min-height: 400px; }
  .section-block .DialogBox.mediumPopup.viewReceiptDialog > div[role=document] {
    max-width: 1165px; }
  .section-block .DialogBox.mediumPopup.viewReceiptDialog .DialogBoxcontent {
    background: gray; }
  .section-block .viewPage {
    color: black;
    font-size: 10px;
    -webkit-box-shadow: -1px 1px 8px -2px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: -1px 1px 8px -2px rgba(34, 60, 80, 0.2);
    box-shadow: -1px 1px 8px -2px rgba(34, 60, 80, 0.4);
    padding: 20px;
    margin-bottom: 30px;
    height: 1424px;
    overflow: hidden;
    background: white; }
    .section-block .viewPage:last-child {
      margin-bottom: 0; }
    .section-block .viewPage .captionLogo {
      font-size: 17px;
      font-weight: bold;
      text-transform: uppercase; }
    .section-block .viewPage .logoBlock {
      width: 320px;
      margin-top: 5px; }
      .section-block .viewPage .logoBlock .logo {
        width: 100%; }
      .section-block .viewPage .logoBlock .subtextLogo {
        line-height: 1.3; }
        .section-block .viewPage .logoBlock .subtextLogo .subtextLogoBlock {
          text-align: center;
          margin-left: 40px;
          width: 165px;
          font-size: 12px; }
    .section-block .viewPage .defaultTable {
      border-collapse: collapse;
      width: 100%; }
      .section-block .viewPage .defaultTable th {
        background-color: #000;
        color: white;
        font-weight: bold;
        width: 33.33%;
        text-transform: uppercase; }
      .section-block .viewPage .defaultTable td, .section-block .viewPage .defaultTable th {
        border: 1px solid #dfe0e2;
        text-align: center;
        line-height: 1.5; }
      .section-block .viewPage .defaultTable th {
        border-top: 0;
        padding-top: 10px;
        padding-bottom: 10px; }
      .section-block .viewPage .defaultTable td {
        font-size: 14px;
        padding-top: 5px;
        padding-bottom: 5px; }
      .section-block .viewPage .defaultTable .balanceDue td {
        font-size: 17px;
        font-weight: bold; }
    .section-block .viewPage .defaultTable.totalAmount td {
      text-align: left;
      padding-left: 15px; }
      .section-block .viewPage .defaultTable.totalAmount td:nth-child(2) {
        width: 39%; }
    .section-block .viewPage .emptyCellTable {
      border-collapse: collapse;
      width: 100%;
      height: 436px;
      border-bottom: 1px solid #dfe0e2; }
      .section-block .viewPage .emptyCellTable th {
        background-color: #000;
        color: white;
        font-weight: bold;
        width: 33.33%;
        height: 35px;
        text-transform: uppercase; }
      .section-block .viewPage .emptyCellTable td, .section-block .viewPage .emptyCellTable th {
        text-align: center;
        line-height: 1.5;
        border-left: 1px solid #dfe0e2;
        border-right: 1px solid #dfe0e2; }
      .section-block .viewPage .emptyCellTable th {
        padding-top: 10px;
        padding-bottom: 10px; }
        .section-block .viewPage .emptyCellTable th:nth-child(5) {
          text-align: left;
          padding-left: 15px;
          padding-right: 15px; }
        .section-block .viewPage .emptyCellTable th:nth-child(6) {
          text-align: left;
          padding-left: 15px;
          padding-right: 15px; }
      .section-block .viewPage .emptyCellTable td {
        font-size: 12px;
        padding-top: 5px;
        padding-bottom: 5px; }
      .section-block .viewPage .emptyCellTable tr {
        height: 25px; }
        .section-block .viewPage .emptyCellTable tr:last-child {
          height: auto; }
        .section-block .viewPage .emptyCellTable tr:nth-child(2) > td:nth-child(4) {
          text-align: left !important;
          padding-left: 15px;
          padding-right: 15px; }
        .section-block .viewPage .emptyCellTable tr:nth-child(2) > td:nth-child(1) {
          text-align: center;
          padding-left: 15px;
          padding-right: 15px; }
        .section-block .viewPage .emptyCellTable tr:nth-child(2) > td:nth-child(2) {
          padding-left: 15px;
          padding-right: 15px;
          text-align: center; }
        .section-block .viewPage .emptyCellTable tr:nth-child(2) > td:nth-child(3) {
          padding-left: 15px;
          padding-right: 15px;
          text-align: center; }
        .section-block .viewPage .emptyCellTable tr:nth-child(2) > td:nth-child(5) {
          text-align: left;
          padding-left: 15px; }
        .section-block .viewPage .emptyCellTable tr td:nth-child(3) {
          text-align: left;
          padding-left: 15px; }
        .section-block .viewPage .emptyCellTable tr td:nth-child(2) {
          text-align: left;
          padding-left: 15px; }
    .section-block .viewPage .clearHeaderTable {
      border-collapse: collapse;
      width: 100%; }
      .section-block .viewPage .clearHeaderTable th {
        background-color: #000;
        color: white;
        width: 33.33%;
        font-weight: bold;
        text-transform: uppercase; }
      .section-block .viewPage .clearHeaderTable td, .section-block .viewPage .clearHeaderTable th {
        line-height: 1.5; }
      .section-block .viewPage .clearHeaderTable td {
        border: 1px solid #dfe0e2; }
      .section-block .viewPage .clearHeaderTable th {
        text-align: left;
        padding-left: 15px;
        border-top: 0;
        padding-top: 10px;
        padding-bottom: 10px; }
      .section-block .viewPage .clearHeaderTable td {
        font-size: 12px;
        padding-left: 15px;
        padding-top: 5px;
        padding-bottom: 5px; }
    .section-block .viewPage .blockText {
      font-size: 14px;
      line-height: 1.5; }
      .section-block .viewPage .blockText .caption {
        font-weight: bold; }
    .section-block .viewPage .divider {
      height: 1px;
      border-top: 1px dashed black; }
    .section-block .viewPage .shipmentCaptionBlock {
      font-size: 21px;
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
      padding-top: 10px;
      padding-bottom: 8px; }
      .section-block .viewPage .shipmentCaptionBlock .shipmentCaption {
        border-bottom: 2px solid black;
        padding-bottom: 3px; }
    .section-block .viewPage .shipmentCaptionDesc {
      line-height: 1.5;
      width: 720px;
      margin: 0 auto;
      font-size: 12px; }
    .section-block .viewPage .totalAmountBlock {
      width: 259px;
      padding-top: 45px;
      margin-left: auto; }
    .section-block .viewPage .totalAmountBlockWithoutDescription {
      width: 224px;
      padding-top: 60px;
      margin-left: auto; }
    .section-block .viewPage .statusBlock {
      text-align: right;
      margin-top: 10px;
      margin-bottom: 5px;
      padding-right: 10px;
      height: 29px; }
      .section-block .viewPage .statusBlock .status {
        font-size: 24px;
        color: red;
        text-transform: uppercase;
        font-weight: bold; }
    .section-block .viewPage .textBlock {
      height: 24px;
      text-align: right; }
      .section-block .viewPage .textBlock .infoText {
        font-size: 10px; }
      .section-block .viewPage .textBlock .noPaymentText {
        text-align: right;
        font-weight: bolder;
        font-size: 12px;
        text-transform: uppercase; }
    .section-block .viewPage .returnAlert {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: bold;
      margin-top: 80px;
      margin-bottom: 10px; }
    .section-block .viewPage .footerBlock {
      font-size: 12px;
      text-align: center;
      line-height: 1.5;
      margin-top: 10px; }
    .section-block .viewPage .tableBlock {
      height: 645px;
      overflow: hidden; }
    .section-block .viewPage .tableBlockWithoutDescription {
      height: 681px;
      overflow: hidden; }
    .section-block .viewPage .customerInformation {
      height: 261px;
      overflow: hidden; }
    .section-block .viewPage .date {
      font-size: 12px; }
    .section-block .viewPage .adjustedInvoiceDescription {
      font-size: 18px;
      line-height: 1.5;
      height: 835px;
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 20px; }
  @media screen and (min-width: 950px) and (max-width: 1220px) {
    .section-block .DialogBox.viewReceiptDialog .scalingBox {
      zoom: 75%; } }
  @media screen and (min-width: 725px) and (max-width: 949px) {
    .section-block .DialogBox.viewReceiptDialog .scalingBox {
      zoom: 60%; } }
  @media screen and (min-width: 570px) and (max-width: 724px) {
    .section-block .DialogBox.viewReceiptDialog .scalingBox {
      zoom: 45%; } }
  @media screen and (max-width: 570px) {
    .section-block .DialogBox.viewReceiptDialog .scalingBox {
      zoom: 22%; } }

.insurance-content__title {
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: 500; }

.insurance-content__text {
  color: #4a4a4a;
  font-size: 14px;
  line-height: 1.2; }

.insurance-content__input-price {
  display: flex;
  margin-bottom: 30px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  height: 30px; }

.insurance-content__prices {
  height: 115px; }
  .insurance-content__prices label {
    color: #4a4a4a;
    font-size: 14px; }

.insurance-content__accept_radio_buttons {
  margin-bottom: 17px; }
  .insurance-content__accept_radio_buttons label span:last-child {
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    color: #4a4a4a; }

.insurance-content__accept_terms {
  display: flex;
  align-items: center;
  margin-bottom: 0 !important; }
  .insurance-content__accept_terms__terms-link {
    text-decoration: none; }
    .insurance-content__accept_terms__terms-link:hover {
      text-decoration: none; }
  .insurance-content__accept_terms__text {
    font-weight: normal;
    font-size: 14px;
    color: #4a4a4a;
    line-height: 20px; }

.insurance-content__info-text {
  width: 100% !important;
  line-height: 17px; }

.tooltip-container {
  position: absolute;
  display: inline-block;
  bottom: 0;
  right: -20px;
  z-index: 2; }

.tooltip-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #0093d0;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer; }

.tooltip-text {
  font-family: 'Lato', sans-serif;
  position: absolute;
  top: 50%;
  left: 35px;
  transform: translateY(-50%);
  min-width: 260px;
  padding: 8px;
  background-color: #FFFFFF;
  border: 1px solid #CCCCCC;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  font-weight: normal;
  color: #4a4a4a; }
  @media (max-width: 1280px) {
    .tooltip-text {
      top: 67px !important;
      left: -237px !important; } }

.tooltip-container:hover::after {
  content: '';
  position: absolute;
  width: 14px;
  height: 14px;
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background: #FFFFFF;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  right: -24px;
  top: 4px; }
  @media (max-width: 1280px) {
    .tooltip-container:hover::after {
      right: 2px !important;
      top: 24px !important;
      border-left: 1px solid #ddd;
      border-top: 1px solid #ddd;
      border-bottom: none; } }

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1; }

.tooltip-container:hover::after {
  visibility: visible;
  opacity: 1; }

.special-instruction {
  padding: 10px 25px 0 25px; }
  .special-instruction__text {
    font-size: 12px;
    color: #4a4a4a; }

.product-image {
  cursor: pointer; }

.delete-image {
  position: absolute;
  top: -20px;
  left: 305px;
  color: red;
  cursor: pointer; }

.overlayHolder {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  opacity: .001;
  width: calc(100% - 22px);
  height: 100%;
  z-index: 1; }

.DialogBoxcontent {
  min-height: 100px !important; }

.DialogBoxcontent img {
  max-width: 100%; }

.image-loader {
  position: absolute;
  text-align: center;
  background: #fff;
  top: 0;
  height: 100%;
  min-height: 100px;
  width: 100%;
  margin-top: 0;
  opacity: 1;
  z-index: 10; }

.addNewCard {
  float: left;
  width: 100%; }
  .addNewCard button.newCard {
    background: transparent !important;
    width: 100%;
    border: 2px dashed #d8d8d8;
    padding: 24px 15px !important; }
    .addNewCard button.newCard span {
      color: #9e9e9e !important;
      font-weight: 400;
      text-transform: none !important;
      font-size: 18px; }
      .addNewCard button.newCard span i {
        margin-right: 6px; }

.paymentPage button {
  padding-left: 25px !important;
  padding-right: 25px !important;
  min-width: 154px !important; }
  .paymentPage button span {
    font-weight: normal !important; }
  .paymentPage button i:before {
    font-size: 14px;
    margin-right: 12px; }

.paymentPage h3 {
  color: #000;
  font-weight: 700;
  font-size: 16px; }

.paymentPage .DialogTitle {
  float: left;
  width: 100%;
  padding-bottom: 8px; }
  .paymentPage .DialogTitle h2 {
    font-size: 18px;
    color: #000;
    font-weight: 700;
    line-height: 22px;
    float: left;
    padding-right: 12px;
    margin-bottom: 20px; }
  .paymentPage .DialogTitle .message-success {
    font-size: 14px;
    color: #8bc34a;
    line-height: 22px;
    float: left; }
    .paymentPage .DialogTitle .message-success i:before {
      color: #8bc34a;
      font-size: 14px;
      vertical-align: top;
      position: relative;
      top: 0px;
      margin-left: 8px;
      margin-right: 4px; }

.paymentPage .DialogBoxcontent {
  padding: 10px 24px 10px 24px; }

.paymentPage .popupOneColLeft {
  width: 100%;
  float: left; }
  .paymentPage .popupOneColLeft .lefeSidebaar {
    width: 380px;
    float: left; }
    .paymentPage .popupOneColLeft .lefeSidebaar p {
      font-size: 14px;
      color: #4a4a4a;
      margin-bottom: 12px;
      padding-right: 8px; }
    .paymentPage .popupOneColLeft .lefeSidebaar .visa-img h3 {
      margin-bottom: 10px; }
    .paymentPage .popupOneColLeft .lefeSidebaar .visa-img img {
      display: inline-block;
      margin-right: 8px; }
  .paymentPage .popupOneColLeft .mainContent {
    float: none;
    padding-left: 420px; }
    .paymentPage .popupOneColLeft .mainContent .wraper {
      width: 100%;
      float: left; }
      .paymentPage .popupOneColLeft .mainContent .wraper .section-block {
        width: 100%;
        float: left; }
        .paymentPage .popupOneColLeft .mainContent .wraper .section-block fieldset {
          margin: 0;
          padding: 0; }
        .paymentPage .popupOneColLeft .mainContent .wraper .section-block .card-section-outer {
          float: left;
          width: 100%;
          border-radius: 3px;
          -webkit-radius: 3px; }
          .paymentPage .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps {
            float: left;
            width: 100%; }
            .paymentPage .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-head {
              float: left;
              width: 100%;
              border: 1px solid #e6eaee;
              border-bottom: 0;
              padding: 10px 16px; }
              .paymentPage .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-head .cart-name {
                float: left;
                font-size: 14px;
                color: #000;
                padding-right: 40px; }
                .paymentPage .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-head .cart-name img {
                  display: inline-block;
                  vertical-align: middle;
                  margin-right: 6px; }
                .paymentPage .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-head .cart-name .date {
                  font-style: normal;
                  font-size: 14px;
                  padding-left: 10px; }
              .paymentPage .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-head .cart-type {
                float: left; }
                .paymentPage .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-head .cart-type samp {
                  font-style: normal;
                  font-size: 14px;
                  vertical-align: top;
                  font-weight: 700;
                  display: inline-block;
                  margin-top: 4px; }
              .paymentPage .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-head .detail {
                float: right; }
                .paymentPage .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-head .detail a {
                  font-size: 12px;
                  vertical-align: middle;
                  font-style: normal; }
                  .paymentPage .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-head .detail a i:before {
                    color: #0093d0;
                    font-size: 18px;
                    vertical-align: top;
                    position: relative;
                    top: -2px;
                    margin-left: 8px; }
              .paymentPage .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-head:last-child {
                border-bottom: 1px solid #e6eaee;
                margin-top: -1px; }
            .paymentPage .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-content {
              float: left;
              width: 100%;
              background: #f9f9f9;
              padding: 14px 0px 0;
              border: 1px solid #dfdfe4; }
              .paymentPage .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-content .row {
                float: left;
                width: 100%;
                padding-left: 16px;
                padding-right: 16px; }
                .paymentPage .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-content .row .labelTxt {
                  font-size: 14px;
                  color: #9b9b9b;
                  font-weight: 700;
                  margin-bottom: 10px;
                  text-transform: uppercase; }
                .paymentPage .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-content .row.section {
                  border-top: 1px solid #dfdfe4;
                  padding-top: 15px;
                  margin-top: 6px; }
                .paymentPage .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-content .row .inputField {
                  float: left;
                  width: 100%;
                  margin-bottom: 6px; }
              .paymentPage .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-content .button-set {
                border-top: 1px solid #e6eaee;
                padding: 15px 0 0;
                margin-top: 14px; }
                .paymentPage .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-content .button-set button {
                  margin: 0 15px 10px !important; }
              .paymentPage .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-content.detail {
                position: relative;
                padding: 30px 30px 10px !important; }
                .paymentPage .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-content.detail:before {
                  content: '';
                  border: 10px solid transparent;
                  border-bottom: 10px solid #dfdfe4;
                  position: absolute;
                  top: -20px;
                  right: 38px; }
                .paymentPage .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-content.detail:after {
                  content: '';
                  border: 10px solid transparent;
                  border-bottom: 10px solid #f9f9f9;
                  position: absolute;
                  top: -19px;
                  right: 38px; }
              .paymentPage .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-content.detailsEdit {
                position: relative; }
                .paymentPage .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-content.detailsEdit:before {
                  content: '';
                  border: 10px solid transparent;
                  border-bottom: 10px solid #dfdfe4;
                  position: absolute;
                  top: -20px;
                  left: 104px; }
                .paymentPage .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .card-steps .strip-content.detailsEdit:after {
                  content: '';
                  border: 10px solid transparent;
                  border-bottom: 10px solid #f9f9f9;
                  position: absolute;
                  top: -19px;
                  left: 104px; }
          .paymentPage .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .scrollDiv {
            overflow: visible; }

.paymentPage .col-grid1 .col label.checkBoxWrap {
  margin-bottom: 0;
  margin-left: -8px; }
  .paymentPage .col-grid1 .col label.checkBoxWrap > span:first-child {
    width: 40px;
    height: 40px; }

.paymentPage .col-grid2 .col {
  padding-right: 10px; }
  .paymentPage .col-grid2 .col:last-child {
    padding-left: 10px; }

.primaryStatus i:before {
  color: #0093d0;
  font-size: 14px;
  vertical-align: top;
  position: relative;
  top: -2px;
  margin-right: 4px; }

.scrollDiv {
  border-top: 1px solid #e6eaee;
  overflow: auto; }

.scrollDivDetail {
  border: 1px solid #e6eaee;
  overflow: auto;
  padding: 15px; }

.button-set.grayBox {
  border: 1px solid #ddd;
  border-top: 0px solid #ddd;
  background: #f9f9f9;
  padding: 10px;
  margin: 0; }
  .button-set.grayBox button {
    margin: 0 15px; }

.order-summary-section {
  width: 100%;
  float: left;
  border: 1px solid #e6eaee;
  background: #f9f9f9;
  border-radius: 3px;
  margin-bottom: 10px; }
  .order-summary-section .content {
    width: 100%;
    float: left; }
    .order-summary-section .content .row {
      width: 100%;
      float: left;
      margin-bottom: 12px; }
      .order-summary-section .content .row .label-text {
        float: left;
        width: 62%;
        font-size: 12px;
        color: #4a4a4a;
        word-break: break-word;
        font-weight: 400; }
      .order-summary-section .content .row .label-value {
        float: left;
        width: 38%;
        text-align: right;
        font-size: 12px;
        color: #000;
        word-break: break-word;
        font-weight: 400; }
    .order-summary-section .content.discount .row .label-text {
      width: 60%;
      float: left; }
    .order-summary-section .content.discount .row .label-value {
      width: 40%;
      float: left; }
      .order-summary-section .content.discount .row .label-value button {
        min-width: inherit !important;
        margin-top: 12px; }
    .order-summary-section .content.total {
      border-top: 2px solid #d6d6d6;
      margin-top: 6px;
      padding-top: 6px; }
      .order-summary-section .content.total .row {
        margin-bottom: 4px; }
        .order-summary-section .content.total .row .label-text {
          width: 50%;
          font-weight: 400;
          color: #000; }
        .order-summary-section .content.total .row .label-value {
          color: #000;
          font-size: 12px;
          width: 50%;
          font-weight: 400; }
      .order-summary-section .content.total:last-child .label-text {
        font-weight: 700; }
      .order-summary-section .content.total:last-child .label-value {
        font-size: 14px;
        font-weight: 700; }
  .order-summary-section .order-summary-head {
    width: 100%;
    float: left;
    padding: 12px 15px;
    border-bottom: 1px solid #e6eaee; }
    .order-summary-section .order-summary-head .row .label-text {
      color: #000;
      width: 62%;
      font-size: 12px;
      float: left;
      font-weight: 700; }
    .order-summary-section .order-summary-head .row .label-value {
      color: #000;
      font-size: 14px;
      width: 38%;
      text-align: right;
      float: left;
      font-weight: 700; }
  .order-summary-section .order-summary-content {
    width: 100%;
    float: left;
    padding: 10px 15px; }

.message-success {
  font-size: 14px;
  color: #8bc34a;
  line-height: 22px;
  float: left; }
  .message-success i:before {
    color: #8bc34a;
    font-size: 14px;
    vertical-align: top;
    position: relative;
    top: 0px;
    margin-right: 4px; }

.card-section-outer.shipment .card-steps .strip-head {
  height: 46px; }
  .card-section-outer.shipment .card-steps .strip-head .cart-checkbox {
    float: left; }
    .card-section-outer.shipment .card-steps .strip-head .cart-checkbox .checkBoxWrap {
      margin: -8px 0 0 -10px; }
      .card-section-outer.shipment .card-steps .strip-head .cart-checkbox .checkBoxWrap span:first-child {
        width: 40px;
        height: 40px; }

.card-section-outer.shipment .card-steps .strip-content {
  padding: 0 !important;
  margin: 0; }
  .card-section-outer.shipment .card-steps .strip-content .row.section {
    border: 0 !important;
    border-bottom: 1px solid #dfdfe4 !important;
    padding: 0 !important;
    margin: 0 !important; }
    .card-section-outer.shipment .card-steps .strip-content .row.section .checkbox {
      float: left;
      margin-top: 6px; }
      .card-section-outer.shipment .card-steps .strip-content .row.section .checkbox .checkBoxWrap {
        margin: 0 0 0 7px; }
        .card-section-outer.shipment .card-steps .strip-content .row.section .checkbox .checkBoxWrap span:first-child {
          width: 40px;
          height: 40px; }
    .card-section-outer.shipment .card-steps .strip-content .row.section .cart-detail {
      float: left;
      margin-top: 11px;
      width: 50%; }
      .card-section-outer.shipment .card-steps .strip-content .row.section .cart-detail img {
        float: left;
        margin-right: 8px; }
      .card-section-outer.shipment .card-steps .strip-content .row.section .cart-detail samp {
        color: #000;
        font-style: normal;
        float: left;
        line-height: 30px; }
      .card-section-outer.shipment .card-steps .strip-content .row.section .cart-detail .name {
        font-size: 14px;
        color: #000; }
      .card-section-outer.shipment .card-steps .strip-content .row.section .cart-detail .expiration {
        margin: -6px 0 0 48px; }
      .card-section-outer.shipment .card-steps .strip-content .row.section .cart-detail .date {
        font-size: 14px;
        padding-left: 8px;
        font-weight: 700; }
      .card-section-outer.shipment .card-steps .strip-content .row.section .cart-detail .primary {
        font-size: 14px;
        padding-left: 8px;
        float: right; }
    .card-section-outer.shipment .card-steps .strip-content .row.section .amount {
      float: right;
      width: 120px;
      margin-bottom: 6px; }
      .card-section-outer.shipment .card-steps .strip-content .row.section .amount span {
        font-size: 12px;
        color: #9b9b9b;
        padding-right: 2%; }
      .card-section-outer.shipment .card-steps .strip-content .row.section .amount .white-field {
        background: #fff;
        padding: 0 5px;
        border: 1px solid #eee;
        width: 93%; }
        .card-section-outer.shipment .card-steps .strip-content .row.section .amount .white-field input {
          border: 0;
          font-size: 14px !important; }
        .card-section-outer.shipment .card-steps .strip-content .row.section .amount .white-field .form-inputField > div:before {
          border: 0 !important; }
        .card-section-outer.shipment .card-steps .strip-content .row.section .amount .white-field .form-inputField > div:after {
          border: 0 !important; }
    .card-section-outer.shipment .card-steps .strip-content .row.section .detail {
      clear: both;
      text-align: right; }
      .card-section-outer.shipment .card-steps .strip-content .row.section .detail a {
        font-size: 14px;
        margin-right: 15px;
        margin-bottom: 8px !important;
        display: inline-block; }
        .card-section-outer.shipment .card-steps .strip-content .row.section .detail a i {
          vertical-align: middle;
          padding-left: 4px;
          position: relative;
          z-index: 1; }

.white-field {
  width: 92px; }
  .white-field .form-inputField > div:first-child:before {
    border: 0 !important; }
  .white-field .form-inputField > div:first-child:after {
    border: 0 !important; }
  .white-field .form-inputField > div:first-child input {
    background: #fff;
    border: 1px solid #f4f4f6;
    text-align: center;
    font-size: 12px !important; }

.strip-content.detail.open {
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important; }

.paymentPopup .popupOneColLeft .mainContent .wraper .section-block .radio-col span + span {
  color: #000;
  font-size: 14px; }

.paymentPopup .popupOneColLeft .mainContent .wraper .section-block .card-section-outer .button.absPos {
  position: absolute;
  bottom: 8px;
  right: 30px; }

.DialogBoxfooter.payment {
  height: 52px; }

.paymentPage fieldset.totalRow .inputField {
  float: none;
  background: #f9f9f9;
  border: 1px solid #e6eaee;
  border-radius: 3px;
  margin: 6px 0 !important;
  width: 100%;
  display: table; }
  .paymentPage fieldset.totalRow .inputField .label {
    float: none;
    color: #000;
    font-weight: 700;
    margin: 0 0px 0 0;
    line-height: 34px;
    font-size: 14px;
    text-align: right;
    width: 81%;
    padding-right: 10px;
    display: table-cell; }
  .paymentPage fieldset.totalRow .inputField .labelData {
    float: none;
    width: 19%;
    color: #000;
    font-weight: 700;
    line-height: 34px;
    margin: 0 0px 0 0;
    text-align: right;
    padding-right: 20px;
    display: table-cell; }

.collapsible {
  text-align: center; }

.collapsible i {
  width: 26px;
  height: 26px;
  border: 1px solid #ddd;
  vertical-align: top;
  border-radius: 3px;
  cursor: pointer;
  background: #fff;
  font-size: 24px; }

.opacity5 {
  opacity: .5; }

.whiteSection.paymentPage {
  padding: 40px 40px 10px !important; }

.ach-banner {
  background-color: #f8f8f8;
  border: 2px solid #d8d8d8;
  border-radius: 4px;
  margin-top: -150px; }

@media only screen and (min-width: 300px) and (max-width: 1154px) {
  .ach-banner {
    margin-top: 0; } }

.cart-detail__store-credit {
  float: left;
  margin-top: 13px;
  width: 66%;
  margin-left: 45px; }
  .cart-detail__store-credit img {
    float: left;
    margin-right: 8px; }
  .cart-detail__store-credit samp {
    color: #000;
    font-style: normal;
    float: left;
    line-height: 30px; }
  .cart-detail__store-credit .name {
    font-size: 14px;
    color: #000; }

.customized-tooltip {
  display: initial; }

.single-rate {
  font-family: 'Helvetica Neue', sans-serif;
  font-style: normal; }
  .single-rate h1, .single-rate h2, .single-rate h3, .single-rate h4, .single-rate h5, .single-rate h6 {
    font-family: 'Helvetica Neue', sans-serif; }
  .single-rate button {
    cursor: pointer; }
  .single-rate .arrow-down {
    display: none; }
  .single-rate div[role="tooltip"] {
    opacity: 1;
    padding-top: 3px; }
  .single-rate .results {
    width: 100%;
    height: 773px;
    background-position: 0 0;
    background-repeat: repeat;
    background-color: #707070;
    background-size: cover;
    padding-top: 155px;
    color: #fff;
    transition: background-image .2s .2s; }
    .single-rate .results.n {
      background-image: url("../../../src/assets/images/standart-rate-min.png"); }
    .single-rate .results.g {
      background-image: url("../../../src/assets/images/guaranteed-rate-min.png"); }
    .single-rate .results.empty {
      background-image: url("../../../src/assets/images/no-result-min.png"); }
    .single-rate .results .container {
      max-width: 1141px;
      height: 400px;
      margin: auto; }
      .single-rate .results .container div {
        display: inline-block; }
      .single-rate .results .container .left-side {
        width: 50%;
        float: left; }
        .single-rate .results .container .left-side h1 {
          font-weight: bold;
          font-size: 40px;
          text-transform: uppercase;
          font-family: 'Helvetica Neue', sans-serif; }
        .single-rate .results .container .left-side .details {
          padding-top: 25px;
          display: grid; }
          .single-rate .results .container .left-side .details .row {
            font-size: 20px;
            line-height: 38px; }
            .single-rate .results .container .left-side .details .row .header {
              font-weight: bold; }
            .single-rate .results .container .left-side .details .row .icon {
              display: inline-block;
              margin-right: 15px;
              height: 34px;
              width: 34px;
              position: relative;
              top: 5px; }
              .single-rate .results .container .left-side .details .row .icon .box-icon {
                width: 26px;
                height: 28px; }
              .single-rate .results .container .left-side .details .row .icon .arrows-icon {
                width: 26px;
                height: 27px; }
              .single-rate .results .container .left-side .details .row .icon .truck-icon {
                width: 33px;
                height: 20px; }
              .single-rate .results .container .left-side .details .row .icon .chronometer-icon {
                width: 25px;
                height: 28px; }
              .single-rate .results .container .left-side .details .row .icon .dots-icon {
                width: 26px;
                height: 26px; }
              .single-rate .results .container .left-side .details .row .icon .location-icon {
                width: 22px;
                height: 25px; }
              .single-rate .results .container .left-side .details .row .icon .fast-icon {
                width: 28px;
                height: 20px; }
            .single-rate .results .container .left-side .details .row .attention-icon {
              margin-left: 7px;
              cursor: pointer; }
        .single-rate .results .container .left-side .link {
          font-weight: 700;
          font-size: 16px;
          color: #ff6b0b;
          text-decoration: underline;
          display: inline-block;
          margin-top: 30px;
          cursor: pointer; }
      .single-rate .results .container .right-side {
        margin: 15px 0 0 60px; }
        .single-rate .results .container .right-side.standard-only .header {
          margin-left: 175px; }
        .single-rate .results .container .right-side.standard-only .rates {
          width: auto; }
          .single-rate .results .container .right-side.standard-only .rates .selected {
            margin-left: 174px; }
        .single-rate .results .container .right-side.guaranteed-only .header {
          margin-left: 175px; }
        .single-rate .results .container .right-side.guaranteed-only .rates {
          width: auto; }
          .single-rate .results .container .right-side.guaranteed-only .rates .selected {
            margin-left: 174px; }
        .single-rate .results .container .right-side .header {
          margin-bottom: 4px; }
          .single-rate .results .container .right-side .header h2 {
            text-transform: uppercase;
            font-size: 20px; }
        .single-rate .results .container .right-side .rates {
          height: 120px;
          width: 477px;
          display: block; }
          .single-rate .results .container .right-side .rates .selected {
            border: 2px solid #ff6b0b;
            width: 280px;
            height: 95%;
            border-radius: 5px;
            text-align: center; }
            .single-rate .results .container .right-side .rates .selected .value {
              font-size: 55px; }
              .single-rate .results .container .right-side .rates .selected .value div {
                font-size: 14px;
                margin-top: 8px; }
            .single-rate .results .container .right-side .rates .selected .label {
              font-size: 12px; }
          .single-rate .results .container .right-side .rates .not-selected {
            opacity: .4;
            cursor: pointer;
            position: relative;
            top: -20px; }
            .single-rate .results .container .right-side .rates .not-selected .value {
              font-size: 38px;
              width: 100%;
              margin-left: 10px;
              display: inline;
              text-align: center; }
            .single-rate .results .container .right-side .rates .not-selected .label {
              font-size: 11px;
              width: 37px; }
            .single-rate .results .container .right-side .rates .not-selected .cent {
              font-size: 18px;
              top: -3px; }
          .single-rate .results .container .right-side .rates .mobile-booking-btn {
            display: none; }
        .single-rate .results .container .right-side .currency {
          font-weight: 500;
          vertical-align: text-top;
          font-size: 16px; }
        .single-rate .results .container .right-side .value {
          font-weight: bold;
          width: 100%;
          margin: auto;
          height: 94px;
          margin-top: 21px; }
        .single-rate .results .container .right-side .label {
          font-style: italic;
          vertical-align: middle;
          width: 65px;
          display: inline-block;
          text-align: left; }
        .single-rate .results .container .right-side .cent {
          font-weight: 500;
          vertical-align: super;
          font-size: 20px;
          margin: 0 2px 0 1px;
          position: relative;
          top: -7px; }
        .single-rate .results .container .right-side .action-btn button {
          width: 280px;
          height: 70px;
          background: #ff6b0b;
          border: 0;
          border-radius: 3px;
          color: #fff;
          font-size: 20px;
          font-weight: bold;
          margin: 15px 0 0 175px;
          padding: 25px; }
        .single-rate .results .container .right-side .share-link {
          display: none;
          margin: 30px 0 0 380px;
          font-weight: 400; }
          .single-rate .results .container .right-side .share-link img {
            margin-right: 3px; }
    .single-rate .results .no-result {
      width: 604px;
      height: 380px;
      margin: auto;
      text-align: center; }
      .single-rate .results .no-result img {
        width: 88px;
        height: 88px; }
      .single-rate .results .no-result h2.header {
        font-size: 35px;
        text-transform: uppercase;
        font-weight: bold;
        margin-top: 40px; }
      .single-rate .results .no-result p.info-text {
        font-size: 25px;
        margin-top: 25px;
        line-height: 30px; }
      .single-rate .results .no-result .buttons {
        margin-top: 30px; }
        .single-rate .results .no-result .buttons button {
          width: 240px;
          height: 60px;
          background: #ff6b0b;
          border: 0;
          border-radius: 3px;
          color: #fff;
          font-size: 20px;
          font-weight: bold;
          padding: 20px;
          text-transform: uppercase;
          margin: 26px; }

.single-rate-v3 {
  font-family: 'Helvetica Neue', sans-serif;
  font-style: normal; }
  .single-rate-v3 h1, .single-rate-v3 h2, .single-rate-v3 h3, .single-rate-v3 h4, .single-rate-v3 h5, .single-rate-v3 h6 {
    font-family: 'Helvetica Neue', sans-serif; }
  .single-rate-v3 button {
    cursor: pointer; }
  .single-rate-v3 .arrow-down {
    display: none; }
  .single-rate-v3 div[role="tooltip"] {
    opacity: 1;
    padding-top: 3px; }
  .single-rate-v3 .results {
    width: 100%;
    height: 773px;
    background-position: 0 0;
    background-repeat: repeat;
    padding-top: 155px;
    background-color: #51A2E5;
    color: #fff; }
    .single-rate-v3 .results .container {
      max-width: 1141px;
      height: 400px;
      margin: auto; }
      .single-rate-v3 .results .container div {
        display: inline-block; }
      .single-rate-v3 .results .container .left-side {
        width: 50%;
        float: left; }
        .single-rate-v3 .results .container .left-side h1 {
          font-weight: bold;
          font-size: 40px;
          text-transform: uppercase;
          font-family: 'Helvetica Neue', sans-serif; }
        .single-rate-v3 .results .container .left-side .details {
          padding-top: 25px;
          display: grid; }
          .single-rate-v3 .results .container .left-side .details .row {
            font-size: 20px;
            line-height: 38px; }
            .single-rate-v3 .results .container .left-side .details .row .header {
              font-weight: bold; }
            .single-rate-v3 .results .container .left-side .details .row .icon {
              display: inline-block;
              margin-right: 15px;
              height: 34px;
              width: 34px;
              position: relative;
              top: 5px; }
              .single-rate-v3 .results .container .left-side .details .row .icon .box-icon {
                width: 26px;
                height: 28px; }
              .single-rate-v3 .results .container .left-side .details .row .icon .arrows-icon {
                width: 26px;
                height: 27px; }
              .single-rate-v3 .results .container .left-side .details .row .icon .truck-icon {
                width: 33px;
                height: 20px; }
              .single-rate-v3 .results .container .left-side .details .row .icon .chronometer-icon {
                width: 25px;
                height: 28px; }
              .single-rate-v3 .results .container .left-side .details .row .icon .dots-icon {
                width: 26px;
                height: 26px; }
              .single-rate-v3 .results .container .left-side .details .row .icon .location-icon {
                width: 22px;
                height: 25px; }
              .single-rate-v3 .results .container .left-side .details .row .icon .fast-icon {
                width: 28px;
                height: 20px; }
            .single-rate-v3 .results .container .left-side .details .row .attention-icon {
              margin-left: 7px;
              cursor: pointer; }
        .single-rate-v3 .results .container .left-side .link {
          font-weight: 700;
          font-size: 16px;
          text-decoration: underline;
          display: inline-block;
          margin-top: 30px;
          cursor: pointer;
          color: #fff; }
      .single-rate-v3 .results .container .right-side {
        margin: 15px 0 0 60px; }
        .single-rate-v3 .results .container .right-side.standard-only .header {
          margin-left: 175px; }
        .single-rate-v3 .results .container .right-side.standard-only .rates {
          width: auto; }
          .single-rate-v3 .results .container .right-side.standard-only .rates .selected {
            margin-left: 174px; }
        .single-rate-v3 .results .container .right-side.guaranteed-only .header {
          margin-left: 175px; }
        .single-rate-v3 .results .container .right-side.guaranteed-only .rates {
          width: auto; }
          .single-rate-v3 .results .container .right-side.guaranteed-only .rates .selected {
            margin-left: 174px; }
        .single-rate-v3 .results .container .right-side .header {
          margin-bottom: 4px; }
          .single-rate-v3 .results .container .right-side .header h2 {
            text-transform: uppercase;
            font-size: 20px; }
        .single-rate-v3 .results .container .right-side .rates {
          height: 120px;
          width: 477px;
          display: block; }
          .single-rate-v3 .results .container .right-side .rates .selected {
            border: 2px solid #fff;
            background: #fff;
            width: 280px;
            height: 95%;
            border-radius: 5px;
            text-align: center;
            color: #000; }
            .single-rate-v3 .results .container .right-side .rates .selected .value {
              font-size: 55px; }
              .single-rate-v3 .results .container .right-side .rates .selected .value div {
                font-size: 14px;
                margin-top: 8px; }
            .single-rate-v3 .results .container .right-side .rates .selected .label {
              font-size: 12px;
              padding-left: 10px; }
          .single-rate-v3 .results .container .right-side .rates .not-selected {
            opacity: .4;
            cursor: pointer; }
            .single-rate-v3 .results .container .right-side .rates .not-selected .value {
              font-size: 38px;
              width: 100%;
              margin-left: 10px;
              display: inline;
              text-align: center; }
            .single-rate-v3 .results .container .right-side .rates .not-selected .label {
              font-size: 11px;
              width: 37px; }
            .single-rate-v3 .results .container .right-side .rates .not-selected .cent {
              font-size: 18px;
              top: -3px; }
          .single-rate-v3 .results .container .right-side .rates .mobile-booking-btn {
            display: none; }
        .single-rate-v3 .results .container .right-side .currency {
          font-weight: 500;
          vertical-align: super;
          font-size: 16px;
          position: relative;
          top: -7px; }
        .single-rate-v3 .results .container .right-side .value {
          font-weight: bold;
          width: 100%;
          margin: auto;
          height: 94px;
          margin-top: 21px; }
        .single-rate-v3 .results .container .right-side .label {
          font-style: italic;
          vertical-align: middle;
          width: 65px;
          display: inline-block;
          text-align: left; }
        .single-rate-v3 .results .container .right-side .cent {
          font-weight: 500;
          vertical-align: super;
          font-size: 20px;
          margin: 0 2px 0 1px;
          position: relative;
          top: -7px; }
        .single-rate-v3 .results .container .right-side .action-btn button {
          width: 280px;
          height: 70px;
          background: #ff6b0b;
          border: 0;
          border-radius: 3px;
          color: #fff;
          font-size: 20px;
          font-weight: bold;
          margin: 15px 0 0 175px;
          padding: 25px; }
        .single-rate-v3 .results .container .right-side .share-link {
          display: none;
          margin: 30px 0 0 380px;
          font-weight: 400; }
          .single-rate-v3 .results .container .right-side .share-link img {
            margin-right: 3px; }
    .single-rate-v3 .results .no-result {
      width: 604px;
      height: 380px;
      margin: auto;
      text-align: center; }
      .single-rate-v3 .results .no-result img {
        width: 88px;
        height: 88px; }
      .single-rate-v3 .results .no-result h2.header {
        font-size: 35px;
        text-transform: uppercase;
        font-weight: bold;
        margin-top: 40px; }
      .single-rate-v3 .results .no-result p.info-text {
        font-size: 25px;
        margin-top: 25px;
        line-height: 30px; }
      .single-rate-v3 .results .no-result .buttons {
        margin-top: 30px; }
        .single-rate-v3 .results .no-result .buttons button {
          width: 240px;
          height: 60px;
          background: #ff6b0b;
          border: 0;
          border-radius: 3px;
          color: #fff;
          font-size: 20px;
          font-weight: bold;
          padding: 20px;
          text-transform: uppercase;
          margin: 26px; }

.info-section {
  height: 515px;
  max-width: 90%;
  position: relative;
  top: -90px;
  margin: auto;
  border: 1px solid #fff;
  box-shadow: 2px 7px 12px #0000001f;
  -webkit-box-shadow: 2px 7px 12px #0000001f;
  border-radius: 8px;
  background: #fff; }
  .info-section .header {
    width: 824px;
    text-align: center;
    margin: auto;
    margin-top: 70px; }
    .info-section .header h2 {
      font-size: 30px;
      font-weight: bold; }
      .info-section .header h2 .highlighted {
        color: #ff6b0b; }
    .info-section .header h6 {
      font-size: 16px;
      margin-top: 20px;
      color: #707070; }
  .info-section .content {
    width: 90%;
    margin: auto;
    display: flex;
    margin-top: 100px; }
    .info-section .content .item {
      width: 30%;
      height: 130px;
      display: flex;
      margin-right: 40px;
      margin-left: 20px; }
      .info-section .content .item .picture img {
        width: 125px;
        height: 125px; }
      .info-section .content .item .info {
        margin-left: 25px; }
        .info-section .content .item .info .title {
          font-weight: bold;
          font-size: 20px; }
        .info-section .content .item .info .sub-title {
          font-weight: bold;
          font-size: 15px;
          margin-top: 5px; }
        .info-section .content .item .info .description {
          font-size: 14px;
          line-height: 20px;
          margin-top: 10px; }

.shuttersImage {
  background-image: url("../../../src/assets/images/shutterstock.png");
  height: 100%;
  background-size: cover;
  background-position-x: -342px;
  display: block; }

.shuttersBlock {
  display: block; }

.result-banner {
  background-color: black;
  color: white;
  padding-top: 5px;
  padding-bottom: 5px; }

.access-time {
  vertical-align: middle;
  font-size: 36px !important; }

@media only screen and (min-width: 1115px) and (max-width: 1495px) {
  .result-banner {
    font-size: 12px; }
  .access-time {
    font-size: 24px !important; } }

@media only screen and (min-width: 300px) and (max-width: 1114px) {
  .result-banner {
    font-size: 8px; }
  .access-time {
    font-size: 12px !important; } }

@media only screen and (min-width: 1200px) and (max-width: 1740px) {
  .shuttersImage {
    display: none; }
  .single-rate-v3 {
    flex-basis: 100% !important;
    max-width: 100% !important; }
  .shuttersBlock {
    display: none; } }

@media only screen and (min-width: 300px) and (max-width: 767px) {
  .shuttersImage {
    display: none; }
  .single-rate-v3 {
    flex-basis: 100% !important;
    max-width: 100% !important; }
  .shuttersBlock {
    display: none; } }

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .shuttersImage {
    display: none; }
  .single-rate-v3 {
    flex-basis: 100% !important;
    max-width: 100% !important; }
  .shuttersBlock {
    display: none; } }

@media only screen and (min-width: 1025px) and (max-width: 1400px) {
  .info-section .header {
    margin-top: 60px; }
    .info-section .header h2 {
      font-size: 26px; }
    .info-section .header h6 {
      font-size: 15px; }
  .info-section .content {
    margin-top: 60px; }
    .info-section .content .item {
      margin-right: 30px;
      margin-left: 10px; }
      .info-section .content .item .picture img {
        width: 90px;
        height: 90px; }
      .info-section .content .item .info .title {
        font-size: 16px; }
      .info-section .content .item .info .sub-title {
        font-size: 13px; }
      .info-section .content .item .info .description {
        font-size: 13px; } }

@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .single-rate .results .container {
    max-width: 1000px; }
    .single-rate .results .container .left-side h1 {
      font-size: 30px; }
    .single-rate .results .container .right-side.standard-only .header {
      margin-left: 150px; }
    .single-rate .results .container .right-side.standard-only .rates {
      width: auto; }
      .single-rate .results .container .right-side.standard-only .rates .selected {
        margin-left: 150px; }
    .single-rate .results .container .right-side.guaranteed-only .header {
      margin-left: 150px; }
    .single-rate .results .container .right-side.guaranteed-only .rates {
      width: auto; }
      .single-rate .results .container .right-side.guaranteed-only .rates .selected {
        margin-left: 150px; }
    .single-rate .results .container .right-side .header h2 {
      font-size: 15px; }
    .single-rate .results .container .right-side .rates {
      width: 390px;
      height: 105px; }
      .single-rate .results .container .right-side .rates .selected {
        width: 220px; }
        .single-rate .results .container .right-side .rates .selected .value {
          font-size: 43px; }
        .single-rate .results .container .right-side .rates .selected .cent {
          top: -3px;
          font-size: 17px; }
        .single-rate .results .container .right-side .rates .selected .label {
          font-size: 10px;
          width: 50px; }
      .single-rate .results .container .right-side .rates .not-selected .value {
        font-size: 30px; }
      .single-rate .results .container .right-side .rates .not-selected .cent {
        top: -1px;
        font-size: 14px; }
      .single-rate .results .container .right-side .rates .not-selected .label {
        font-size: 9px; }
    .single-rate .results .container .right-side .action-btn button {
      margin: 50px 0 0 120px; }
  .single-rate-v3 .results .container {
    max-width: 1000px; }
    .single-rate-v3 .results .container .left-side h1 {
      font-size: 30px; }
    .single-rate-v3 .results .container .right-side.standard-only .header {
      margin-left: 150px; }
    .single-rate-v3 .results .container .right-side.standard-only .rates {
      width: auto; }
      .single-rate-v3 .results .container .right-side.standard-only .rates .selected {
        margin-left: 150px; }
    .single-rate-v3 .results .container .right-side.guaranteed-only .header {
      margin-left: 150px; }
    .single-rate-v3 .results .container .right-side.guaranteed-only .rates {
      width: auto; }
      .single-rate-v3 .results .container .right-side.guaranteed-only .rates .selected {
        margin-left: 150px; }
    .single-rate-v3 .results .container .right-side .header h2 {
      font-size: 15px; }
    .single-rate-v3 .results .container .right-side .rates {
      width: 390px;
      height: 105px; }
      .single-rate-v3 .results .container .right-side .rates .selected {
        width: 220px; }
        .single-rate-v3 .results .container .right-side .rates .selected .value {
          font-size: 43px; }
        .single-rate-v3 .results .container .right-side .rates .selected .cent {
          top: -3px;
          font-size: 17px; }
        .single-rate-v3 .results .container .right-side .rates .selected .label {
          font-size: 10px;
          width: 50px; }
      .single-rate-v3 .results .container .right-side .rates .not-selected .value {
        font-size: 30px; }
      .single-rate-v3 .results .container .right-side .rates .not-selected .cent {
        top: -1px;
        font-size: 14px; }
      .single-rate-v3 .results .container .right-side .rates .not-selected .label {
        font-size: 9px; }
    .single-rate-v3 .results .container .right-side .action-btn button {
      margin: 15px 0 0 150px;
      width: 220px; }
    .single-rate-v3 .results .container .right-side .currency {
      top: -3px; } }

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .single-rate .results {
    padding-top: 60px;
    height: 418px; }
    .single-rate .results .container {
      max-width: 1000px; }
      .single-rate .results .container .left-side {
        padding-left: 80px; }
        .single-rate .results .container .left-side h1 {
          font-size: 20px; }
        .single-rate .results .container .left-side .details {
          padding-top: 10px; }
          .single-rate .results .container .left-side .details .row {
            font-size: 10px;
            line-height: 20px; }
            .single-rate .results .container .left-side .details .row .icon {
              margin-right: 7px;
              height: 17px;
              width: 17px;
              top: 2px; }
              .single-rate .results .container .left-side .details .row .icon .box-icon {
                width: 13px;
                height: 14px; }
              .single-rate .results .container .left-side .details .row .icon .arrows-icon {
                width: 13px;
                height: 13px; }
              .single-rate .results .container .left-side .details .row .icon .truck-icon {
                width: 16px;
                height: 10px; }
              .single-rate .results .container .left-side .details .row .icon .chronometer-icon {
                width: 12px;
                height: 14px; }
              .single-rate .results .container .left-side .details .row .icon .dots-icon {
                width: 13px;
                height: 13px; }
              .single-rate .results .container .left-side .details .row .icon .location-icon {
                width: 11px;
                height: 12px; }
              .single-rate .results .container .left-side .details .row .icon .fast-icon {
                width: 14px;
                height: 10px; }
            .single-rate .results .container .left-side .details .row .attention-icon {
              margin-left: 5px;
              width: 6px;
              height: 6px; }
        .single-rate .results .container .left-side .link {
          font-size: 10px; }
      .single-rate .results .container .right-side {
        margin: 10px 0 0 0; }
        .single-rate .results .container .right-side.standard-only .header {
          margin-left: 85px; }
        .single-rate .results .container .right-side.standard-only .rates {
          width: auto; }
          .single-rate .results .container .right-side.standard-only .rates .selected {
            margin-left: 85px; }
        .single-rate .results .container .right-side.guaranteed-only .header {
          margin-left: 85px; }
        .single-rate .results .container .right-side.guaranteed-only .rates {
          width: auto; }
          .single-rate .results .container .right-side.guaranteed-only .rates .selected {
            margin-left: 85px; }
        .single-rate .results .container .right-side .header h2 {
          font-size: 10px; }
        .single-rate .results .container .right-side .rates {
          width: 380px;
          height: 70px; }
          .single-rate .results .container .right-side .rates .selected {
            width: 136px; }
            .single-rate .results .container .right-side .rates .selected .value {
              font-size: 25px;
              height: 50px; }
              .single-rate .results .container .right-side .rates .selected .value div {
                font-size: 8px;
                margin-top: 5px; }
            .single-rate .results .container .right-side .rates .selected .label {
              font-size: 6px;
              width: 30px; }
            .single-rate .results .container .right-side .rates .selected .cent {
              font-size: 12px;
              top: 0; }
          .single-rate .results .container .right-side .rates .not-selected {
            top: -10px; }
            .single-rate .results .container .right-side .rates .not-selected .value {
              font-size: 25px; }
            .single-rate .results .container .right-side .rates .not-selected .label {
              font-size: 5px; }
            .single-rate .results .container .right-side .rates .not-selected .currency {
              font-size: 12px; }
            .single-rate .results .container .right-side .rates .not-selected .cent {
              font-size: 11px;
              top: 0; }
        .single-rate .results .container .right-side .currency {
          font-size: 14px; }
        .single-rate .results .container .right-side .value {
          margin-top: 17px; }
        .single-rate .results .container .right-side .action-btn button {
          margin: 50px 0 0 80px;
          width: 150px;
          height: 40px;
          font-size: 12px;
          padding: 12px; }
    .single-rate .results .no-result {
      width: 270px;
      height: 133px;
      margin-top: 35px; }
      .single-rate .results .no-result img {
        width: 37px;
        height: 37px; }
      .single-rate .results .no-result h2.header {
        font-size: 15px;
        margin-top: 20px; }
      .single-rate .results .no-result p.info-text {
        font-size: 10px;
        margin-top: 15px;
        line-height: 14px; }
      .single-rate .results .no-result .buttons {
        margin-top: 25px; }
        .single-rate .results .no-result .buttons button {
          width: 75px;
          height: 23px;
          font-size: 7px;
          padding: 8px;
          margin: 10px;
          border-radius: 1px; }
  .single-rate-v3 .results {
    padding-top: 60px;
    height: 418px; }
    .single-rate-v3 .results .container {
      max-width: 1000px; }
      .single-rate-v3 .results .container .left-side {
        padding-left: 80px; }
        .single-rate-v3 .results .container .left-side h1 {
          font-size: 20px; }
        .single-rate-v3 .results .container .left-side .details {
          padding-top: 10px; }
          .single-rate-v3 .results .container .left-side .details .row {
            font-size: 10px;
            line-height: 20px; }
            .single-rate-v3 .results .container .left-side .details .row .icon {
              margin-right: 7px;
              height: 17px;
              width: 17px;
              top: 2px; }
              .single-rate-v3 .results .container .left-side .details .row .icon .box-icon {
                width: 13px;
                height: 14px; }
              .single-rate-v3 .results .container .left-side .details .row .icon .arrows-icon {
                width: 13px;
                height: 13px; }
              .single-rate-v3 .results .container .left-side .details .row .icon .truck-icon {
                width: 16px;
                height: 10px; }
              .single-rate-v3 .results .container .left-side .details .row .icon .chronometer-icon {
                width: 12px;
                height: 14px; }
              .single-rate-v3 .results .container .left-side .details .row .icon .dots-icon {
                width: 13px;
                height: 13px; }
              .single-rate-v3 .results .container .left-side .details .row .icon .location-icon {
                width: 11px;
                height: 12px; }
              .single-rate-v3 .results .container .left-side .details .row .icon .fast-icon {
                width: 14px;
                height: 10px; }
            .single-rate-v3 .results .container .left-side .details .row .attention-icon {
              margin-left: 5px;
              width: 6px;
              height: 6px; }
        .single-rate-v3 .results .container .left-side .link {
          font-size: 10px; }
      .single-rate-v3 .results .container .right-side {
        margin: 10px 0 0 0; }
        .single-rate-v3 .results .container .right-side.standard-only .header {
          margin-left: 85px; }
        .single-rate-v3 .results .container .right-side.standard-only .rates {
          width: auto; }
          .single-rate-v3 .results .container .right-side.standard-only .rates .selected {
            margin-left: 85px; }
        .single-rate-v3 .results .container .right-side.guaranteed-only .header {
          margin-left: 85px; }
        .single-rate-v3 .results .container .right-side.guaranteed-only .rates {
          width: auto; }
          .single-rate-v3 .results .container .right-side.guaranteed-only .rates .selected {
            margin-left: 85px; }
        .single-rate-v3 .results .container .right-side .header h2 {
          font-size: 10px; }
        .single-rate-v3 .results .container .right-side .rates {
          width: 380px;
          height: 70px; }
          .single-rate-v3 .results .container .right-side .rates .selected {
            width: 136px; }
            .single-rate-v3 .results .container .right-side .rates .selected .value {
              font-size: 25px;
              height: 50px; }
              .single-rate-v3 .results .container .right-side .rates .selected .value div {
                font-size: 8px;
                margin-top: 5px; }
            .single-rate-v3 .results .container .right-side .rates .selected .label {
              font-size: 6px;
              width: 30px; }
            .single-rate-v3 .results .container .right-side .rates .selected .cent {
              font-size: 12px;
              top: 0; }
          .single-rate-v3 .results .container .right-side .rates .not-selected .value {
            font-size: 25px; }
          .single-rate-v3 .results .container .right-side .rates .not-selected .label {
            font-size: 5px; }
          .single-rate-v3 .results .container .right-side .rates .not-selected .currency {
            font-size: 12px; }
          .single-rate-v3 .results .container .right-side .rates .not-selected .cent {
            font-size: 11px;
            top: 0; }
        .single-rate-v3 .results .container .right-side .currency {
          font-size: 14px;
          top: 2px; }
        .single-rate-v3 .results .container .right-side .value {
          margin-top: 17px; }
        .single-rate-v3 .results .container .right-side .action-btn button {
          margin: 8px 0 0 84px;
          width: 138px;
          height: 40px;
          font-size: 12px;
          padding: 12px; }
    .single-rate-v3 .results .no-result {
      width: 270px;
      height: 133px;
      margin-top: 35px; }
      .single-rate-v3 .results .no-result img {
        width: 37px;
        height: 37px; }
      .single-rate-v3 .results .no-result h2.header {
        font-size: 15px;
        margin-top: 20px; }
      .single-rate-v3 .results .no-result p.info-text {
        font-size: 10px;
        margin-top: 15px;
        line-height: 14px; }
      .single-rate-v3 .results .no-result .buttons {
        margin-top: 25px; }
        .single-rate-v3 .results .no-result .buttons button {
          width: 75px;
          height: 23px;
          font-size: 7px;
          padding: 8px;
          margin: 10px;
          border-radius: 1px; }
  .info-section {
    height: 228px; }
    .info-section .header {
      margin-top: 24px;
      width: auto; }
      .info-section .header h2 {
        font-size: 18px; }
      .info-section .header h6 {
        font-size: 10px;
        margin-top: 10px; }
    .info-section .content {
      margin-top: 25px; }
      .info-section .content .item {
        margin-right: 12px;
        margin-left: 10px; }
        .info-section .content .item .picture img {
          width: 60px;
          height: 60px; }
        .info-section .content .item .info {
          margin-left: 15px; }
          .info-section .content .item .info .title {
            font-size: 10px; }
          .info-section .content .item .info .sub-title {
            font-size: 8px;
            margin-top: 3px; }
          .info-section .content .item .info .description {
            font-size: 8px;
            line-height: 11px;
            margin-top: 6px; } }

@media only screen and (min-width: 300px) and (max-width: 767px) {
  body {
    background: #fff; }
  .single-rate .results {
    padding-top: 60px;
    height: 418px; }
    .single-rate .results .container {
      max-width: 1000px;
      display: grid; }
      .single-rate .results .container div {
        display: table-cell; }
      .single-rate .results .container .customized-tooltip {
        display: initial; }
      .single-rate .results .container .left-side {
        width: 74%;
        margin: auto; }
        .single-rate .results .container .left-side h1 {
          font-size: 22px; }
        .single-rate .results .container .left-side .details {
          padding-top: 10px; }
          .single-rate .results .container .left-side .details .row {
            font-size: 10px;
            line-height: 20px; }
            .single-rate .results .container .left-side .details .row .icon {
              margin-right: 4px;
              height: 17px;
              width: 17px;
              top: 2px; }
              .single-rate .results .container .left-side .details .row .icon .box-icon {
                width: 13px;
                height: 14px; }
              .single-rate .results .container .left-side .details .row .icon .arrows-icon {
                width: 13px;
                height: 13px; }
              .single-rate .results .container .left-side .details .row .icon .truck-icon {
                width: 16px;
                height: 10px; }
              .single-rate .results .container .left-side .details .row .icon .chronometer-icon {
                width: 12px;
                height: 14px; }
              .single-rate .results .container .left-side .details .row .icon .dots-icon {
                width: 13px;
                height: 13px; }
              .single-rate .results .container .left-side .details .row .icon .location-icon {
                width: 11px;
                height: 12px; }
              .single-rate .results .container .left-side .details .row .icon .fast-icon {
                width: 14px;
                height: 10px; }
            .single-rate .results .container .left-side .details .row .attention-icon {
              margin-left: 5px;
              width: 6px;
              height: 6px; }
        .single-rate .results .container .left-side .link {
          font-size: 10px;
          margin-top: 15px; }
      .single-rate .results .container .right-side {
        margin: 80px 0 0 0; }
        .single-rate .results .container .right-side.standard-only .header {
          margin-left: 0; }
        .single-rate .results .container .right-side.standard-only .rates {
          width: 100%; }
          .single-rate .results .container .right-side.standard-only .rates .selected {
            margin-left: 0; }
        .single-rate .results .container .right-side.guaranteed-only .header {
          margin-left: 0; }
        .single-rate .results .container .right-side.guaranteed-only .rates {
          width: 100%; }
          .single-rate .results .container .right-side.guaranteed-only .rates .selected {
            margin-left: 0; }
        .single-rate .results .container .right-side .header {
          display: none; }
        .single-rate .results .container .right-side .rates {
          width: 100%;
          display: table; }
          .single-rate .results .container .right-side .rates .selected {
            display: table-cell;
            border: 0;
            height: 125px;
            background-color: #283138;
            border-radius: 0;
            width: 50%;
            text-align: center; }
          .single-rate .results .container .right-side .rates .not-selected {
            display: table-cell;
            height: 125px;
            width: 50%;
            background-color: #DADEE0;
            color: #000;
            opacity: 1;
            text-align: center; }
            .single-rate .results .container .right-side .rates .not-selected .value {
              margin-left: 0; }
          .single-rate .results .container .right-side .rates .cent {
            font-size: 15px;
            top: -4px; }
          .single-rate .results .container .right-side .rates .mobile-booking-btn {
            display: block;
            margin: 20px auto 0;
            width: 92px;
            height: 29px;
            font-size: 8px;
            padding: 11px;
            background: #ff6b0b;
            border: 0;
            border-radius: 1px;
            color: #fff;
            font-weight: bold; }
          .single-rate .results .container .right-side .rates .arrow-down {
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid #ff6b0b;
            margin-left: 44%;
            display: block; }
        .single-rate .results .container .right-side .currency {
          font-size: 12px; }
        .single-rate .results .container .right-side .value {
          font-size: 35px !important;
          display: inline-block !important;
          height: 0;
          margin-top: 20px;
          width: auto; }
        .single-rate .results .container .right-side .label {
          font-size: 6px !important;
          width: 37px; }
        .single-rate .results .container .right-side .action-btn button {
          display: none; }
    .single-rate .results .no-result {
      width: 270px;
      height: 133px;
      margin-top: 35px; }
      .single-rate .results .no-result img {
        width: 37px;
        height: 37px; }
      .single-rate .results .no-result h2.header {
        font-size: 15px;
        margin-top: 20px; }
      .single-rate .results .no-result p.info-text {
        font-size: 10px;
        margin-top: 15px;
        line-height: 14px; }
      .single-rate .results .no-result .buttons {
        margin-top: 25px; }
        .single-rate .results .no-result .buttons button {
          width: 75px;
          height: 23px;
          font-size: 7px;
          padding: 8px;
          margin: 10px;
          border-radius: 1px; }
  .single-rate .results.n .container .right-side .rates .not-selected .arrow-down {
    display: none; }
  .single-rate .results.n .container .right-side .rates .selected .value {
    margin-top: 10px; }
  .single-rate .results.g .container .right-side .rates .not-selected {
    background-color: #283138;
    color: #fff; }
    .single-rate .results.g .container .right-side .rates .not-selected .value {
      margin-top: 10px; }
  .single-rate .results.g .container .right-side .rates .selected {
    background-color: #DADEE0;
    color: #000; }
    .single-rate .results.g .container .right-side .rates .selected .arrow-down {
      display: none; }
  .single-rate-v3 .results {
    padding-top: 60px;
    height: 418px; }
    .single-rate-v3 .results .container {
      max-width: 1000px;
      display: grid; }
      .single-rate-v3 .results .container div {
        display: table-cell; }
      .single-rate-v3 .results .container .customized-tooltip {
        display: initial; }
      .single-rate-v3 .results .container .left-side {
        width: 74%;
        margin: auto; }
        .single-rate-v3 .results .container .left-side h1 {
          font-size: 22px; }
        .single-rate-v3 .results .container .left-side .details {
          padding-top: 10px; }
          .single-rate-v3 .results .container .left-side .details .row {
            font-size: 10px;
            line-height: 20px; }
            .single-rate-v3 .results .container .left-side .details .row .icon {
              margin-right: 4px;
              height: 17px;
              width: 17px;
              top: 2px; }
              .single-rate-v3 .results .container .left-side .details .row .icon .box-icon {
                width: 13px;
                height: 14px; }
              .single-rate-v3 .results .container .left-side .details .row .icon .arrows-icon {
                width: 13px;
                height: 13px; }
              .single-rate-v3 .results .container .left-side .details .row .icon .truck-icon {
                width: 16px;
                height: 10px; }
              .single-rate-v3 .results .container .left-side .details .row .icon .chronometer-icon {
                width: 12px;
                height: 14px; }
              .single-rate-v3 .results .container .left-side .details .row .icon .dots-icon {
                width: 13px;
                height: 13px; }
              .single-rate-v3 .results .container .left-side .details .row .icon .location-icon {
                width: 11px;
                height: 12px; }
              .single-rate-v3 .results .container .left-side .details .row .icon .fast-icon {
                width: 14px;
                height: 10px; }
            .single-rate-v3 .results .container .left-side .details .row .attention-icon {
              margin-left: 5px;
              width: 6px;
              height: 6px; }
        .single-rate-v3 .results .container .left-side .link {
          font-size: 10px;
          margin-top: 15px; }
      .single-rate-v3 .results .container .right-side {
        margin: 80px 0 0 0; }
        .single-rate-v3 .results .container .right-side.standard-only .header {
          margin-left: 0; }
        .single-rate-v3 .results .container .right-side.standard-only .rates {
          width: 100%; }
          .single-rate-v3 .results .container .right-side.standard-only .rates .selected {
            margin-left: 0; }
        .single-rate-v3 .results .container .right-side.guaranteed-only .header {
          margin-left: 0; }
        .single-rate-v3 .results .container .right-side.guaranteed-only .rates {
          width: 100%; }
          .single-rate-v3 .results .container .right-side.guaranteed-only .rates .selected {
            margin-left: 0; }
        .single-rate-v3 .results .container .right-side .header {
          display: none; }
        .single-rate-v3 .results .container .right-side .rates {
          width: 100%;
          display: table; }
          .single-rate-v3 .results .container .right-side .rates .selected {
            display: table-cell;
            border: 0;
            height: 125px;
            background-color: #283138;
            border-radius: 0;
            width: 50%;
            color: #fff;
            text-align: center; }
          .single-rate-v3 .results .container .right-side .rates .not-selected {
            display: table-cell;
            height: 125px;
            width: 50%;
            background-color: #DADEE0;
            color: #000;
            opacity: 1;
            text-align: center; }
            .single-rate-v3 .results .container .right-side .rates .not-selected .value {
              margin-left: 0; }
          .single-rate-v3 .results .container .right-side .rates .cent {
            font-size: 15px;
            top: -4px; }
          .single-rate-v3 .results .container .right-side .rates .mobile-booking-btn {
            display: block;
            margin: 20px auto 0;
            width: 92px;
            height: 29px;
            font-size: 8px;
            padding: 11px;
            background: #ff6b0b;
            border: 0;
            border-radius: 1px;
            color: #fff;
            font-weight: bold; }
          .single-rate-v3 .results .container .right-side .rates .arrow-down {
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid #ff6b0b;
            margin-left: 44%;
            display: block; }
        .single-rate-v3 .results .container .right-side .currency {
          font-size: 12px;
          top: -2px; }
        .single-rate-v3 .results .container .right-side .value {
          font-size: 35px !important;
          display: inline-block !important;
          height: 0;
          margin-top: 20px;
          width: auto; }
        .single-rate-v3 .results .container .right-side .label {
          font-size: 6px !important;
          width: 37px; }
        .single-rate-v3 .results .container .right-side .action-btn button {
          display: none; }
    .single-rate-v3 .results .no-result {
      width: 270px;
      height: 133px;
      margin-top: 35px; }
      .single-rate-v3 .results .no-result img {
        width: 37px;
        height: 37px; }
      .single-rate-v3 .results .no-result h2.header {
        font-size: 15px;
        margin-top: 20px; }
      .single-rate-v3 .results .no-result p.info-text {
        font-size: 10px;
        margin-top: 15px;
        line-height: 14px; }
      .single-rate-v3 .results .no-result .buttons {
        margin-top: 25px; }
        .single-rate-v3 .results .no-result .buttons button {
          width: 75px;
          height: 23px;
          font-size: 7px;
          padding: 8px;
          margin: 10px;
          border-radius: 1px; }
  .single-rate-v3 .results.n .container .right-side .rates .not-selected .arrow-down {
    display: none; }
  .single-rate-v3 .results.n .container .right-side .rates .selected .value {
    margin-top: 10px; }
  .single-rate-v3 .results.g .container .right-side .rates .not-selected {
    background-color: #283138;
    color: #fff; }
    .single-rate-v3 .results.g .container .right-side .rates .not-selected .value {
      margin-top: 10px; }
  .single-rate-v3 .results.g .container .right-side .rates .selected {
    background-color: #DADEE0;
    color: #000; }
    .single-rate-v3 .results.g .container .right-side .rates .selected .arrow-down {
      display: none; }
  .info-section {
    height: auto;
    position: inherit;
    max-width: 100%;
    margin: auto;
    border: 0;
    box-shadow: inherit;
    -webkit-box-shadow: inherit;
    border-radius: 0;
    background: #fff; }
    .info-section .container {
      margin-top: 100px; }
      .info-section .container .header {
        margin-top: 24px;
        width: auto;
        line-height: 28px; }
        .info-section .container .header h2 {
          font-size: 20px; }
        .info-section .container .header h6 {
          font-size: 12px;
          margin-top: 10px; }
      .info-section .container .content {
        margin-top: 0;
        display: inline-block;
        width: 100%; }
        .info-section .container .content .item {
          display: block;
          height: auto;
          width: 100%;
          margin: auto;
          text-align: center;
          margin-top: 90px;
          margin-bottom: 70px; }
          .info-section .container .content .item .picture img {
            width: 130px;
            height: 130px; }
          .info-section .container .content .item .info {
            margin: auto; }
            .info-section .container .content .item .info .title {
              font-size: 18px;
              margin-top: 30px; }
            .info-section .container .content .item .info .sub-title {
              font-size: 18px;
              margin-top: 10px; }
            .info-section .container .content .item .info .description {
              margin: auto;
              margin-top: 15px;
              font-size: 16px;
              line-height: 22px;
              width: 50%; } }

.cursor-pointer {
  cursor: pointer; }

.faq-block {
  height: auto;
  background-color: #dadee0;
  font-family: 'Helvetica Neue', sans-serif;
  padding: 70px; }
  .faq-block .faq-header {
    width: 100%;
    text-align: center; }
    .faq-block .faq-header h1 {
      font-size: 30px;
      font-weight: bold; }
    .faq-block .faq-header h6 {
      font-size: 16px;
      max-width: 822px;
      margin: 40px auto auto; }
  .faq-block .questions {
    margin: auto;
    margin-top: 94px;
    max-width: 1060px; }
    .faq-block .questions .item {
      width: 100%;
      margin-bottom: 30px; }
      .faq-block .questions .item .question {
        background-color: #f0f1f3;
        height: 55px;
        display: inline-flex;
        width: 100%;
        cursor: pointer;
        align-items: center;
        font-size: 16px; }
        .faq-block .questions .item .question .question-icon {
          width: 24px;
          height: 24px;
          margin: auto 25px; }
        .faq-block .questions .item .question span {
          margin: auto 0;
          font-weight: bold; }
        .faq-block .questions .item .question .open-icon {
          display: inline-block;
          margin: auto;
          margin-right: 25px;
          width: 13px;
          height: 25px; }
      .faq-block .questions .item .answer {
        background-color: #7f8994;
        color: #fff;
        padding: 0;
        line-height: 22px;
        display: block;
        overflow: hidden;
        height: 0;
        transition: all 300ms ease; }
        .faq-block .questions .item .answer a {
          color: #fff; }
    .faq-block .questions .item.open .answer {
      height: auto;
      padding: 30px;
      overflow: inherit; }

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .faq-block .faq-header h1 {
    font-size: 20px; }
  .faq-block .faq-header h6 {
    font-size: 12px;
    margin-top: 25px;
    line-height: 16px;
    max-width: 300px; }
    .faq-block .faq-header h6 a {
      color: #ff6b0b; }
  .faq-block .questions {
    margin-top: 60px; }
    .faq-block .questions .item .question {
      height: 40px;
      font-size: 13px; }
      .faq-block .questions .item .question .question-icon {
        width: 20px;
        height: 20px;
        margin: auto 20px; }
      .faq-block .questions .item .question span {
        margin-right: 10px; }
      .faq-block .questions .item .question .open-icon {
        width: 10px;
        margin-right: 20px;
        height: 20px; }
    .faq-block .questions .item .answer {
      font-size: 13px;
      line-height: 19px; }
    .faq-block .questions .item.open .answer {
      padding: 25px; } }

@media only screen and (min-width: 300px) and (max-width: 767px) {
  .faq-block {
    padding: 40px; }
    .faq-block .faq-header h1 {
      font-size: 20px; }
    .faq-block .faq-header h6 {
      font-size: 12px;
      margin-top: 25px;
      line-height: normal; }
      .faq-block .faq-header h6 a {
        color: #ff6b0b; }
    .faq-block .questions {
      margin-top: 60px; }
      .faq-block .questions .item .question {
        height: 30px;
        font-size: 10px; }
        .faq-block .questions .item .question .question-icon {
          width: 15px;
          height: 15px;
          margin: auto 15px; }
        .faq-block .questions .item .question span {
          margin-right: 10px; }
        .faq-block .questions .item .question .open-icon {
          width: 10px;
          margin-right: 10px;
          margin-top: 5px;
          height: 20px; }
      .faq-block .questions .item .answer {
        font-size: 10px;
        line-height: 14px; }
      .faq-block .questions .item.open .answer {
        padding: 15px 20px; } }

.map_bg {
  background-image: url("./../../assets/images/map_bg.png");
  background-size: cover; }

.react-grid-layout {
  position: relative;
  transition: height 50ms ease; }

.react-grid-item {
  transition: all 50ms ease;
  transition-property: left, top; }

.react-grid-item img {
  pointer-events: none;
  user-select: none; }

.react-grid-item.cssTransforms {
  transition-property: transform; }

.react-grid-item.resizing {
  z-index: 1;
  will-change: width, height; }

.react-grid-item.react-draggable-dragging {
  transition: none;
  z-index: 3;
  will-change: transform; }

.react-grid-item.dropping {
  visibility: hidden; }

.react-grid-item.react-grid-placeholder {
  background: red;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none; }

.react-resizable-hide .react-resizable-handle {
  display: none; }

.react-grid-item .react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px; }
  .react-grid-item .react-resizable-handle ::after {
    content: "";
    position: absolute;
    right: 3px;
    bottom: 3px;
    width: 5px;
    height: 5px;
    border-right: 2px solid rgba(0, 0, 0, 0.4);
    border-bottom: 2px solid rgba(0, 0, 0, 0.4); }
  .react-grid-item .react-resizable-handle .react-resizable-handle-sw {
    bottom: 0;
    left: 0;
    cursor: sw-resize;
    transform: rotate(90deg); }
  .react-grid-item .react-resizable-handle .react-resizable-handle-se {
    bottom: 0;
    right: 0;
    cursor: se-resize; }
  .react-grid-item .react-resizable-handle .react-resizable-handle-nw {
    top: 0;
    left: 0;
    cursor: nw-resize;
    transform: rotate(180deg); }
  .react-grid-item .react-resizable-handle .react-resizable-handle-ne {
    top: 0;
    right: 0;
    cursor: ne-resize;
    transform: rotate(270deg); }
  .react-grid-item .react-resizable-handle .react-resizable-handle-w,
  .react-grid-item .react-resizable-handle .react-resizable-handle-e {
    top: 50%;
    margin-top: -10px;
    cursor: ew-resize; }
  .react-grid-item .react-resizable-handle .react-resizable-handle-n,
  .react-grid-item .react-resizable-handle .react-resizable-handle-s {
    left: 50%;
    margin-left: -10px;
    cursor: ns-resize; }
  .react-grid-item .react-resizable-handle .react-resizable-handle-e {
    right: 0;
    transform: rotate(315deg); }
  .react-grid-item .react-resizable-handle .react-resizable-handle-w {
    left: 0;
    transform: rotate(135deg); }
  .react-grid-item .react-resizable-handle .react-resizable-handle-n {
    top: 0;
    transform: rotate(225deg); }
  .react-grid-item .react-resizable-handle .react-resizable-handle-s {
    bottom: 0;
    transform: rotate(45deg); }

.react-resizable {
  position: relative; }

.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+");
  background-position: bottom right;
  padding: 0 3px 3px 0; }

.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg); }

.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize; }

.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg); }

.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg); }

.react-resizable-handle-w,
.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize; }

.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg); }

.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg); }

.react-resizable-handle-n,
.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize; }

.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg); }

.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg); }

.credit-app {
  background-color: white;
  min-height: 68vh; }
  .credit-app .content-wrapper {
    height: 100%;
    padding-bottom: 50px;
    width: 70%;
    margin: auto; }
    .credit-app .content-wrapper .title-row {
      margin-top: 100px;
      margin-bottom: 50px; }
      .credit-app .content-wrapper .title-row .title {
        font-size: 40px;
        font-weight: bold;
        text-align: center; }
      .credit-app .content-wrapper .title-row .sub-title {
        max-width: 640px;
        margin: 30px auto;
        color: #676767;
        font-size: 18px;
        line-height: 25px;
        text-align: center; }
      .credit-app .content-wrapper .title-row .form-wrapper {
        margin: 40px auto;
        font-size: 14px;
        text-align: left; }
  .credit-app .invalidTxt {
    width: max-content !important; }

@media only screen and (min-width: 750px) and (max-width: 1280px) {
  .credit-app .content-wrapper {
    padding-bottom: 50px; }
    .credit-app .content-wrapper .title-row {
      margin-top: 70px;
      margin-bottom: 50px; }
      .credit-app .content-wrapper .title-row .title {
        font-size: 34px; }
      .credit-app .content-wrapper .title-row .sub-title {
        font-size: 16px; }
      .credit-app .content-wrapper .title-row .form-wrapper {
        margin: 40px auto;
        font-size: 14px;
        text-align: left; } }

@media only screen and (min-width: 300px) and (max-width: 749px) {
  .credit-app .content-wrapper {
    padding-bottom: 50px; }
    .credit-app .content-wrapper .title-row {
      margin-top: 40px;
      margin-bottom: 50px; }
      .credit-app .content-wrapper .title-row .title {
        font-size: 24px; }
      .credit-app .content-wrapper .title-row .sub-title {
        font-size: 14px; }
      .credit-app .content-wrapper .title-row .form-wrapper {
        margin: 40px auto;
        font-size: 14px;
        text-align: left; } }

.smMenu {
  display: none; }

.sdMenu {
  display: none; }

@media only screen and (min-width: 300px) and (max-width: 1154px) {
  .main-panel {
    margin-top: 50px; }
  .resetpassword .textSection {
    display: none; }
  .resetpassword .loginwrapper .innWrapper .loginTitle h2 {
    font-size: 24px; }
  .resetpassword .loginwrapper .innWrapper .loginOverlay {
    padding: 0 20px 40px; }
    .resetpassword .loginwrapper .innWrapper .loginOverlay .loginOverlayinn .leftColumn {
      width: 100%;
      min-height: 0;
      background: url("../../assets/images/base@2x.png");
      background-size: 100%;
      position: inherit; }
      .resetpassword .loginwrapper .innWrapper .loginOverlay .loginOverlayinn .leftColumn .wraper {
        padding: 30px; }
        .resetpassword .loginwrapper .innWrapper .loginOverlay .loginOverlayinn .leftColumn .wraper li:last-child {
          margin-bottom: 0; }
    .resetpassword .loginwrapper .innWrapper .loginOverlay .loginOverlayinn .rightColumn {
      padding: 30px;
      min-height: 0; }
      .resetpassword .loginwrapper .innWrapper .loginOverlay .loginOverlayinn .rightColumn .login-page .formSection {
        width: 100%;
        padding-right: 0; }
      .resetpassword .loginwrapper .innWrapper .loginOverlay .loginOverlayinn .rightColumn .col-grid1 > .col {
        margin-top: 15px;
        text-align: center; }
  .page .headerSection .headerWrapper {
    padding: 0 15px;
    width: 100%; }
    .page .headerSection .headerWrapper .logoSection {
      float: none;
      margin: 0 auto;
      width: 180px; }
      .page .headerSection .headerWrapper .logoSection h1 {
        padding: 0; }
      .page .headerSection .headerWrapper .logoSection .phone-number {
        margin: 0;
        position: absolute;
        right: 50%;
        top: 35px;
        font-weight: 400;
        font-size: 11px;
        margin-right: -90px;
        text-decoration: none; }
    .page .headerSection .headerWrapper .hederRight {
      display: block;
      position: absolute;
      width: 100%;
      height: auto;
      background: #fff;
      top: 53px;
      left: 0%;
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      transition: all .2s; }
      .page .headerSection .headerWrapper .hederRight.close {
        left: -100%; }
      .page .headerSection .headerWrapper .hederRight .topNav {
        width: 100%; }
        .page .headerSection .headerWrapper .hederRight .topNav ul {
          width: 100%; }
          .page .headerSection .headerWrapper .hederRight .topNav ul li {
            display: block;
            float: none;
            width: 100%;
            margin: 5px 0 0;
            padding: 0 5px; }
            .page .headerSection .headerWrapper .hederRight .topNav ul li a {
              border-radius: 4px !important; }
      .page .headerSection .headerWrapper .hederRight .accountSection {
        width: 100%;
        float: left;
        margin: 0;
        padding: 0 5px; }
        .page .headerSection .headerWrapper .hederRight .accountSection .popupMenu {
          display: block;
          position: static;
          width: 100%;
          box-shadow: none;
          padding-top: 0px; }
          .page .headerSection .headerWrapper .hederRight .accountSection .popupMenu .menuList li {
            padding: 10px 12px 14px;
            color: #4a4a4a;
            font-weight: 400;
            text-transform: uppercase; }
            .page .headerSection .headerWrapper .hederRight .accountSection .popupMenu .menuList li.active {
              background: #f2f2f2;
              text-decoration: none;
              color: #ff6c2f;
              border-radius: 4px; }
        .page .headerSection .headerWrapper .hederRight .accountSection button {
          display: none; }
    .page .headerSection .headerWrapper .smMenu {
      display: block;
      position: absolute;
      left: 15px;
      top: 15px; }
      .page .headerSection .headerWrapper .smMenu:before {
        content: '';
        width: 100%;
        height: 3px;
        background: #4a4a4a;
        position: absolute;
        top: 9px; }
      .page .headerSection .headerWrapper .smMenu span {
        width: 30px;
        height: 21px;
        display: block; }
        .page .headerSection .headerWrapper .smMenu span:before {
          content: '';
          width: 100%;
          height: 3px;
          background: #4a4a4a;
          position: absolute;
          transition: all .2s; }
        .page .headerSection .headerWrapper .smMenu span:after {
          content: '';
          width: 100%;
          height: 3px;
          background: #4a4a4a;
          position: absolute;
          bottom: 0;
          transition: all .2s; }
      .page .headerSection .headerWrapper .smMenu.open:before {
        display: none; }
      .page .headerSection .headerWrapper .smMenu.open span:before {
        transform: rotate(40deg);
        top: 10px;
        height: 2px; }
      .page .headerSection .headerWrapper .smMenu.open span:after {
        transform: rotate(-40deg);
        top: 10px;
        height: 2px; }
    .page .headerSection .headerWrapper .sdMenu {
      display: block;
      position: absolute;
      right: 15px;
      top: 15px; }
      .page .headerSection .headerWrapper .sdMenu:before {
        content: '';
        width: 100%;
        height: 3px;
        background: #4a4a4a;
        position: absolute;
        top: 9px; }
      .page .headerSection .headerWrapper .sdMenu span {
        width: 30px;
        height: 21px;
        display: block; }
        .page .headerSection .headerWrapper .sdMenu span:before {
          content: '';
          width: 100%;
          height: 3px;
          background: #4a4a4a;
          position: absolute;
          transition: all .2s; }
        .page .headerSection .headerWrapper .sdMenu span:after {
          content: '';
          width: 100%;
          height: 3px;
          background: #4a4a4a;
          position: absolute;
          bottom: 0;
          transition: all .2s; }
      .page .headerSection .headerWrapper .sdMenu.open:before {
        display: none; }
      .page .headerSection .headerWrapper .sdMenu.open span:before {
        transform: rotate(40deg);
        top: 10px;
        height: 2px; }
      .page .headerSection .headerWrapper .sdMenu.open span:after {
        transform: rotate(-40deg);
        top: 10px;
        height: 2px; }
  .page .headerSection.noShodow .headerWrapper .hederRight .topNav ul {
    padding: 0 10px; }
    .page .headerSection.noShodow .headerWrapper .hederRight .topNav ul li {
      background: #f8f8f8;
      border-radius: 4px; }
      .page .headerSection.noShodow .headerWrapper .hederRight .topNav ul li.active {
        border: 0; }
  .page .headerSection.noShodow .headerWrapper .hederRight .searchSection {
    width: 100%;
    padding-bottom: 30px; }
    .page .headerSection.noShodow .headerWrapper .hederRight .searchSection input {
      width: 80%; }
  .page .loginwrapper .innWrapper {
    max-width: 750px; }
    .page .loginwrapper .innWrapper .loginTitle {
      height: 40px;
      padding: 0 15px; }
      .page .loginwrapper .innWrapper .loginTitle h2 {
        line-height: 38px;
        margin-top: 8px;
        font-size: 35px; }
    .page .loginwrapper .innWrapper .loginOverlay {
      padding: 0 15px 10px; }
      .page .loginwrapper .innWrapper .loginOverlay .loginOverlayinn .leftColumn {
        width: 240px;
        background-position: center 56%;
        background-size: cover;
        position: static;
        width: 100%; }
        .page .loginwrapper .innWrapper .loginOverlay .loginOverlayinn .leftColumn .wraper {
          padding: 20px 16px 10px 20px; }
          .page .loginwrapper .innWrapper .loginOverlay .loginOverlayinn .leftColumn .wraper ul li {
            margin-bottom: 10px; }
      .page .loginwrapper .innWrapper .loginOverlay .loginOverlayinn .rightColumn {
        padding: 20px 20px 2px 20px; }
  .page .login-page .formSection {
    width: 100%;
    float: left;
    padding-right: 0px; }
  .page .login-page .textSection {
    width: 100%;
    float: left;
    padding-left: 0;
    border-left: 0;
    border-top: 1px solid #ddd;
    min-height: auto; }
    .page .login-page .textSection h2 {
      font-size: 28px; }
  .page .ForgotPasswordForm .form-group {
    position: static;
    width: 100%;
    padding: 15px 15px 10px; }
  .page .col-grid3 > .col {
    width: 100%;
    padding-right: 0; }
  .page .col-grid2 > .col {
    width: 100%;
    padding-right: 0; }
  .page .col-grid2-1 > .col {
    width: 100% !important; }
  .page .signupForm .holdarRadio {
    position: static;
    padding-top: 20px; }
  .page .footerSection .footerinn .wraper {
    padding: 0; }
  .gestUserLogin .loginOverlay .leftColumn {
    background-position: center 56%;
    background-size: cover;
    position: static;
    width: 100%;
    min-height: 0; }
    .gestUserLogin .loginOverlay .leftColumn .wraper {
      padding: 45px 16px 20px 20px; }
      .gestUserLogin .loginOverlay .leftColumn .wraper .list {
        padding: 0; }
        .gestUserLogin .loginOverlay .leftColumn .wraper .list li {
          margin: 0 0 0 20px; }
      .gestUserLogin .loginOverlay .leftColumn .wraper ul li {
        margin: 0 0 10px 0; }
      .gestUserLogin .loginOverlay .leftColumn .wraper ul li:last-child {
        margin-bottom: 10px; }
  .gestUserLogin .loginOverlay .rightColumn {
    margin-left: 0;
    padding: 20px 20px 2px 20px; }
    .gestUserLogin .loginOverlay .rightColumn .wraper h1 {
      font-size: 18px;
      margin: 0; }
    .gestUserLogin .loginOverlay .rightColumn .wraper h2 {
      font-size: 18px;
      margin: 0 0 10px 0; }
    .gestUserLogin .loginOverlay .rightColumn .wraper input {
      width: 100%; }
    .gestUserLogin .loginOverlay .rightColumn .wraper .col-grid2 > .col:first-child {
      padding-right: 0; }
    .gestUserLogin .loginOverlay .rightColumn .wraper .col-grid2 > .col {
      width: 100%; }
    .gestUserLogin .loginOverlay .rightColumn .wraper .col-grid2-1 > .col:first-child {
      width: 100% !important; }
    .gestUserLogin .loginOverlay .rightColumn .wraper .col-grid2-1 > .col {
      margin-bottom: 0; }
  .layout-onecol .innWrapper .mainContent .contenWrapper {
    padding: 15px !important; }
    .layout-onecol .innWrapper .mainContent .contenWrapper .status-section {
      margin: 10px 0 0 0; }
      .layout-onecol .innWrapper .mainContent .contenWrapper .status-section .block {
        width: 100%;
        padding-right: 0; }
        .layout-onecol .innWrapper .mainContent .contenWrapper .status-section .block .wraper {
          min-height: inherit;
          padding: 20px 20px 10px 30px;
          margin-bottom: 10px; }
          .layout-onecol .innWrapper .mainContent .contenWrapper .status-section .block .wraper p {
            float: left;
            margin: 0 30px 10px 0; }
    .layout-onecol .innWrapper .mainContent .contenWrapper .tooltipWrap .tooltiptext.fullScreenTp {
      left: 0;
      top: 60px;
      bottom: inherit;
      width: 100%; }
      .layout-onecol .innWrapper .mainContent .contenWrapper .tooltipWrap .tooltiptext.fullScreenTp:after {
        top: -19px;
        margin-left: 192px;
        bottom: inherit;
        border-color: transparent transparent #fff transparent; }
    .layout-onecol .innWrapper .mainContent .contenWrapper .list-section {
      margin-top: 10px; }
      .layout-onecol .innWrapper .mainContent .contenWrapper .list-section .title-row h2 {
        margin-bottom: 10px; }
      .layout-onecol .innWrapper .mainContent .contenWrapper .list-section .title-row p a {
        font-size: 14px; }
      .layout-onecol .innWrapper .mainContent .contenWrapper .list-section .listWraper .listItems {
        position: relative;
        padding-bottom: 40px; }
        .layout-onecol .innWrapper .mainContent .contenWrapper .list-section .listWraper .listItems .row.booking .col {
          width: 100%;
          padding: 6px 10px; }
          .layout-onecol .innWrapper .mainContent .contenWrapper .list-section .listWraper .listItems .row.booking .col.schedule {
            width: 60%;
            min-height: 55px;
            border-bottom: 1px solid #ddd;
            background: #fbf9f9; }
          .layout-onecol .innWrapper .mainContent .contenWrapper .list-section .listWraper .listItems .row.booking .col.d-date {
            width: 40%; }
            .layout-onecol .innWrapper .mainContent .contenWrapper .list-section .listWraper .listItems .row.booking .col.d-date.quote-date {
              border-bottom: 1px solid #ddd;
              background: #fbf9f9;
              min-height: 55px; }
          .layout-onecol .innWrapper .mainContent .contenWrapper .list-section .listWraper .listItems .row.booking .col.tracking {
            width: 60%;
            min-height: 55px; }
          .layout-onecol .innWrapper .mainContent .contenWrapper .list-section .listWraper .listItems .row.booking .col.link {
            padding-top: 0;
            padding-bottom: 0; }
          .layout-onecol .innWrapper .mainContent .contenWrapper .list-section .listWraper .listItems .row.booking .col.link1 {
            padding-top: 0;
            padding-bottom: 0; }
          .layout-onecol .innWrapper .mainContent .contenWrapper .list-section .listWraper .listItems .row.booking .col.getDoc {
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 0;
            padding-right: 0;
            background: #ddd;
            position: absolute;
            bottom: 0;
            left: 0; }
            .layout-onecol .innWrapper .mainContent .contenWrapper .list-section .listWraper .listItems .row.booking .col.getDoc .iconButton .popupMenu {
              top: 40px; }
          .layout-onecol .innWrapper .mainContent .contenWrapper .list-section .listWraper .listItems .row.booking .col.getInc {
            width: 40%;
            float: left;
            padding: 0;
            clear: both; }
            .layout-onecol .innWrapper .mainContent .contenWrapper .list-section .listWraper .listItems .row.booking .col.getInc button {
              padding-top: 0;
              padding-bottom: 0; }
          .layout-onecol .innWrapper .mainContent .contenWrapper .list-section .listWraper .listItems .row.booking .col.fileClaim {
            width: 60%;
            float: left;
            padding: 0; }
            .layout-onecol .innWrapper .mainContent .contenWrapper .list-section .listWraper .listItems .row.booking .col.fileClaim button {
              padding-top: 0;
              padding-bottom: 0; }
          .layout-onecol .innWrapper .mainContent .contenWrapper .list-section .listWraper .listItems .row.booking .col.close {
            padding-top: 0;
            padding-bottom: 0; }
            .layout-onecol .innWrapper .mainContent .contenWrapper .list-section .listWraper .listItems .row.booking .col.close .zmdi-close-circle {
              position: absolute;
              top: -7px;
              right: -7px; }
        .layout-onecol .innWrapper .mainContent .contenWrapper .list-section .listWraper .listItems .row.collapsible {
          padding: 10px; }
          .layout-onecol .innWrapper .mainContent .contenWrapper .list-section .listWraper .listItems .row.collapsible .wrapper .leftSection {
            width: 100%;
            padding: 0; }
          .layout-onecol .innWrapper .mainContent .contenWrapper .list-section .listWraper .listItems .row.collapsible .wrapper .rightSection {
            width: 100%;
            margin: 0;
            padding: 0; }
            .layout-onecol .innWrapper .mainContent .contenWrapper .list-section .listWraper .listItems .row.collapsible .wrapper .rightSection .main {
              padding-left: 0; }
              .layout-onecol .innWrapper .mainContent .contenWrapper .list-section .listWraper .listItems .row.collapsible .wrapper .rightSection .main .inlineBtn > .col {
                padding-right: 225px; }
              .layout-onecol .innWrapper .mainContent .contenWrapper .list-section .listWraper .listItems .row.collapsible .wrapper .rightSection .main .radioBtnBlock .radioGroup .radio-col {
                margin-left: 0; }
                .layout-onecol .innWrapper .mainContent .contenWrapper .list-section .listWraper .listItems .row.collapsible .wrapper .rightSection .main .radioBtnBlock .radioGroup .radio-col span {
                  text-align: left; }
      .layout-onecol .innWrapper .mainContent .contenWrapper .list-section.savedQuotes .listWraper .listItems {
        padding-bottom: 0; }
        .layout-onecol .innWrapper .mainContent .contenWrapper .list-section.savedQuotes .listWraper .listItems .row > .col.tracking {
          width: 100%; }
  .layout-onecolLeft .innWrapper {
    padding: 0 15px; }
    .layout-onecolLeft .innWrapper .sidebaar-left {
      width: 100%;
      float: left;
      padding: 20px;
      min-height: inherit; }
      .layout-onecolLeft .innWrapper .sidebaar-left h1 {
        font-size: 24px;
        margin-bottom: 10px; }
      .layout-onecolLeft .innWrapper .sidebaar-left .accountMenu li {
        padding: 8px 0;
        font-size: 16px; }
    .layout-onecolLeft .innWrapper .mainContent {
      padding: 0;
      width: 100%;
      float: left; }
      .layout-onecolLeft .innWrapper .mainContent .contenWrapper {
        padding: 20px 15px 10px;
        margin-top: 12px; }
        .layout-onecolLeft .innWrapper .mainContent .contenWrapper .accounDetail .fullName {
          font-size: 42px; }
        .layout-onecolLeft .innWrapper .mainContent .contenWrapper .accounDetail .h5 {
          font-size: 18px; }
  .GuestLoginForm h2 {
    font-size: 32px; }
  .notAMemberYetSection {
    margin-top: 10px !important; } }

@media only screen and (min-width: 769px) and (max-width: 990px) {
  .white-section.quote .content-section .item-detail .col-left {
    width: 100%;
    border-right: 0; }
  .white-section.quote .content-section .item-detail .col-right {
    width: 100%;
    padding-left: 0 !important;
    padding-bottom: 30px; }
  .white-section.quote .pickup-delivery-info .col.pickupInfo {
    border-right: 0; }
    .white-section.quote .pickup-delivery-info .col.pickupInfo .col-grid2 > .col {
      width: 50%;
      margin-bottom: 15px; }
  .white-section.quote .pickup-delivery-info .col.deliveryInfo {
    padding-left: 0; }
    .white-section.quote .pickup-delivery-info .col.deliveryInfo .col-grid2 > .col {
      width: 50%;
      margin-bottom: 15px; } }

@media only screen and (min-width: 769px) and (max-width: 1324px) {
  /* quote form */
  .white-section.quote {
    padding: 20px 15px; }
    .white-section.quote .formSelectBoxImp > label,
    .white-section.quote .formSelectBoxRdonly > label,
    .white-section.quote .form-inputField > label {
      font-size: 14px; }
    .white-section.quote .content-section .item-detail .col-right {
      padding-left: 15px; }
  .white-section.quote .content-section .item-detail .col-left .row .column1 {
    width: 33%; }
  .white-section.quote .content-section .item-detail .col-left .row .column3 {
    width: 67%;
    padding-left: 20px;
    padding-right: 20px; }
  .white-section.quote .content-section .item-detail .col-left .row + .row .column3 .dimension {
    width: 100%; }
  .white-section.quote .content-section .item-detail .col-left .row + .row .column3 .dimension-check {
    width: 100%; }
    .white-section.quote .content-section .item-detail .col-left .row + .row .column3 .dimension-check .checkbox-lbl {
      width: 50%;
      float: left; }
  .icon-field.other-unit-type .formControl label {
    white-space: nowrap;
    overflow: hidden;
    width: 77%;
    text-overflow: ellipsis; }
  .white-section.quote.pickupInfo.Extrashipmentservices .col-grid4 > .col {
    margin-bottom: 10px; }
  /* end quote form */ }

@media only screen and (min-width: 300px) and (max-width: 868px) {
  h1 {
    font-size: 24px; }
  .col-grid4 > .col {
    width: 100%;
    padding-right: 0; }
  .signupForm .col-grid2-1 .col .formAction {
    text-align: center; }
    .signupForm .col-grid2-1 .col .formAction button {
      float: none; }
    .signupForm .col-grid2-1 .col .formAction p {
      display: block;
      width: 100%;
      margin-left: 0; }
  .layout-onecol .innWrapper .mainContent .contenWrapper {
    /* Start quote result */
    /* End quote result */ }
    .layout-onecol .innWrapper .mainContent .contenWrapper .list-section .listWraper .listItems .row.collapsible {
      position: relative; }
      .layout-onecol .innWrapper .mainContent .contenWrapper .list-section .listWraper .listItems .row.collapsible .wrapper .rightSection .main .inlineBtn > .col {
        padding: 0; }
      .layout-onecol .innWrapper .mainContent .contenWrapper .list-section .listWraper .listItems .row.collapsible .wrapper .rightSection .main .inlineBtn button {
        position: static; }
      .layout-onecol .innWrapper .mainContent .contenWrapper .list-section .listWraper .listItems .row.collapsible:before {
        content: '';
        border: 1px solid #ddd;
        width: 12px;
        height: 12px;
        border-bottom-color: #fff;
        border-right-color: #fff;
        position: absolute;
        transform: rotate(45deg);
        left: 50px;
        top: -6px;
        background: #fff; }
    .layout-onecol .innWrapper .mainContent .contenWrapper .quote-result-wraper .left-section {
      width: 100%;
      float: left;
      overflow: hidden; }
      .layout-onecol .innWrapper .mainContent .contenWrapper .quote-result-wraper .left-section .block-section .col-grid2 > .col {
        width: 50%;
        float: left; }
    .layout-onecol .innWrapper .mainContent .contenWrapper .quote-result-wraper .quote-result-section {
      width: 100%;
      float: left;
      padding: 0; }
      .layout-onecol .innWrapper .mainContent .contenWrapper .quote-result-wraper .quote-result-section .wraper .result-list {
        background: #fff; }
        .layout-onecol .innWrapper .mainContent .contenWrapper .quote-result-wraper .quote-result-section .wraper .result-list ul {
          padding: 0 15px; }
          .layout-onecol .innWrapper .mainContent .contenWrapper .quote-result-wraper .quote-result-section .wraper .result-list ul li {
            border: 1px solid #ddd; }
            .layout-onecol .innWrapper .mainContent .contenWrapper .quote-result-wraper .quote-result-section .wraper .result-list ul li .col-grid2 {
              text-align: center; }
              .layout-onecol .innWrapper .mainContent .contenWrapper .quote-result-wraper .quote-result-section .wraper .result-list ul li .col-grid2 > .col {
                border: 0; }
                .layout-onecol .innWrapper .mainContent .contenWrapper .quote-result-wraper .quote-result-section .wraper .result-list ul li .col-grid2 > .col .shiping-section {
                  padding: 0; }
                  .layout-onecol .innWrapper .mainContent .contenWrapper .quote-result-wraper .quote-result-section .wraper .result-list ul li .col-grid2 > .col .shiping-section.left-section .ship-type {
                    width: 50%; }
                  .layout-onecol .innWrapper .mainContent .contenWrapper .quote-result-wraper .quote-result-section .wraper .result-list ul li .col-grid2 > .col .shiping-section .ship-type {
                    border: 0;
                    width: 100%;
                    padding: 6px 10px; }
                    .layout-onecol .innWrapper .mainContent .contenWrapper .quote-result-wraper .quote-result-section .wraper .result-list ul li .col-grid2 > .col .shiping-section .ship-type.logo {
                      width: 100%;
                      padding: 0; }
                      .layout-onecol .innWrapper .mainContent .contenWrapper .quote-result-wraper .quote-result-section .wraper .result-list ul li .col-grid2 > .col .shiping-section .ship-type.logo .ship-image {
                        width: 100%; }
                        .layout-onecol .innWrapper .mainContent .contenWrapper .quote-result-wraper .quote-result-section .wraper .result-list ul li .col-grid2 > .col .shiping-section .ship-type.logo .ship-image .table .cell {
                          height: 80px; }
                      .layout-onecol .innWrapper .mainContent .contenWrapper .quote-result-wraper .quote-result-section .wraper .result-list ul li .col-grid2 > .col .shiping-section .ship-type.logo .shiping {
                        display: none; }
                    .layout-onecol .innWrapper .mainContent .contenWrapper .quote-result-wraper .quote-result-section .wraper .result-list ul li .col-grid2 > .col .shiping-section .ship-type.data {
                      display: none; }
                    .layout-onecol .innWrapper .mainContent .contenWrapper .quote-result-wraper .quote-result-section .wraper .result-list ul li .col-grid2 > .col .shiping-section .ship-type .ship-status {
                      display: flex;
                      justify-content: center;
                      min-height: 0;
                      padding-bottom: 5px;
                      margin: 0; }
                      .layout-onecol .innWrapper .mainContent .contenWrapper .quote-result-wraper .quote-result-section .wraper .result-list ul li .col-grid2 > .col .shiping-section .ship-type .ship-status h3 {
                        font-size: 16px; }
                      .layout-onecol .innWrapper .mainContent .contenWrapper .quote-result-wraper .quote-result-section .wraper .result-list ul li .col-grid2 > .col .shiping-section .ship-type .ship-status p {
                        line-height: 18px;
                        padding: 0 7px; }
                    .layout-onecol .innWrapper .mainContent .contenWrapper .quote-result-wraper .quote-result-section .wraper .result-list ul li .col-grid2 > .col .shiping-section .ship-type .price {
                      font-size: 12px; }
                    .layout-onecol .innWrapper .mainContent .contenWrapper .quote-result-wraper .quote-result-section .wraper .result-list ul li .col-grid2 > .col .shiping-section .ship-type .blue-attention-icon {
                      height: 12px !important;
                      width: 12px !important; }
                    .layout-onecol .innWrapper .mainContent .contenWrapper .quote-result-wraper .quote-result-section .wraper .result-list ul li .col-grid2 > .col .shiping-section .ship-type div[class^='MuiTooltip-tooltip'] {
                      max-width: 210px; }
                .layout-onecol .innWrapper .mainContent .contenWrapper .quote-result-wraper .quote-result-section .wraper .result-list ul li .col-grid2 > .col .guaranteed .ship-status {
                  padding-top: 10px;
                  border-top: 1px solid #ddd; }
            .layout-onecol .innWrapper .mainContent .contenWrapper .quote-result-wraper .quote-result-section .wraper .result-list ul li .carrierDetails .col-grid2 > .col {
              width: 50%;
              padding-top: 8px; }
            .layout-onecol .innWrapper .mainContent .contenWrapper .quote-result-wraper .quote-result-section .wraper .result-list ul li .cDetailcollapsible .smallTxt {
              line-height: 16px;
              color: #4a4a4a; }
      .layout-onecol .innWrapper .mainContent .contenWrapper .quote-result-wraper .quote-result-section .wraper .carriersNotused-section .white-section.search-cutomer .inwraper .grid-carriersNotused .col {
        width: 50%; }
      .layout-onecol .innWrapper .mainContent .contenWrapper .quote-result-wraper .quote-result-section .wraper .carriersNotused-section .carriersNotusedResult .col {
        width: 50%; }
        .layout-onecol .innWrapper .mainContent .contenWrapper .quote-result-wraper .quote-result-section .wraper .carriersNotused-section .carriersNotusedResult .col img {
          max-width: 90%; }
  .page .footerSection {
    padding-bottom: 0; }
    .page .footerSection ul {
      float: none; }
    .page .footerSection .footerinn .wraper {
      padding: 0; }
      .page .footerSection .footerinn .wraper .cal1 {
        width: 100%;
        min-height: inherit;
        padding-bottom: 20px;
        border-bottom: 1px solid #434343;
        margin-bottom: 20px;
        text-align: center; }
        .page .footerSection .footerinn .wraper .cal1:last-child {
          margin-bottom: 0;
          border: 0; }
      .page .footerSection .footerinn .wraper .cal2 {
        width: 100%;
        min-height: inherit;
        padding-bottom: 20px;
        margin-bottom: 20px;
        text-align: center; }
        .page .footerSection .footerinn .wraper .cal2:last-child {
          margin-bottom: 70px;
          border: 0; }
  .page .mr-5 {
    margin-right: 0px !important; }
  .page .mr-6 {
    margin-right: 0px !important; }
  .page .text-md-right {
    text-align: center !important; }
  .shipmentProgressSection {
    margin-bottom: 20px;
    margin-top: 30px; }
    .shipmentProgressSection .wraper {
      padding: 0; }
      .shipmentProgressSection .wraper .ship-id {
        display: none; }
      .shipmentProgressSection .wraper ul li {
        padding: 0;
        margin: 0;
        width: 25%;
        float: left; }
        .shipmentProgressSection .wraper ul li .edit_link {
          display: block;
          width: 100%; }
        .shipmentProgressSection .wraper ul li i {
          left: 41%;
          top: -26px;
          font-size: 24px;
          z-index: 1; }
        .shipmentProgressSection .wraper ul li:before {
          width: 100%;
          top: -15px;
          right: inherit;
          left: 46%; }
        .shipmentProgressSection .wraper ul li a {
          font-size: 14px;
          padding-bottom: 4px;
          min-height: 32px;
          padding-left: 4px;
          padding-right: 4px; }
          .shipmentProgressSection .wraper ul li a:before {
            top: -25px;
            left: 40%;
            background: #fff; }
        .shipmentProgressSection .wraper ul li:last-child:before {
          background: transparent;
          width: 0; }
  form[name="LocationDetailsForm"] .whiteSection {
    padding: 10px 15px; }
    form[name="LocationDetailsForm"] .whiteSection .head-section h2 {
      float: none;
      padding-bottom: 5px; }
    form[name="LocationDetailsForm"] .whiteSection .head-section .InfolabelText {
      clear: both;
      margin-left: 0;
      font-weight: 400; }
    form[name="LocationDetailsForm"] .whiteSection .head-section > div {
      float: none !important;
      margin-top: 15px; }
    form[name="LocationDetailsForm"] .whiteSection .datePicker .calendar-icon {
      right: 5px; }
    form[name="LocationDetailsForm"] .whiteSection .datePicker-shipment .calendar-icon {
      right: 5px; }
  form[name="LocationDetailsForm"] .button-section .buttonSet {
    padding-top: 5px; }
  form[name="ShipmentDetailsForm"] .whiteSection .twoColumn .leftSection {
    width: 100%;
    float: left;
    padding: 0; }
    form[name="ShipmentDetailsForm"] .whiteSection .twoColumn .leftSection .content .itemListing {
      margin-bottom: 30px; }
      form[name="ShipmentDetailsForm"] .whiteSection .twoColumn .leftSection .content .itemListing li a {
        margin: 0;
        border-right: 0; }
        form[name="ShipmentDetailsForm"] .whiteSection .twoColumn .leftSection .content .itemListing li a.active {
          border-right: 1px solid #ddd; }
        form[name="ShipmentDetailsForm"] .whiteSection .twoColumn .leftSection .content .itemListing li a:hover {
          border-color: transparent;
          background: transparent; }
  form[name="ShipmentDetailsForm"] .whiteSection .twoColumn .rightSection {
    width: 100%;
    float: left;
    margin: 0; }
    form[name="ShipmentDetailsForm"] .whiteSection .twoColumn .rightSection .wrapper {
      border-left: 0;
      padding-left: 0; }
      form[name="ShipmentDetailsForm"] .whiteSection .twoColumn .rightSection .wrapper .chargesBlock {
        margin-left: 0; }
  form[name="ShipmentDetailsForm"] .button-section .buttonSet {
    padding-top: 5px; }
  form[name="shipmentPaymentForm"] .whiteSection.paymentPage {
    padding: 10px 15px !important; }
    form[name="shipmentPaymentForm"] .whiteSection.paymentPage .popupOneColLeft .lefeSidebaar {
      width: 100%;
      float: left; }
    form[name="shipmentPaymentForm"] .whiteSection.paymentPage .popupOneColLeft .mainContent {
      width: 100%;
      float: left;
      margin: 0;
      padding: 30px 0 0 0; }
  .whiteSection.shipment-confirm {
    padding: 10px 15px 20px; }
    .whiteSection.shipment-confirm .head-section h2 {
      font-size: 30px; }
      .whiteSection.shipment-confirm .head-section h2.showQues {
        font-size: 20px; }
    .whiteSection.shipment-confirm .head-section .ship-id {
      position: static;
      margin-bottom: 10px; }
    .whiteSection.shipment-confirm .head-section h3 {
      margin-bottom: 15px; }
    .whiteSection.shipment-confirm .boxModel-gray.shipment-summary {
      padding: 15px; }
      .whiteSection.shipment-confirm .boxModel-gray.shipment-summary .leftSection {
        width: 100%;
        float: left; }
      .whiteSection.shipment-confirm .boxModel-gray.shipment-summary .rightSection {
        width: 100%;
        float: left;
        padding: 0;
        margin: 0; }
        .whiteSection.shipment-confirm .boxModel-gray.shipment-summary .rightSection .wrapper {
          border: 0;
          padding: 0; }
          .whiteSection.shipment-confirm .boxModel-gray.shipment-summary .rightSection .wrapper .address-block {
            width: 100%; }
    .whiteSection.shipment-confirm .boxModel-gray.tracking {
      padding: 0;
      padding-top: 15px; }
  .white-section.quote .content-section .item-detail .col-left {
    width: 100%;
    float: left;
    border: 0; }
    .white-section.quote .content-section .item-detail .col-left .row .column1 {
      width: 100%;
      float: left; }
    .white-section.quote .content-section .item-detail .col-left .row .column3 {
      width: 100%;
      float: left;
      padding: 0; }
      .white-section.quote .content-section .item-detail .col-left .row .column3 .dimension {
        width: 100%;
        float: left; }
        .white-section.quote .content-section .item-detail .col-left .row .column3 .dimension > .col {
          width: 50%; }
      .white-section.quote .content-section .item-detail .col-left .row .column3 .dimension-check {
        width: 100%;
        float: left; }
  .white-section.quote .content-section .item-detail .col-right {
    width: 100%;
    float: left;
    padding: 0 0 20px 0; }
  .white-section.quote .pickup-delivery-info .col-grid2 .col:last-child {
    padding: 0; }
  .white-section.quote .pickup-delivery-info .fType .gCol1 .col .radioGroup .radio-col {
    width: 100%;
    padding-left: 0; }
  .white-section.quote .pickup-delivery-info .pickupInfo {
    border: 0; }
  .white-section.quote .pickup-delivery-info .deliveryInfo {
    padding-left: 0; }
  .tbl-data.res {
    border: 0; }
    .tbl-data.res thead {
      display: none; }
    .tbl-data.res tbody {
      display: block;
      border: 1px solid #ddd;
      margin-bottom: 10px; }
      .tbl-data.res tbody tr {
        display: block; }
        .tbl-data.res tbody tr td {
          display: block;
          padding-left: 50%;
          position: relative; }
          .tbl-data.res tbody tr td:before {
            content: attr(data-label);
            position: absolute;
            left: 0;
            background: #f8f8f8;
            width: 45%;
            padding: 4px;
            top: 0;
            height: 100%;
            border-right: 1px solid #ddd;
            font-weight: 700; }
  .DialogBox > div[role=document] {
    margin: 15px; }
  .other-unit-type .formControl label {
    white-space: nowrap;
    width: 83%;
    text-overflow: ellipsis;
    overflow: hidden; }
  .pagination-section .pagination-wraper .item-per-page .text-perpage {
    display: none; }
  .pagination-section .pagination-wraper .pagination li {
    min-width: inherit; }
    .pagination-section .pagination-wraper .pagination li a {
      min-width: inherit; }
    .pagination-section .pagination-wraper .pagination li button {
      width: 22px;
      height: 22px;
      margin: 0; }
      .pagination-section .pagination-wraper .pagination li button i:before {
        font-size: 23px; }
  .pagination-section .pagination-wraper .item-per-page {
    margin: 0;
    float: right; }
    .pagination-section .pagination-wraper .item-per-page.selectoption select {
      padding: 5px 4px 5px 5px !important; } }

@media only screen and (min-width: 300px) and (max-width: 500px) {
  .headCardbillInfo .checkboxRight {
    float: left !important; } }

@media only screen and (min-width: 300px) and (max-width: 768px) {
  .DialogBox {
    top: 0 !important;
    bottom: 0 !important; }
    .DialogBox form[name="TermsAndConditionsForm"] fieldset {
      padding: 5px; }
      .DialogBox form[name="TermsAndConditionsForm"] fieldset .content {
        min-height: 120px !important;
        padding: 20px 10px !important; }
        .DialogBox form[name="TermsAndConditionsForm"] fieldset .content .InfolabelText {
          margin-left: 0; }
  .col-center {
    margin-left: 0;
    margin-right: 0;
    width: 100%; }
  .carrier-loader {
    font-size: 14px; }
    .carrier-loader .header .dot-container div {
      margin: 0 5px; }
  .quote-result-section .border-bottom {
    display: none; } }

.result-banner-full {
  background-color: black;
  color: white;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 5px; }

.access-time-full {
  vertical-align: middle;
  font-size: 36px !important; }

@media only screen and (min-width: 1012px) and (max-width: 1495px) {
  .result-banner-full {
    font-size: 12px; }
  .access-time-full {
    font-size: 24px !important; } }

@media only screen and (min-width: 300px) and (max-width: 1011px) {
  .result-banner-full {
    font-size: 8px; }
  .access-time-full {
    font-size: 12px !important; } }

@media only screen and (min-width: 869px) and (max-width: 1470px) {
  .page .footerSection {
    float: none; }
    .page .footerSection .footerinn .wraper .cal1 {
      width: 21%; } }

@media only screen and (min-width: 869px) and (max-width: 1154px) {
  .datePicker-shipment .calendar-icon {
    right: 5px; }
    .datePicker-shipment .calendar-icon.time {
      right: 5px !important; } }

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

/* Firefox */
input[type=number] {
  -moz-appearance: textfield; }
